import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { BiErrorCircle } from "react-icons/bi"
import Cookies from "js-cookie"

import versiGemIcon from "../../../../../assets/images/versigem.PNG"
import DialogBox, { closeDialog, getDialog } from "../../../../../components/DialogBox"
import ConfirmButton from "../../../../../components/Buttons/ConfirmButton"
import { buyVersiGemsPath, COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION, TOASTTIMER } from "../../../../../assets/constants"
import { unlockFreelancerDirectMessageRestrictionAsync } from "../../../../../slices/message/directMessage"
import toastify from "../../../../../utils/toastify"

const UnlockWithVersiGemsDialog = ({ requiredVerisGems, messageId, setVersiGemsSpent }) => {
    const { user } = useSelector(state => state.auth)
    const [loading, setLoading] = useState(false)

    // Handle balance < required gems
    const location = useLocation()
    const navigate = useNavigate()
    const navigateToBuyVersichGemsPage = () => {
        const path = window.encodeURIComponent(location.pathname + location.search)
        Cookies.set(COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION, path, { expires: 1 })
        navigate(buyVersiGemsPath)
    }
    const hasRequiredBalance = user.versigems && user.versigems >= requiredVerisGems.value

    // Handle unlock process
    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        setLoading(true)
        await dispatch(unlockFreelancerDirectMessageRestrictionAsync({ id: messageId, restriction: requiredVerisGems.key }))
            .unwrap()
            .then(() => {
                const dialog = getDialog(e.target)
                if (dialog) {
                    closeDialog(null, dialog)
                }
                setVersiGemsSpent(requiredVerisGems.value)
                setTimeout(() => {
                    setVersiGemsSpent(null)
                    submitBtn.disabled = false
                }, TOASTTIMER)
                return setLoading(false)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                toastify(data.message, data.status, submitBtn, unlockWithVersiGemsDialog)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <DialogBox
            id={unlockWithVersiGemsDialog}
            className="backdrop:bg-oxford-blue/20 max-sm:max-w-sm"
            toastStacked={false}
            hideClose={true}
        >
            <form className="bg-lighter bg-opacity-20 mx-auto p-4 lg:p-4" onSubmit={handleSubmit} data-parent-id={unlockWithVersiGemsDialog}>
                <img className="absolute w-7 h-7 md:w-10 md:h-10 top-4 right-4" src={versiGemIcon} alt="" />
                <div className="text-center mb-8 md:mb-12">
                    <h2 className="text-versich-dark-blue font-semibold text-xl md:text-2xl max-md:px-6">Unlock with VersiGems</h2>
                </div>
                <div>
                    <p className="text-sm md:text-base text-versich-label mb-1">Available VersiGems</p>
                    <div className="text-lg flex items-center gap-x-1.5 mt-1.5 mb-3">
                        <p className="text-versich-dark-blue text-lg md:text-xl font-semibold">
                            {user.versigems ? user.versigems : <span className="text-red-500">0</span>}</p>
                    </div>
                </div>
                <div>
                    <p className="text-sm md:text-base text-versich-label mb-1">VersiGems Required</p>
                    <div className="text-lg flex items-center gap-x-1.5 mt-1.5 mb-3">
                        <p className="text-versich-dark-blue text-lg md:text-xl font-semibold">{requiredVerisGems.value}</p>
                    </div>
                </div>
                {hasRequiredBalance
                    ? <div>
                        <p className="text-sm md:text-base text-versich-label mb-1">VersiGems balance after unlocking</p>
                        <div className="text-lg flex items-center gap-x-1.5 mt-1.5 mb-3">
                            <p className="text-versich-dark-blue text-lg md:text-xl font-semibold">{user.versigems ? user.versigems - requiredVerisGems.value : 0}</p>
                        </div>
                    </div>
                    : <p className="text-versich-blue inline-flex gap-1 sm401:items-center"><BiErrorCircle className="min-w-5 h-5 max-sm401:mt-0.5" /> You don't have enough VersiGems!</p>
                }
                <div className="max-w-fit mx-auto mt-3 md:mt-6">
                    {hasRequiredBalance
                        ? <ConfirmButton className="bg-versich-blue hover:bg-versich-blue-hover text-sm md:text-base w-full text-white font-medium px-4 lg:px-5 py-3 rounded-lg" type="submit" loading={loading}>Unlock {requiredVerisGems.label}</ConfirmButton>
                        : <button className="bg-versich-blue hover:bg-versich-blue-hover text-sm md:text-base w-full text-white font-medium px-4 md:px-5 py-3 rounded-lg" type="button" onClick={navigateToBuyVersichGemsPage}>Buy VerisGems & Continue</button>
                    }
                </div>
            </form>
        </DialogBox >
    )
}
export const unlockWithVersiGemsDialog = "unlockwithversigems-dialog"
export default UnlockWithVersiGemsDialog