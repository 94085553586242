import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../../api"
import apiErrorParser from "../../../../utils/apiErrorParser"

export const allServiceNeedAsync = createAsyncThunk("service/DAA/business/allServiceNeed", async (params) => {
    try {
        const { data } = await api.businessServiceNeedsDAA(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allChoiceOfToolAsync = createAsyncThunk("service/DAA/business/allChoiceOfTool", async (params) => {
    try {
        const { data } = await api.businessChoiceOfToolsDAA(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createRequestAsync = createAsyncThunk("account/DAA/business/createRequest",
    async ({
        user, businessSize, need, otherNeed, tools, projectDuration, otherProjectDuration, currency, budget, otherBudget,
        clientEmail, clientName, description, token
    }) => {
        try {
            const reqData = {
                businessSizeId: businessSize,
                serviceNeedId: need,
                otherServiceNeed: otherNeed,
                toolsIds: tools,
                projectDurationId: projectDuration,
                otherProjectDuration,
                currencyId: currency,
                estimatedBudgetId: budget,
                otherEstimatedBudget: otherBudget,
                clientEmail,
                clientName,
                description,
                csrfmiddlewaretoken: token
            }
            const { data } = user ? await api.businessCreateRequestDAA(reqData) : await api.businessCreateRequestAsGuestDAA(reqData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const getRequestAsync = createAsyncThunk("account/DAA/business/getRequest", async (refId) => {
    try {
        const { data } = await api.businessGetRequestDAA(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getRequestTitleAsync = createAsyncThunk("account/DAA/business/getRequestTitle", async (refId) => {
    try {
        const { data } = await api.businessGetRequestTitleDAA(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})