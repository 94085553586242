import { Routes, Route, Navigate } from "react-router-dom"

import HeaderFooterRoutes from "./HeaderFooterRoutes"
import Home from "../../pages/home/Home"
import Services from "../../pages/services/Services"
import BusinessListing from "../../pages/business-listing/BusinessListing"
import NotAuthRoutes from "./NotAuthRoutes"
import { signupPath } from "../../assets/constants"
import AccountSelection from "../../pages/auth/signup/AccountSelectionForm"
import ClientSignupForm from "../../pages/auth/signup/ClientSignupForm"
import FreelancerSignupForm from "../../pages/auth/signup/FreelancerSignupForm"
import BusinessSignupForm from "../../pages/auth/signup/BusinessSignupForm"
import Login from "../../pages/auth/Login"
import ForgotPassword from "../../pages/auth/ForgotPassword"
import ResetPassword from "../../pages/auth/ResetPassword"
import GoogleOAuthVerification from "../../pages/auth/verification/GoogleOAuthVerification"
import AuthRoutes from "./AuthRoutes"
import EmailVerificationRoutes from "./EmailVerificationRoutes"
import EmailTokenLinkVerification from "../../pages/auth/verification/EmailTokenLinkVerification"
import EmailVerification from "../../pages/auth/verification/email/EmailVerification"
import FreelancerSetupRoutes from "./FreelancerRoutes/FreelancerSetupRoutes"
import PrimaryServiceForm from "../../pages/auth/setup/freelancer/PrimaryServiceForm"
import LeadsForm from "../../pages/auth/setup/freelancer/LeadsForm"
import OtherServicesForm from "../../pages/auth/setup/freelancer/OtherServicesForm"
import ServiceDetailsForm from "../../pages/auth/setup/freelancer/ServiceDetailsForm"
import BusinessSetupRoutes from "./BusinessRoutes/BusinessSetupRoutes"
import BusinessDetailsForm from "../../pages/auth/setup/business/BusinessDetailsForm"
import BusinessPersonalContactForm from "../../pages/auth/setup/business/BusinessPersonalContactForm"
import BusinessAboutForm from "../../pages/auth/setup/business/BusinessAboutForm"
import BusinessLocationForm from "../../pages/auth/setup/business/BusinessLocationForm"
import BusinessSocialProfilesForm from "../../pages/auth/setup/business/BusinessSocialProfilesForm"
import BusinessCompetitorsForm from "../../pages/auth/setup/business/BusinessCompetitorsForm"
import BusinessReviewForm from "../../pages/auth/setup/business/BusinessReviewForm"
import Dashboard from "../../pages/dashboard/Dashboard"
import BusinessPricing from "../../pages/pricing/BusinessPricing"
import BuyVersiGems from "../../pages/dashboard/BuyVersiGems"
import EditProfile from "../../pages/dashboard/edit/Profile/Profile"
import BusinessOrFreelancerRoutes from "./BusinessOrFreelancerRoutes"
import EditPrimaryService from "../../pages/dashboard/edit/PrimaryService/PrimaryService"
import EditLeadsLocation from "../../pages/dashboard/edit/LeadsLocation/LeadsLocation"
import EditOtherServices from "../../pages/dashboard/edit/OtherServices/OtherServices"
import EditServiceDetails from "../../pages/dashboard/edit/ServiceDetails"
import ClientRoutes from "./ClientRoutes/ClientRoutes"
import RequestConfirmation from "../../pages/dashboard/request/confirmation/RequestConfirmation"
import RequestDetails from "../../pages/dashboard/request/details/RequestDetails"
import AllRequest from "../../pages/dashboard/request/all/AllRequest"
import MessageDetails from "../../pages/dashboard/message/details/MessageDetails"
import AllMessage from "../../pages/dashboard/message/all/AllMessage"
import RelatedFreelancers from "../../pages/dashboard/freelancer/service/RelatedFreelancers"
import PaymentSuccess from "../../pages/payments/Success"
import PaymentCanceled from "../../pages/payments/Canceled"
import ClientOnlyRoutes from "./ClientRoutes/ClientOnlyRoutes"
import NewRequest from "../../pages/dashboard/request/new/NewRequest"
import WebDesignAndDevelopment from "../../pages/dashboard/request/new/webDesignAndDevelopment/WebDesignAndDevelopment"
import MobileApp from "../../pages/dashboard/request/new/mobileApp/MobileApp"
import DataAndAnalytics from "../../pages/dashboard/request/new/dataAndAnalytics/DataAndAnalytics"
import PersonalDataAndAnalytics from "../../pages/dashboard/request/new/dataAndAnalytics/personal/PersonalDataAndAnalytics"
import BusinessDataAndAnalytics from "../../pages/dashboard/request/new/dataAndAnalytics/business/BusinessDataAndAnalytics"
import SoftwareDevelopment from "../../pages/dashboard/request/new/softwareDevelopment/SoftwareDevelopment"
import FinanceTransformation from "../../pages/dashboard/request/new/financeTransformation/FinanceTransformation"
import FPandARevolution from "../../pages/dashboard/request/new/financeTransformation/FPandARevolution/FPandARevolution"
import FinanceReportingAndAdvancedAnalytics from "../../pages/dashboard/request/new/financeTransformation/FRAA/FRAA"
import Enterprise from "../../pages/dashboard/request/new/financeTransformation/Enterprise/Enterprise"
import SystemAdministration from "../../pages/dashboard/request/new/financeTransformation/SystemAdministration/SystemAdministration"
import DigitalTransformation from "../../pages/dashboard/request/new/financeTransformation/DigitalTransformation/DigitalTransformation"
import OtherFT from "../../pages/dashboard/request/new/financeTransformation/other/OtherFT"
import MachineLearningAndAI from "../../pages/dashboard/request/new/machineLearningAndAI/MachineLearningAndAI"
import ErrorPage from "../ErrorPage/ErrorPage"

const AllRoutes = () => {
    return (
        <Routes>
            <Route element={<HeaderFooterRoutes />}>
                <Route path="/" element={<Home />} />
                <Route path="services/" element={<Services />} />
                <Route path="business-listing/" element={<BusinessListing />} />
            </Route>
            <Route path="auth/">
                <Route element={<NotAuthRoutes />}>
                    <Route path="" element={<Navigate to={signupPath} replace />} />
                    <Route path="signup/" element={<AccountSelection />} />
                    <Route path="client/signup/" element={<ClientSignupForm />} />
                    <Route path="freelancer/signup/" element={<FreelancerSignupForm />} />
                    <Route path="business/signup/" element={<BusinessSignupForm />} />
                    <Route path="login/" element={<Login />} />
                    <Route path="forgot-password/" element={<ForgotPassword />} />
                    <Route path="reset-password/:token/" element={<ResetPassword />} />
                    <Route path="social-accounts/verify/google-oauth2/" element={<GoogleOAuthVerification />} />
                </Route>
                <Route element={<AuthRoutes />}>
                    <Route element={<EmailVerificationRoutes />}>
                        <Route path="verification/:token/email/" element={<EmailTokenLinkVerification />} />
                        <Route path="verification/email/" element={<EmailVerification />} />
                    </Route>
                    <Route path="su/">
                        <Route path="freelancer/" element={<FreelancerSetupRoutes />}>
                            <Route path="primary-service/" element={<PrimaryServiceForm />} />
                            <Route path="leads/" element={<LeadsForm />} />
                            <Route path="other-services/" element={<OtherServicesForm />} />
                            <Route path="service-details/" element={<ServiceDetailsForm />} />
                        </Route>
                        <Route path="business/" element={<BusinessSetupRoutes />}>
                            <Route path="details/" element={<BusinessDetailsForm />} />
                            <Route path="personal-contact/" element={<BusinessPersonalContactForm />} />
                            <Route path="about/" element={<BusinessAboutForm />} />
                            <Route path="location/" element={<BusinessLocationForm />} />
                            <Route path="social-profiles/" element={<BusinessSocialProfilesForm />} />
                            <Route path="competitors/" element={<BusinessCompetitorsForm />} />
                            <Route path="review-and-references/" element={<BusinessReviewForm />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route path="dashboard/" element={<AuthRoutes />}>
                <Route path="" element={<Dashboard />} />
                <Route path="business/pricing/" element={<BusinessPricing />} />
                <Route path="plans/versigems/buy/" element={<BuyVersiGems />} />
                <Route path="edit/">
                    <Route path="profile/" element={<EditProfile />} />
                    <Route element={<BusinessOrFreelancerRoutes />}>
                        <Route path="primary-service/" element={<EditPrimaryService />} />
                        <Route path="leads/" element={<EditLeadsLocation />} />
                        <Route path="other-services/" element={<EditOtherServices />} />
                        <Route path="service-details/" element={<EditServiceDetails />} />
                    </Route>
                </Route>
                <Route path="request/" element={<ClientRoutes />}>
                    <Route path=":id/confirmation/:pid/" element={<RequestConfirmation />} />
                    <Route path=":id/details/" element={<RequestDetails />} />
                    <Route path="all/" element={<AllRequest />} />
                </Route>
                <Route path="message/" element={<BusinessOrFreelancerRoutes />}>
                    <Route path=":id/details/" element={<MessageDetails />} />
                    <Route path="all/" element={<AllMessage />} />
                </Route>
                <Route path="freelancer/" >
                    <Route path="service/:id/" element={<RelatedFreelancers />} />
                </Route>
            </Route>
            <Route path="payments/checkout/" element={<AuthRoutes />}>
                <Route path="success/" element={<PaymentSuccess />} />
                <Route path="canceled/" element={<PaymentCanceled />} />
            </Route>
            <Route path="request/new/" element={<ClientOnlyRoutes />}>
                <Route path="" element={<NewRequest />} />
                <Route path="web-design-and-development/" element={<WebDesignAndDevelopment />} />
                <Route path="mobile-app/" element={<MobileApp />} />
                <Route path="data-and-analytics/">
                    <Route path="" element={<DataAndAnalytics />} />
                    <Route path="personal/" element={<PersonalDataAndAnalytics />} />
                    <Route path="business/" element={<BusinessDataAndAnalytics />} />
                </Route>
                <Route path="software-development/" element={<SoftwareDevelopment />} />
                <Route path="finance-transformation/">
                    <Route path="" element={<FinanceTransformation />} />
                    <Route path="fp-and-a-revolution/" element={<FPandARevolution />} />
                    <Route path="financial-reporting-and-advanced-analytics/" element={<FinanceReportingAndAdvancedAnalytics />} />
                    <Route path="enterprise/" element={<Enterprise />} />
                    <Route path="system-administration/" element={<SystemAdministration />} />
                    <Route path="digital-transformation/" element={<DigitalTransformation />} />
                    <Route path="other/" element={<OtherFT />} />
                </Route>
                <Route path="machine-learning-and-ai/" element={<MachineLearningAndAI />} />
            </Route>
            <Route path="*" element={<ErrorPage status={404} />} />
        </Routes>
    )
}
export default AllRoutes
