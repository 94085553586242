import { useEffect, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import { MdClose } from "react-icons/md"
import Search from "../svgs/Search"

const ComboServicesInputField = ({ otherRemainingServicesAsync, onSelect }) => {
    const [otherOptions, setOtherOptions] = useState([])
    const [filteredOptions, setFilteredOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [search, setSearch] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(otherRemainingServicesAsync())
            .unwrap()
            .then(data => {
                setOtherOptions(data.remainingServices)
                setFilteredOptions(data.remainingServices)
                setSelectedOptions(data.otherServices)
            })
            .catch(() => { })
    }, [])

    // Store selected options
    useEffect(() => {
        onSelect(selectedOptions.map(option => option.uid))
    }, [selectedOptions])

    // Search
    const handleSearch = (event) => {
        const searchTerm = event.target.value
        setSearch(searchTerm)

        // Filtering options from all options
        let filtered = otherOptions.filter((option) => option.title.toLowerCase().includes(searchTerm.toLowerCase()))

        // Filtering filtered options from selected options
        filtered = filtered.filter(option => selectedOptions.includes(option) === false)
        setFilteredOptions(filtered)
    }

    // Select option
    const handleOptionSelection = (option) => {
        setSelectedOptions((prevSelectedOptions) => [
            ...prevSelectedOptions,
            option,
        ])
        setFilteredOptions((prevFilteredOptions) =>
            prevFilteredOptions.filter((o) => o !== option)
        )
    }

    // Remove option
    const removeSelection = (option) => {
        setSelectedOptions((prevSelectedOptions) =>
            prevSelectedOptions.filter((o) => o !== option)
        )
        setFilteredOptions((prevFilteredOptions) => [
            ...prevFilteredOptions,
            option,
        ])
    }

    // Handle Outside click
    const comboInputRef = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
            comboInputRef.current && !comboInputRef.current.contains(event.target)
                && setIsOpen(false)
        }
        document.addEventListener("click", handleClickOutside)
        return () => {
            document.removeEventListener("click", handleClickOutside)
        }
    }, [])
    return (
        <div className="flex flex-col gap-y-5" ref={comboInputRef}>
            <div className="mt-2 relative z-50">
                <div className="flex items-center relative" onClick={() => setIsOpen(true)}>
                    <input
                        className="text-sm font-medium relative w-full border border-versich-border pl-10 p-[11px] rounded-[10px]"
                        name="selectedServices"
                        type="text"
                        value={search}
                        onChange={handleSearch}
                        placeholder="Search for more services..."
                    />
                    <Search className="w-6 h-6 absolute left-[11px] top-[11px]" />
                </div>
                {isOpen &&
                    <div className="absolute bg-white mt-2 overflow-y-auto max-h-48 w-full border border-versich-border rounded-[10px]">
                        {filteredOptions.map((option) => (
                            <ul key={option.uid}>
                                <li className="p-2 border-b border-gray-200" onClick={() => handleOptionSelection(option)}>{option.title}</li>
                            </ul>
                        ))}
                    </div>
                }
            </div>
            {selectedOptions.length > 0 &&
                <div className="flex flex-wrap gap-2.5">
                    {selectedOptions.map((option) => (
                        <button className="bg-versich-blue hover:bg-versich-blue-hover text-white text-sm flex items-center gap-x-2.5 py-2 px-5 rounded-[10px] transition-all ease-out duration-600"
                            onClick={() => removeSelection(option)} key={option.uid}
                        >
                            {option.title}
                            <MdClose />
                        </button>
                    ))}
                </div>
            }
        </div>
    )
}
export default ComboServicesInputField
