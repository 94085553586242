import { useSelector } from "react-redux"
import { useLocation, Navigate, Outlet } from "react-router-dom"

import ErrorPage from "../../ErrorPage/ErrorPage"
import { BUSINESS, businessPaths } from "../../../assets/constants"

const BusinessSetupRoutes = () => {
    const { user } = useSelector(state => state.auth)
    const location = useLocation()
    if (user.accountType !== BUSINESS) {
        return <ErrorPage status={403} />
    }
    const route = getProfileLink(user, location.pathname)
    if (route) {
        if (location.pathname !== route) {
            return <Navigate to={route} replace />
        }
        return <Outlet />
    }
    return <ErrorPage status={404} />
}
const getProfileLink = (user, currentPath) => {
    if (currentPath === businessPaths.details || (!user.business || !user.business.details || !user.business.details.completed)) {
        return businessPaths.details
    }
    else if (currentPath === businessPaths.personalcontact || (!user.business.personalContact || !user.business.personalContact.completed)) {
        return businessPaths.personalcontact
    }
    else if (currentPath === businessPaths.about || !user.business.about) {
        return businessPaths.about
    }
    else if (currentPath === businessPaths.location || (!user.business.location || !user.business.location.completed)) {
        return businessPaths.location
    }
    else if (currentPath === businessPaths.socialprofiles || (!user.business.socialProfiles || !user.business.socialProfiles.completed)) {
        return businessPaths.socialprofiles
    }
    else if (currentPath === businessPaths.competitors || !user.business.competitors) {
        return businessPaths.competitors
    }
    return businessPaths.reviewandreferences
}

export default BusinessSetupRoutes