import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { BeatLoader } from "react-spinners"

import ErrorPage from "../../../../components/ErrorPage/ErrorPage"
import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import Meta from "../../../../components/Meta"
import BackButton from "../../../../components/Buttons/BackButton"
import noMessages from "../../../../assets/images/no-messages.svg"
import RequestCard from "./RequestCard"
import MessageCard from "./MessageCard"
import { ClientMenu } from "../../../../components/Dashboard/constants"
import { allRequestPath } from "../../../../assets/constants"
import { fetchRequestAction } from "./utils"
import { allClientDirectMessageOfRequestAsync } from "../../../../slices/message/directMessage"

const RequestDetails = () => {
    const { id } = useParams()
    const [request, setRequest] = useState(undefined)
    const [messages, setMessages] = useState([])

    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        const actionAsync = fetchRequestAction(id)
        dispatch(actionAsync(id))
            .unwrap()
            .then(data => {
                setRequest(data.payload)
                dispatch(allClientDirectMessageOfRequestAsync(id))
                    .unwrap()
                    .then(data => {
                        setMessages(data.payload)
                    })
                    .catch(() => { })
            })
            .catch(() => setRequest(null))
    }, [])
    return (
        request === null
            ? <ErrorPage status={404} />
            : <DashboardLayout navlinks={ClientMenu} activePage="My Requests">
                <div className="text-start mb-5 lg:mb-12 space-y-8">
                    <Meta data={{
                        title: request ? request.title : "VersiMarket | Request Details Page",
                        description: "Detailed view of client request",
                    }} />
                    <div className="bg-white shadow-formLight rounded-xl">
                        <div className="border-b-[3px] border-versich-blue/5 p-3 md:p-6">
                            <BackButton onClick={() => navigate(allRequestPath)} />
                        </div>
                        {request
                            ? <RequestCard data={request} />
                            : <div className="w-full h-80 flex justify-center items-center">
                                <BeatLoader loading={true} color="#1D88ED" size={10} />
                            </div>
                        }
                    </div>
                    <div className="bg-white shadow-formLight rounded-xl">
                        <div className="p-5 md:p-10 space-y-4">
                            <div className="text-start space-y-5">
                                <div className="flex pb-3">
                                    <p className="text-lg lg:text-xl text-versich-dark-blue font-semibold">Contacted Experts</p>
                                </div>
                                {messages.length > 0 ? (
                                    <div className="text-start space-y-5">
                                        {messages.map((message, index) => (
                                            <MessageCard data={message} key={index} />
                                        ))}
                                    </div>
                                ) : (<>
                                    <div className="flex justify-center">
                                        <img className="w-[400px]" src={noMessages} alt="no messages" />
                                    </div>
                                    <p className="text-center text-base lg:text-lg font-semibold text-versich-dark-blue -mt-5">There are no expert contacted yet.</p>
                                </>)}
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
    )
}
export default RequestDetails