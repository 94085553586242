import { useSelector } from "react-redux"

import Meta from "../../../../components/Meta"
import { CLIENT, FREELANCER } from "../../../../assets/constants"
import EditClientProfile from "./ClientProfile"
import EditFreelancerProfile from "./FreelancerProfile"

const metadata = {
    title: "Edit Profile",
    description: "Edit user profile",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/edit/profile/`
}

const EditProfile = () => {
    const { user } = useSelector((state) => state.auth)
    return (<>
        <Meta data={metadata} />
        {user.accountType === CLIENT && <EditClientProfile />}
        {user.accountType === FREELANCER && <EditFreelancerProfile />}
    </>)
}
export default EditProfile
