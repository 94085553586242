import { useState } from "react"
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs"

const PasswordField = ({ name, label, placeholder, onChange, defaultValue, error }) => {
    const [value, setValue] = useState(defaultValue)
    const [showPassword, setShowPassword] = useState(false)
    return (
        <div className="space-y-2">
            {label &&
                <div className="flex flex-wrap gap-2 items-center justify-between">
                    <label htmlFor={name} className="text-charcoal text-sm">{label}<span className="text-red-500 ml-1">*</span></label>
                    <button className="flex items-center space-x-1 text-xs" type="button" onClick={() => setShowPassword((prev) => !prev)}>
                        {showPassword
                            ? <BsFillEyeFill className="text-charcoal" />
                            : <BsFillEyeSlashFill className="text-charcoal" />
                        }
                        <span className="text-charcoal">{showPassword ? "Hide" : "Show"}</span>
                    </button>
                </div>
            }
            <input
                className={"text-sm w-full h-10 py-2.5 px-3 border-[1px] border-gray-300 focus-visible:border-versich-blue shadow-input focus:shadow-inputFocus outline-none rounded-lg " + (error.show && !value ? "border-red-500" : "border-versich-border")}
                name={name}
                type={showPassword ? "text" : "password"}
                value={value}
                placeholder={placeholder}
                onChange={(e) => { setValue(e.target.value); onChange(e) }}
            />
            {error.show && !value &&
                <div className="text-sm text-red-500 text-left">
                    {error.message}
                </div>
            }
        </div>
    )
}
export default PasswordField
