import { ToastContainer, Flip } from "react-toastify"

import Close from "./svgs/Close"
import { TOASTTIMER } from "../assets/constants"

const DialogBox = ({ children, className = "", id, hideClose, customCloseEvent, toastStacked = true }) => {
    return (
        <dialog id={id} className={"bg-light w-11/12 lg:w-1/2 2xl:w-1/3 max-w-2xl p-0 border-none rounded-xl " + className} onClose={customCloseEvent}>
            <div className="dialog_inner p-2 sm:p-4">
                {!hideClose &&
                    <div id="dialog_closebtn" className="text-right">
                        <button type="button" className="m-1 p-1" onClick={customCloseEvent ? customCloseEvent : closeDialog} data-parent-id={id}>
                            <Close className="w-4 h-4" />
                        </button>
                    </div>
                }
                {children}
                <ToastContainer stacked={toastStacked} containerId={id} position="bottom-right" autoClose={TOASTTIMER} hideProgressBar={true}
                    newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable transition={Flip} />
            </div>
        </dialog>
    )
}
export const getDialog = (element) => {
    if (element.dataset.parentId && element.dataset.parentId !== "") {
        return document.getElementById(element.dataset.parentId)
    }
    return null
}
export const openDialog = (event, formSubmit = null) => {
    const dialog = getDialog(event.target)
    if (!dialog) {
        return
    }
    if (formSubmit) {
        const form = dialog.querySelector("form")
        if (form) {
            form.onsubmit = formSubmit
        }
    }
    dialog.showModal()
    dialog.addEventListener("click", listenToCloseDialog)
}
export const listenToCloseDialog = (event) => {
    const dialog = event.currentTarget
    if (!dialog) {
        return
    }
    event.target === dialog && closeDialog(null, dialog)
}
export const closeDialog = (event, dialog = null) => {
    if (event) {
        dialog = getDialog(event.currentTarget)
    }
    if (!dialog) {
        return
    }
    dialog.close("")
    dialog.removeEventListener("click", listenToCloseDialog)
}
export default DialogBox