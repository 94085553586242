import { IoIosArrowBack } from "react-icons/io"
import { useNavigate } from "react-router-dom"

const BackButton = ({ onClick }) => {
    const navigate = useNavigate()
    return (
        <button className="flex items-center gap-x-2 cursor-pointer font-medium text-versich-dark-blue" onClick={onClick ? onClick : navigate(-1)}>
            <IoIosArrowBack className="text-xl" />Back
        </button>
    )
}
export default BackButton
