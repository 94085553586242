import { useState, useEffect, useRef } from "react"
import { BeatLoader } from "react-spinners"

import Meta from "../../../../components/Meta"
import AuthImgBanner from "../../../../components/AuthImgBanner"
import Logo from "../../../../components/Logo"
import openEmailIcon from "../../../../assets/icons/open-email.png"
import ResendVerificationLink from "./ResendVerificationLink"
import { SS_VERIFICATION_EMAIL, dashboardPath } from "../../../../assets/constants"

const metadata = {
    title: "Email Verification",
    description: "Proceed to verify your email address",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/verification/email/`
}

const EmailVerification = () => {
    const resendTriggerStatus = window.sessionStorage.getItem(SS_VERIFICATION_EMAIL.key)
    const triggerResend = resendTriggerStatus === SS_VERIFICATION_EMAIL.value

    const [showResendLink, setShowResendLink] = useState(false)
    const [countdown, setCountdown] = useState(2)
    const timer = useRef()
    useEffect(() => {
        if (triggerResend || countdown <= 0) {
            setShowResendLink(true)
            return clearInterval(timer.current)
        }
        timer.current = setInterval(() => {
            setCountdown(prev => prev - 1)
        }, 1000)
        return () => {
            clearInterval(timer.current)
            window.sessionStorage.removeItem(SS_VERIFICATION_EMAIL.key)
        }
    }, [countdown])
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 min-h-svh">
            <Meta data={metadata} />
            <AuthImgBanner />
            <div className="w-full m-auto space-y-5 lg:space-y-7 p-5 smaller:p-10 max-w-[580px] rounded-lg">
                <Logo href={dashboardPath} />
                <div className="text-6xl pb-5 flex justify-center text-versich-blue">
                    <img className="w-20 h-20" src={openEmailIcon} alt="verification email" />
                </div>
                <div className="text-center space-y-2">
                    <h2 className="text-xl md:text-2xl leading-normal text-versich-darktext-color font-semibold">Email Verification</h2>
                    <p className="text-versich-label">Kindly check your email inbox or spam folder to verify your email address, click the button below to resent verification link.</p>
                </div>
                {showResendLink
                    ? <ResendVerificationLink triggered={triggerResend} />
                    : <BeatLoader
                        loading={true}
                        color="rgb(251 191 36)"
                    />}
            </div>
        </div>
    )
}
export default EmailVerification