import ServicesList from "../../../../assets/ServicesList"

export const fetchService = (refId) => {
    const words = refId.split("_")
    const serviceCode = words[0]
    switch (serviceCode) {
        case "WDAD":
            return ServicesList[0]
        case "MA":
            return ServicesList[1]
        case "DAA":
            return ServicesList[2]
        case "SD":
            return ServicesList[3]
        case "FT":
            return ServicesList[4]
        case "MLAI":
            return ServicesList[5]
        default:
            break
    }
    return null
}