import { useSelector } from "react-redux"

import Meta from "../../../../components/Meta"
import { FREELANCER_TYPES } from "../../../../assets/constants"
import EditFreelancerPrimaryService from "./FreelancerPrimaryService"

const metadata = {
    title: "Edit Primary Service",
    description: "Edit user primary service",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/edit/primary-service/`
}

const EditPrimaryService = () => {
    const { user } = useSelector((state) => state.auth)
    return (<>
        <Meta data={metadata} />
        {FREELANCER_TYPES.includes(user.accountType) && <EditFreelancerPrimaryService />}
    </>)
}
export default EditPrimaryService
