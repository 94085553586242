import { Helmet } from "react-helmet"

const Meta = ({ data }) => {
    return (<>
        <Helmet>
            <title>{data.title}</title>
            <meta name="description" content={data.description} />
            <meta property="og:title" content={data.title} />
            <meta property="og:description" content={data.description} />
        </Helmet>
        {data.url &&
            <Helmet>
                <meta property="og:url" content={data.url} />
                <link rel="canonical" href={data.url} />
            </Helmet>
        }
    </>)
}
export default Meta
