import { useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { IoMdCloseCircle } from "react-icons/io"
import Cookies from "js-cookie"

import DashboardLayout from "../../../components/Dashboard/DashboardLayout"
import ProfileCard from "../../../components/Dashboard/ProfileCard"
import LeadSettingsCard from "../../../components/Dashboard/LeadSettingsCard"
import AboutCard from "../../../components/Dashboard/AboutCard"
import MessagesCard from "../../../components/Dashboard/MessagesCard"
import VersiGemsCard from "./VersiGemsCard"
import HelpCard from "../../../components/Dashboard/HelpCard"
import { COOKIE_FREELANCER_VERSIGEMS_PURCHASE_COUNT } from "../../../assets/constants"
import { FreelancerMenu } from "../../../components/Dashboard/constants"

const FreelancerDashboard = () => {
    const { user } = useSelector(state => state.auth)

    const [purchasedVersiGems, setPurchasedVersiGems] = useState(null)
    useEffect(() => {
        const purchasedVersiGems = Cookies.get(COOKIE_FREELANCER_VERSIGEMS_PURCHASE_COUNT)
        if (purchasedVersiGems) {
            setPurchasedVersiGems(purchasedVersiGems)
        }
        Cookies.remove(COOKIE_FREELANCER_VERSIGEMS_PURCHASE_COUNT)
    }, [])
    return (
        <DashboardLayout navlinks={FreelancerMenu} activePage="Dashboard" showVerifyEmailBanner={true}>
            {purchasedVersiGems &&
                <div className="bg-green-50 text-green-500 font-semibold text-start flex gap-4 justify-between w-full mb-10 px-4 py-2 sm:px-6 sm:py-4 border border-green-500 shadow-sticker rounded-lg">
                    <p>
                        Purchase Complete! <span className="text-green-700 font-medium">You have successfully purchased</span> {purchasedVersiGems} VersiGems. <span className="text-green-700 font-medium">Your new balance is</span> {user.versigems} VersiGems.
                    </p>
                    <button className="hover:text-red-500 transition-colors" onClick={() => setPurchasedVersiGems(null)}>
                        <IoMdCloseCircle className="w-5 h-5" />
                    </button>
                </div>
            }
            <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-3 min-h-[480px]">
                <div className="xl:col-span-2 grid xl:grid-cols-2 flex-col xl:flex-row gap-x-4 gap-y-3">
                    <ProfileCard className="flex-1" />
                    <LeadSettingsCard className="flex-1" />
                    <AboutCard />
                </div>
                <div className="space-y-3 flex-1">
                    <MessagesCard />
                    <VersiGemsCard />
                    <HelpCard />
                </div>
            </div>
        </DashboardLayout>
    )
}
export default FreelancerDashboard