import { useParams, useNavigate } from "react-router-dom"
import { useState, useRef, useEffect } from "react"
import { useDispatch } from "react-redux"
import { BeatLoader } from "react-spinners"

import ErrorPage from "../../components/ErrorPage/ErrorPage"
import Meta from "../../components/Meta"
import AuthImgBanner from "../../components/AuthImgBanner"
import Logo from "../../components/Logo"
import CSRFTokenField from "../../components/Inputs/CSRFTokenField"
import PasswordField from "../../components/Inputs/PasswordField"
import ConfirmButton from "../../components/Buttons/ConfirmButton"
import { forgotPasswordPath, loginPath, TOASTTIMER } from "../../assets/constants"
import { verifyResetPasswordLink, resetPassword } from "../../actions/password"
import toastify from "../../utils/toastify"

const metadata = {
    title: "Reset Password - VersiMarket",
    description: "Set new password for your account"
}

const ResetPassword = () => {
    const { token } = useParams()
    const [status, setStatus] = useState("verifying")
    const [csrfToken, setCsrfToken] = useState("")
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)
    const csrfTokenUpdated = useRef(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        !csrfTokenUpdated.current
            && token
            && (csrfTokenUpdated.current = csrfToken !== "")
            && dispatch(verifyResetPasswordLink(token, csrfToken))
                .then(data => setStatus(data?.status))
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    if (data.status === "error" && data.shortInfo === "link expired") {
                        navigate(forgotPasswordPath)
                        toastify(data.message, data.status, null)
                    }
                    else setStatus(null)
                })
        // eslint-disable-next-line
    }, [csrfToken])

    const handleSubmit = (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (password1 && password2) {
            setLoading(true)
            dispatch(resetPassword(token, password1, password2, csrfToken))
                .then(data => {
                    navigate(loginPath)
                    toastify(data.message, data.status, submitBtn)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    if (data.status === "error" && data.shortInfo === "link expired") {
                        navigate(forgotPasswordPath)
                        toastify(data.message, data.status, submitBtn)
                    }
                    else {
                        setErrorMsg(data.message)
                        setTimeout(() => {
                            setErrorMsg("")
                            submitBtn.disabled = false
                        }, TOASTTIMER)
                    }
                })
                .finally(() => {
                    setLoading(false)
                    e.target.reset()
                })
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
                submitBtn.disabled = false
            }, TOASTTIMER)
        }
    }
    return token && status ?
        <article id="resetpassword-container" className="auth">
            <Meta data={metadata} />
            <Meta title="Reset Password - VersiMarket" description="Set new password for your account" />
            <div className="grid grid-cols-1 lg:grid-cols-2 min-h-svh">
                <AuthImgBanner />
                <div className="w-full m-auto space-y-5 lg:space-y-7 p-5 smaller:p-10 max-w-[580px] rounded-lg">
                    <Logo />
                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                    {status === "verifying" ?
                        <div className="w-full h-80 flex justify-center items-center">
                            <BeatLoader loading={true} color="#1D88ED" size={10} />
                        </div>
                        : <>
                            <div className="text-center space-y-2">
                                <h2 className="text-xl md:text-2xl leading-normal text-versich-darktext-color font-semibold">Reset Password</h2>
                                <p className="text-versich-label">Enter a new and unique password to recover your account</p>
                            </div>
                            <form className="space-y-6 lg:space-y-8 pb-6 lg:pb-8" noValidate onSubmit={handleSubmit}>
                                <div className="space-y-4 md:space-y-5 text-start">
                                    <PasswordField
                                        name="password1"
                                        label="New Password"
                                        placeholder="e.g. example123&_"
                                        onChange={(e) => setPassword1(e.target.value)}
                                        error={{
                                            "message": "Password is required",
                                            "show": error
                                        }}
                                    />
                                    <PasswordField
                                        name="password2"
                                        label="Confirm Password"
                                        placeholder="e.g. example123&_"
                                        onChange={(e) => setPassword2(e.target.value)}
                                        error={{
                                            "message": "Password is required",
                                            "show": error
                                        }}
                                    />
                                    {errorMsg && <div className="text-sm text-red-500 text-left">{errorMsg}</div>}
                                </div>
                                <div className="space-y-3 md:space-y-4 text-start">
                                    <ConfirmButton type="submit" loading={loading}>Submit</ConfirmButton>
                                </div>
                            </form>
                        </>}
                </div>
            </div>
        </article>
        : <ErrorPage status={404} />
}
export default ResetPassword