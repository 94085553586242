import { useSelector } from "react-redux"

import { FREELANCER, BUSINESS } from "../../../../assets/constants"
import FreelancerMessageDetails from "./FreelancerMessageDetails"
import BusinessMessageDetails from "./BusinessMessageDetails"

const MessageDetails = () => {
    const { user } = useSelector(state => state.auth)
    return (<>
        {user.accountType === FREELANCER && <FreelancerMessageDetails />}
        {user.accountType === BUSINESS && <BusinessMessageDetails />}
    </>)
}
export default MessageDetails