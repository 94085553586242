import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import apiErrorParser from "../../utils/apiErrorParser"

export const getFreelancerVersiGemsPlansAsync = createAsyncThunk("account/freelancerVersiGemsPlans", async () => {
    try {
        const { data } = await api.getFreelancerVersiGemsPlans()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createFreelancerCheckoutSessionAsync = createAsyncThunk("account/createFreelancerBusinessCheckoutSession", async ({ priceId, token }) => {
    try {
        const { data } = await api.createFreelancerCheckoutSession({ priceId, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})