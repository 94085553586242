import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import Cookies from "js-cookie"

import Meta from "../../../../../../components/Meta"
import BrandBar from "../../../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../../../components/Inputs/CSRFTokenField"
import BusinessSize from "../../components/BusinessSize"
import Budget from "../../components/Budget"
import ProjectDuration from "../../components/ProjectDuration"
import ClientDetails from "../../components/ClientDetails"
import Description from "../../components/Description"
import {
    loginPath, newRequestPath, requestConfirmationPath,
    SS_FINANCETRANSFORMATION_OTHER, COOKIE_NEW_REQUEST_FT,
    TOASTTIMER, TOASTTYPE_ERROR
} from "../../../../../../assets/constants"
import { createRequestAsync } from "../../../../../../slices/client/service/financeTransformation/"
import toastify from "../../../../../../utils/toastify"
import RequestConfirmationDialogForGuest, { requestConfirmationDialog } from "../../components/RequestConfirmationDialogForGuest"
import { closeDialog, getDialog } from "../../../../../../components/DialogBox"

const metadata = {
    title: "New Request - Other: Finance Transformation Service",
    description: "Other finance transformation new request",
    url: `${process.env.REACT_APP_CLIENT_URL}/request/new/finance-transformation/other/`
}

const OtherFT = () => {
    const { user } = useSelector(state => state.auth)
    const [csrfToken, setCsrfToken] = useState("")
    const [formPosition, setFormPosition] = useState(0)
    const [businessSize, setBusinessSize] = useState("")
    const [service, setService] = useState("")
    const [currency, setCurrency] = useState("")
    const [budget, setBudget] = useState("")
    const [otherBudget, setOtherBudget] = useState(null)
    const [projectDuration, setProjectDuration] = useState("")
    const [otherProjectDuration, setOtherProjectDuration] = useState(null)
    const [clientDetails, setClientDetails] = useState(null)
    const [description, setDescription] = useState("")
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)
    const [requestIDs, setRequestIDs] = useState({ refId: null, pid: null })

    useEffect(() => {
        setService(window.sessionStorage.getItem(SS_FINANCETRANSFORMATION_OTHER))
    }, [])

    // Prefill data from cookie
    useEffect(() => {
        const data = Cookies.get(COOKIE_NEW_REQUEST_FT)
        if (!data) {
            return
        }
        const parsedData = JSON.parse(data)
        parsedData.businessSize && setBusinessSize(parsedData.businessSize)
        parsedData.service && setService(parsedData.service)
        parsedData.currency && setCurrency(parsedData.currency)
        parsedData.budget && setBudget(parsedData.budget)
        parsedData.otherBudget && setOtherBudget(parsedData.otherBudget)
        parsedData.projectDuration && setProjectDuration(parsedData.projectDuration)
        parsedData.otherProjectDuration && setOtherProjectDuration(parsedData.otherProjectDuration)
        parsedData.clientEmail && setClientDetails({
            email: parsedData.clientEmail,
            name: parsedData.clientName,
        })
        parsedData.description && setDescription(parsedData.description)
    }, [])

    // Prefill client details
    useEffect(() => {
        if (!user) {
            return
        }
        setClientDetails({
            email: user.email,
            ...(user.name && { name: user.name }),
        })
    }, [user])

    // Show request confirmation
    const navigate = useNavigate()
    const customOpenDialog = (event) => {
        const dialog = getDialog(event.target)
        if (!dialog) {
            return
        }
        dialog.showModal()
        dialog.addEventListener("click", confirmationCloseListener)
    }
    const confirmationCloseListener = (e) => {
        const dialog = e.currentTarget
        if (!dialog) {
            return
        }
        if (e.target === dialog) {
            navigateGuestToLogin(dialog)
        }
    }
    const navigateGuestToLogin = (dialog) => {
        const dynamicRequestConfimationPath = requestConfirmationPath.replace(":id", requestIDs.refId).replace(":pid", requestIDs.pid)
        const encodedPath = window.encodeURIComponent(dynamicRequestConfimationPath)
        navigate(`${loginPath}?next=${encodedPath}&email=${clientDetails.email}&name=${clientDetails.name}`)
        closeDialog(null, dialog)
    }

    // Create request
    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (!description) {
            setError(true)
            return setTimeout(() => {
                setError(false)
            }, 2000)
        }

        setLoading(true)
        const formData = {
            user, businessSize, service, currency, budget,
            ...(otherBudget && { otherBudget }),
            projectDuration,
            ...(otherProjectDuration && { otherProjectDuration }),
            clientEmail: clientDetails.email,
            clientName: clientDetails.name,
            description,
            token: csrfToken
        }
        await dispatch(createRequestAsync(formData))
            .unwrap()
            .then((data) => {
                if (data.payload) {
                    const dynamicRequestConfimationPath = requestConfirmationPath.replace(":id", data.payload.requestID).replace(":pid", data.payload.pid)
                    if (user) {
                        return navigate(dynamicRequestConfimationPath)
                    }
                    setRequestIDs({ refId: data.payload.requestID, pid: data.payload.pid })
                    customOpenDialog(e)
                }
                else toastify("Something went wrong, while creating request!", TOASTTYPE_ERROR, submitBtn)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                setErrorMsg(data.message)
                setTimeout(() => {
                    setErrorMsg("")
                    submitBtn.disabled = false
                }, TOASTTIMER)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    const totalForms = 5
    return (<>
        <Meta data={metadata} />
        <BrandBar />
        <div className="bg-versich-primary-bg mb-12 py-10 md:py-14 px-3">
            <Meta data={metadata} />
            <form className="flex flex-col" onSubmit={handleSubmit} onKeyDown={e => e.key === "Enter" && !clientDetails && e.preventDefault()} data-parent-id={requestConfirmationDialog}>
                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                {!businessSize
                    ? <BusinessSize totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(1)} setBusinessSize={setBusinessSize} goBack={() => navigate(newRequestPath + "finance-transformation/")} />
                    : !budget
                        ? <Budget totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(2)} setCurrency={setCurrency} setBudget={setBudget} setOtherBudget={setOtherBudget} goBack={() => setBusinessSize("")} />
                        : !projectDuration
                            ? <ProjectDuration totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(3)} setProjectDuration={setProjectDuration} setOtherProjectDuration={setOtherProjectDuration} goBack={() => setBudget("")} />
                            : !clientDetails
                                ? <ClientDetails totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(4)} clientDetails={clientDetails} setClientDetails={setClientDetails} goBack={() => setProjectDuration("")} />
                                : <Description totalForms={totalForms} formPosition={formPosition} updateFormPosition={() => setFormPosition(5)} description={description} setDescription={setDescription} error={error} goBack={() => setClientDetails(null)} />
                }
            </form>
            <RequestConfirmationDialogForGuest pid={requestIDs.pid} redirectToLogin={navigateGuestToLogin} />
            <div className="max_screen_w">
                {errorMsg && <div className="text-red-500 text-sm text-right max-w-[900px] !mt-10 mx-auto">{errorMsg}</div>}
            </div>
            {loading && <FormLoader />}
        </div>
    </>)
}
export default OtherFT