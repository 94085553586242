import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const serviceRelatedFreelancersAsync = createAsyncThunk("account/serviceRelatedFreelancers", async (id) => {
    try {
        const { data } = await api.serviceRelatedFreelancers(id)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const freelancerServiceDetailsSetAsync = createAsyncThunk("account/freelancerServiceDetailsSet",
    async ({ serviceDetails, token }, { dispatch }) => {
        try {
            const formData = new FormData()
            Object.entries(serviceDetails).forEach(([key, value]) => {
                formData.append("services", key)
                formData.append(`descriptions[${key}]`, value.description)
                value.images.forEach(img => {
                    formData.append(`images[${key}]`, img)
                })
            })
            formData.append("csrfmiddlewaretoken", token)
            const { data } = await api.freelancerServiceDetailsSet(formData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const freelancerServiceDetailsUpdateAsync = createAsyncThunk("account/freelancerServiceDetailsUpdate",
    async ({ serviceDetails, token }, { dispatch }) => {
        try {
            const formData = new FormData()
            Object.entries(serviceDetails).forEach(([key, value]) => {
                formData.append("services", key)
                formData.append(`descriptions[${key}]`, value.description)
                value.images.forEach(img => {
                    formData.append(`images[${key}]`, img)
                })
                value.urls.forEach(url => {
                    formData.append(`urls[${key}]`, url)
                })
            })
            formData.append("csrfmiddlewaretoken", token)
            const { data } = await api.freelancerServiceDetailsUpdate(formData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })