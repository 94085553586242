import { useSelector } from "react-redux"
import { Outlet } from "react-router-dom"

import ErrorPage from "../../ErrorPage/ErrorPage"
import { CLIENT } from "../../../assets/constants"

const ClientRoutes = () => {
    const { user } = useSelector(state => state.auth)
    if (user.accountType !== CLIENT) {
        return <ErrorPage status={403} />
    }
    return <Outlet />
}
export default ClientRoutes