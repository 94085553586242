import { formatDistanceToNow } from "date-fns"
import { MdOutlineUpdate } from "react-icons/md"
import { FaRegEye } from "react-icons/fa6"

const MessageCard = ({ data }) => {
    return (
        <div className="flex flex-col p-4 sm:p-6 py-6 border rounded-xl overflow-auto space-y-5">
            <div className="flex-1">
                <p className="text-versich-dark-blue text-lg lg:text-xl font-semibold mb-4">{data.businessName}</p>
                <div className="text-xs md:text-sm flex flex-col smaller:flex-row gap-y-3 gap-x-4">
                    <div className="text-versich-light-gray inline-flex items-center gap-1 md:gap-1.5"><MdOutlineUpdate /> Contacted {formatDistanceToNow(data.createdAt, { addSuffix: true })}</div>
                    {data.read && <div className="text-versich-light-gray inline-flex items-center gap-1 md:gap-1.5"><FaRegEye className="text-versich-blue" /> Message viewed</div>}
                </div>
            </div>
            <div className="text-sm pt-4 pb-2 px-2 md:pt-6 md:pb-4 border-t">
                {data.message}
            </div>
        </div>
    )
}
export default MessageCard