import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import officeBuildingIcon from "../../../../assets/svgs/office-building.svg"
import Meta from "../../../../components/Meta"
import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import InputText from "../../../../components/Inputs/InputTextField"
import toastify from "../../../../utils/toastify"
import BusinessSetupTracker from "./BusinessSetupTracker"
import { TOASTTYPE_ERROR, businessPaths } from "../../../../assets/constants"
import { businessDetailsSetAsync } from "../../../../slices/business/details"

const metadata = {
    title: "Setup - Business Details",
    description: "Fill information about your business to list your business",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/su/business/details/`
}

const BusinessDetailsForm = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [website, setWebsite] = useState("")
    const [email, setEmail] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill form
    useEffect(() => {
        if (user?.business?.details?.data) {
            setCompanyName(user.business.details.data.companyName || "")
            setWebsite(user.business.details.data.website || "")
            setEmail(user.business.details.data.businessEmail || "")
        }
    }, [user])

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                companyname: data.companyName,
                website: data.website,
                businessemail: data.businessEmail,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(businessDetailsSetAsync(formData))
                .unwrap()
                .then(() => {
                    navigate(businessPaths.personalcontact)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <FormProvider {...methods}>
            <div className="bg-[#FBFBFF] min-h-svh">
                <Meta data={metadata} />
                <BrandBar />
                <div className="max_screen_w mb-12 py-8 md:py-10 px-4 md:px-8 lg:px-16">
                    <div className="space-y-3 pb-8 md:pb-10">
                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl text-left font-medium">Business Listings</h1>
                        <p className="text-versich-dark-blue/80 text-xs sm:text-sm text-left font-medium">Complete details below to list your business</p>
                    </div>
                    <div className="flex max-md:flex-col-reverse gap-5">
                        <div className="bg-white w-full mx-auto px-4 smaller:px-8 xl:px-12 py-8 rounded-[11px] shadow-card2">
                            <div className="flex gap-3 pb-4 border-b border-[#EFEEEE99]">
                                <img src={officeBuildingIcon} alt="" />
                                <div className="font-medium text-left space-y-[7px]">
                                    <h1 className="text-versich-dark-blue text-lg sm:text-xl">Business Details</h1>
                                    <p className="text-versich-dark-blue/80 text-xs sm:text-sm">Enter required details below</p>
                                </div>
                            </div>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <div className="space-y-5 py-7">
                                    <InputText name="companyName" inputType="text" placeholder="e.g. Business Solution" label="Company Name" onChange={(e) => setCompanyName(e.target.value)} defaultValue={companyName} rules={{ required: "Company name is required" }} />
                                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                    <InputText name="website" inputType="url" placeholder="e.g. https://a2zsolutions.com" label="Business Website" onChange={(e) => setWebsite(e.target.value)} defaultValue={website} />
                                    <InputText name="businessEmail" inputType="email" placeholder="e.g. ronald@a2zsolutions.com" label="Business Email Address" onChange={(e) => setEmail(e.target.value)} defaultValue={email} rules={{ required: "Email address is required" }} />
                                </div>
                                {errorMsg && <div className="text-sm text-red-500 text-left mt-4">{errorMsg}</div>}
                                <div className="text-sm font-semibold text-right w-full py-6">
                                    <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Continue</button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <BusinessSetupTracker completed={[
                                "Business Details"
                            ]} />
                        </div>
                    </div>
                </div>
                {loading && <FormLoader />}
            </div>
        </FormProvider>
    )
}
export default BusinessDetailsForm
