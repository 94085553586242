import FormLoader from "../loader/FormLoader"

const ConfirmButton = ({ children, id, className, type, handleClick, loading }) => {
    return (
        <div>
            <button id={id ? id : null} className={className ? className : "bg-versich-blue hover:bg-versich-blue-hover w-full text-white rounded-lg py-2 lg:py-3 font-medium"} type={type} onClick={handleClick}>{children}</button>
            {loading && <FormLoader />}
        </div>
    )
}
export default ConfirmButton
