import worldwideIcon from "../assets/svgs/worldwide.svg"
import locationIcon from "../assets/svgs/location.svg"


// Paths
export const homePath = "/"
export const servicesPath = "/services/"
export const businessListingPath = "/business-listing/"
export const dashboardPath = "/dashboard/"
export const businessPricingPath = "/dashboard/business/pricing/"
export const buyVersiGemsPath = "/dashboard/plans/versigems/buy/"
export const editProfilePath = "/dashboard/edit/profile/"
export const editPrimaryServicePath = "/dashboard/edit/primary-service/"
export const editLeadsLocationPath = "/dashboard/edit/leads/"
export const editOtherServicesPath = "/dashboard/edit/other-services/"
export const editServiceDetailsPath = "/dashboard/edit/service-details/"
export const checkoutPaymentsSuccessPath = "/payments/checkout/success/"
export const newRequestPath = "/request/new/"
export const requestConfirmationPath = "/dashboard/request/:id/confirmation/:pid/"
export const requestDetailsPath = "/dashboard/request/:id/details/"
export const allRequestPath = "/dashboard/request/all/"
export const messageDetailsPath = "/dashboard/message/:id/details/"
export const allMessagePath = "/dashboard/message/all/"
export const serviceRelatedfreelancerPath = "/dashboard/freelancer/service/:id/"
export const signupPath = "/auth/signup/"
export const clientSignupPath = "/auth/client/signup/"
export const freelancerSignupPath = "/auth/freelancer/signup/"
export const businessSignupPath = "/auth/business/signup/"
export const loginPath = "/auth/login/"
export const forgotPasswordPath = "/auth/forgot-password/"
export const setupPath = "/auth/su/"
export const emailVerificationPath = "/auth/verification/email/"
export const emailTokenVerificationPath = "/auth/verification/:token/email/"

// Conditional paths based on account type
export const freelancerPaths = {
    root: "/auth/su/freelancer/",
    primaryservice: "/auth/su/freelancer/primary-service/",
    leads: "/auth/su/freelancer/leads/",
    otherservices: "/auth/su/freelancer/other-services/",
    servicedetails: "/auth/su/freelancer/service-details/"
}
export const businessPaths = {
    root: "/auth/su/business/",
    details: "/auth/su/business/details/",
    personalcontact: "/auth/su/business/personal-contact/",
    about: "/auth/su/business/about/",
    location: "/auth/su/business/location/",
    socialprofiles: "/auth/su/business/social-profiles/",
    competitors: "/auth/su/business/competitors/",
    reviewandreferences: "/auth/su/business/review-and-references/",
}
export const businessSetupPaths = Object.keys(businessPaths).map(key => businessPaths[key])

// Accounts
export const CLIENT = "client"
export const FREELANCER = "freelancer"
export const BUSINESS = "business"
export const ACCOUNT_TYPES = [CLIENT, FREELANCER, BUSINESS]
export const FREELANCER_TYPES = [FREELANCER, BUSINESS]

// States
export const verificationStates = {
    verifying: {
        code: "verifying",
        text: "Verifying"
    },
    verified: {
        code: "verified",
        text: "Verified"
    },
    expired: {
        code: "expired",
        text: "Expired"
    }
}

// Leads location
export const regionCoverageOptions = [{
    value: "worldwide",
    title: "Worldwide",
    desc: "By selecting world wide means your serve customers worldwide",
    bgColor: "majorelle-blue",
    icon: worldwideIcon,
}, {
    value: "custom",
    title: "My Location Only",
    desc: "By selecting my location only means your serve customers only in your location",
    bgColor: "fluorescent-cyan",
    icon: locationIcon,
}]
export const regionCoverage = Object.fromEntries(regionCoverageOptions.map(coverage => [coverage.value, {
    title: coverage.title,
    value: coverage.value
}]))

export const companySizes = [{
    uid: "soletrader",
    title: "Self-employed, Sole trader"
}, {
    uid: "2-10",
    title: "2-10"
}, {
    uid: "11-50",
    title: "11-50"
}, {
    uid: "51-200",
    title: "51-200"
}]

// Cookie keys
export const SS_REMEMBER_ME = "remember_me"
export const SS_AUTH_ERROR = { key: "auth_error", value: "" }
export const SS_VERIFICATION_EMAIL = { key: "verification_email", value: "resend" }
export const SS_VERIFICATION_STATUS = { key: "verification_status", value: "verified" }
export const SS_ACCOUNT_TYPE = { key: "account_type", value: null }
export const SS_FINANCETRANSFORMATION_OTHER = "financeTransformation_other"
export const COOKIE_NEW_REQUEST_WDAD = "new_service_request_WDAD"
export const COOKIE_NEW_REQUEST_MA = "new_service_request_MA"
export const COOKIE_NEW_REQUEST_DAA_BU = "new_service_request_DAA_BU"
export const COOKIE_NEW_REQUEST_DAA_PE = "new_service_request_DAA_PE"
export const COOKIE_NEW_REQUEST_SD = "new_service_request_SD"
export const COOKIE_NEW_REQUEST_FT_FPAR = "new_service_request_FT_FPAR"
export const COOKIE_NEW_REQUEST_FT_FRAA = "new_service_request_FT_FRAA"
export const COOKIE_NEW_REQUEST_FT_EN = "new_service_request_FT_EN"
export const COOKIE_NEW_REQUEST_FT_SA = "new_service_request_FT_SA"
export const COOKIE_NEW_REQUEST_FT_DT = "new_service_request_FT_DT"
export const COOKIE_NEW_REQUEST_FT = "new_service_request_FT"
export const COOKIE_NEW_REQUEST_MLAI = "new_service_request_MLAI"
export const COOKIE_REQUEST_REFID = "service_request_refid"
export const COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION = "temp_message_details_path_after_new_subscription"
export const COOKIE_FREELANCER_VERSIGEMS_PURCHASE_COUNT = "temp_freelancer_versigems_purchase_count"

// Class
export const EMPTY = "empty"
export const HIGHPARENT = "highlight-parent"
export const SHOW = "show"

// Id
export const contactFreelancerDialog = "contactfreelancer-dialog"

// Values
export const TOASTTIMER = 3500
export const TOASTTYPE_INFO = "info"
export const TOASTTYPE_SUCCESS = "success"
export const TOASTTYPE_WARN = "warn"
export const TOASTTYPE_ERROR = "error"

// VersiGems
export const freelancerUnlockOptions = {
    message: {
        label: "Message",
        key: "message",
    },
    request: {
        label: "Request Details",
        key: "request",
    },
    client: {
        label: "Client Details",
        key: "client",
    }
}