import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../../api"
import apiErrorParser from "../../../../utils/apiErrorParser"

export const allAssistanceNeedAsync = createAsyncThunk("service/DAA/personal/allAssistanceNeed", async (params) => {
    try {
        const { data } = await api.personalAssistanceNeedsDAA(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allChoiceOfToolAsync = createAsyncThunk("service/DAA/personal/allChoiceOfTool", async (params) => {
    try {
        const { data } = await api.personalChoiceOfToolsDAA(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createRequestAsync = createAsyncThunk("account/DAA/personal/createRequest",
    async ({
        user, need, tools, projectDuration, otherProjectDuration, currency, budget, otherBudget,
        clientEmail, clientName, description, token
    }) => {
        try {
            const reqData = {
                assistanceNeedId: need,
                toolsIds: tools,
                projectDurationId: projectDuration,
                otherProjectDuration,
                currencyId: currency,
                estimatedBudgetId: budget,
                otherEstimatedBudget: otherBudget,
                clientEmail,
                clientName,
                description,
                csrfmiddlewaretoken: token
            }
            const { data } = user ? await api.personalCreateRequestDAA(reqData) : await api.personalCreateRequestAsGuestDAA(reqData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const getRequestAsync = createAsyncThunk("account/DAA/personal/getRequest", async (refId) => {
    try {
        const { data } = await api.personalGetRequestDAA(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getRequestTitleAsync = createAsyncThunk("account/DAA/personal/getRequestTitle", async (refId) => {
    try {
        const { data } = await api.personalGetRequestTitleDAA(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})