import { FaCircleCheck } from "react-icons/fa6"

const BusinessSetupTracker = ({ completed = [] }) => {
    return (
        <div className="bg-white sm:min-w-[288px] px-4 md:px-8 py-8 rounded-[11px] shadow-card2">
            <div className="flex items-center ml-9 relative">
                <div className="space-y-[26px]">
                    {setupFlows.map((flow, index) => (
                        <div className="relative z-10" key={index}>
                            <div className="text-versich-dark-blue flex flex-col justify-center text-left">
                                <span className="text-sm font-medium">{flow.title}</span>
                                <span className="text-[10px]">{flow.description}</span>
                                {completed.includes(flow.title)
                                    ? <FaCircleCheck className="text-versich-blue absolute -left-[39px] top-2 w-5 h-5" />
                                    : <div className="bg-white text-white border border-versich-blue absolute -left-[39px] w-5 h-5 rounded-xl shadow-ring" />
                                }
                            </div>
                        </div>
                    ))}
                </div>

                {/* Left-side indicator track */}
                <div className="bg-[#F1F8FF] absolute -left-[30px] h-[90%] w-[3px] rounded-full" />
            </div>
        </div>
    )
}
const setupFlows = [{
    title: "Business Details",
    description: "Complete your business details"
},
{
    title: "Personal Contact",
    description: "Complete your personal details"
},
{
    title: "About Business",
    description: "Tell us more about your business"
},
{
    title: "Business Location",
    description: "Complete your business location"
},
{
    title: "Social Profiles",
    description: "Complete your social profile"
},
{
    title: "Competitors",
    description: "Tell us about your competitors"
},
{
    title: "Review and References",
    description: "Tell us about your competitors"
}]
export default BusinessSetupTracker