import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { PiArrowRightBold } from "react-icons/pi"

import { dashboardPath, newRequestPath } from "../../assets/constants"

const ServiceCard = ({ service }) => {
    const { user } = useSelector(state => state.auth)

    const navigate = useNavigate()
    const navigateToRequestForm = () => {
        if (!user || (user && user.accountType === "client")) {
            return navigate(`${newRequestPath + service.uid}/`)
        }
        navigate(dashboardPath + "#underDevelopment")
    }
    return (
        <a className="mx-4 my-3 w-[255px] md:w-[325px] shadow-card rounded-2xl text-center group scroll-snap-align-start scroll-item">
            <div className="h-[120px] md:h-[170px] w-[225px] md:w-[325px] relative overflow-hidden rounded-tr-2xl rounded-tl-2xl" onClick={navigateToRequestForm}>
                <img src={service.image} alt={service.name} className="object-cover w-full h-full transition-transform duration-400 cursor-pointer hover:scale-110" />
            </div>
            <div className="h-auto text-start px-4 py-2 md:py-4">
                <p className="text-versich-darktext-color font-bold text-sm md:text-base lg:text-lg mb-2">{service.name}</p>
                <span
                    className="flex text-xs md:text-sm cursor-pointer items-center gap-x-2 text-versich-light-blue font-medium hover:text-versich-blue hover:gap-x-4 transition-all duration-300 ease-in-out"
                    onClick={navigateToRequestForm}
                >
                    Find a professional
                    <PiArrowRightBold />
                </span>
            </div>
        </a>
    )
}
export default ServiceCard
