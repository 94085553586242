import { Outlet } from "react-router-dom"

import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"

const HeaderFooterRoutes = () => {
    return (<>
        <Navbar />
        <Outlet />
        <Footer />
    </>)
}
export default HeaderFooterRoutes