import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import socialProfilesIcon from "../../../../assets/svgs/social-profiles.svg"
import facebookIcon from "../../../../assets/social-icons/facebook_color.svg"
import xIcon from "../../../../assets/social-icons/x_color.svg"
import linkedinIcon from "../../../../assets/social-icons/linkedin_color.svg"
import pinterestIcon from "../../../../assets/social-icons/pinterest_color.svg"
import tiktokIcon from "../../../../assets/social-icons/tiktok_color.svg"
import instagramIcon from "../../../../assets/social-icons/instagram_color.svg"
import Meta from "../../../../components/Meta"
import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import InputText from "../../../../components/Inputs/InputTextField"
import toastify from "../../../../utils/toastify"
import BusinessSetupTracker from "./BusinessSetupTracker"
import { TOASTTYPE_ERROR, businessPaths } from "../../../../assets/constants"
import { businessSocialProfilesSetAsync } from "../../../../slices/business/social-profiles"

const metadata = {
    title: "Setup - Social Profiles",
    description: "Fill information about your business to list your business",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/su/business/social-profiles/`
}

const BusinessSocialProfilesForm = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [facebook, setFacebook] = useState("")
    const [xLink, setXlink] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [pinterest, setPinterest] = useState("")
    const [tiktok, setTiktok] = useState("")
    const [instagram, setInstagram] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill form
    useEffect(() => {
        if (user?.business?.socialProfiles?.data) {
            setFacebook(user.business.socialProfiles.data.facebook || "")
            setXlink(user.business.socialProfiles.data.x || "")
            setLinkedin(user.business.socialProfiles.data.linkedin || "")
            setPinterest(user.business.socialProfiles.data.pinterest || "")
            setTiktok(user.business.socialProfiles.data.tiktok || "")
            setInstagram(user.business.socialProfiles.data.instagram || "")
        }
    }, [user])

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                facebook: data.facebook,
                x: data.xLink,
                linkedin: data.linkedin,
                pinterest: data.pinterest,
                tiktok: data.tiktok,
                instagram: data.instagram,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(businessSocialProfilesSetAsync(formData))
                .unwrap()
                .then(() => {
                    navigate(businessPaths.competitors)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <FormProvider {...methods}>
            <div className="bg-[#FBFBFF] min-h-svh">
                <Meta data={metadata} />
                <BrandBar />
                <div className="max_screen_w mb-12 py-8 md:py-10 px-4 md:px-8 lg:px-16">
                    <div className="space-y-3 pb-8 md:pb-10">
                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl text-left font-medium">Business Listings</h1>
                        <p className="text-versich-dark-blue/80 text-xs sm:text-sm text-left font-medium">Complete details below to list your business</p>
                    </div>
                    <div className="flex max-md:flex-col-reverse gap-5">
                        <div className="bg-white w-full mx-auto px-4 smaller:px-8 xl:px-12 py-8 rounded-[11px] shadow-card2">
                            <div className="flex gap-3 pb-4 border-b border-[#EFEEEE99]">
                                <img src={socialProfilesIcon} alt="" />
                                <div className="font-medium text-left space-y-[7px]">
                                    <h1 className="text-versich-dark-blue text-lg sm:text-xl">Social Profiles</h1>
                                    <p className="text-versich-dark-blue/80 text-xs sm:text-sm">Enter required details below</p>
                                </div>
                            </div>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <div className="space-y-5 py-7">
                                    <div className="grid grid-cols-1 gap-x-10 gap-5 lg:grid-cols-2">
                                        <div className="flex items-center gap-3">
                                            <img className="w-[30px] h-[30px]" src={facebookIcon} alt="" />
                                            <div className="flex-1">
                                                <InputText name="facebook" inputType="url" placeholder="Enter Facebook Link" onChange={(e) => setFacebook(e.target.value)} defaultValue={facebook} />
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <img className="w-[30px] h-[30px]" src={xIcon} alt="" />
                                            <div className="flex-1">
                                                <InputText name="xLink" inputType="url" placeholder="Enter X Link" onChange={(e) => setXlink(e.target.value)} defaultValue={xLink} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-x-10 gap-5 lg:grid-cols-2">
                                        <div className="flex items-center gap-3">
                                            <img className="w-[30px] h-[30px]" src={linkedinIcon} alt="" />
                                            <div className="flex-1">
                                                <InputText name="linkedin" inputType="url" placeholder="Enter Linkedin Link" onChange={(e) => setLinkedin(e.target.value)} defaultValue={linkedin} />
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <img className="w-[30px] h-[30px]" src={pinterestIcon} alt="" />
                                            <div className="flex-1">
                                                <InputText name="pinterest" inputType="url" placeholder="Enter Pinterest Link" onChange={(e) => setPinterest(e.target.value)} defaultValue={pinterest} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-x-10 gap-5 lg:grid-cols-2">
                                        <div className="flex items-center gap-3">
                                            <img className="w-[30px] h-[30px]" src={tiktokIcon} alt="" />
                                            <div className="flex-1">
                                                <InputText name="tiktok" inputType="url" placeholder="Enter Tiktok Link" onChange={(e) => setTiktok(e.target.value)} defaultValue={tiktok} />
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-3">
                                            <img className="w-[30px] h-[30px]" src={instagramIcon} alt="" />
                                            <div className="flex-1">
                                                <InputText name="instagram" inputType="url" placeholder="Enter Instagram Link" onChange={(e) => setInstagram(e.target.value)} defaultValue={instagram} />
                                            </div>
                                        </div>
                                    </div>
                                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                </div>
                                {errorMsg && <div className="text-sm text-red-500 text-left mt-4">{errorMsg}</div>}
                                <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                                    <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(businessPaths.location)}>Back</button>
                                    <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Continue</button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <BusinessSetupTracker completed={[
                                "Business Details", "Personal Contact", "About Business",
                                "Business Location", "Social Profiles"
                            ]} />
                        </div>
                    </div>
                </div>
                {loading && <FormLoader />}
            </div>
        </FormProvider>
    )
}
export default BusinessSocialProfilesForm
