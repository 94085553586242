import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import InputText from "../../../../components/Inputs/InputTextField"
import PhoneNumberField from "../../../../components/Inputs/PhoneNumberField"
import CountrySelectionField from "../../../../components/Inputs/CountrySelectionField"
import ChoiceButton from "../../../../components/Buttons/ChoiceButton"
import toastify from "../../../../utils/toastify"
import { dashboardPath, TOASTTYPE_ERROR } from "../../../../assets/constants"
import { clientUpdateAboutAsync } from "../../../../slices/client/about"

const EditClientProfile = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()
    const navigate = useNavigate()

    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberValid, setPhoneNumberValid] = useState(false)
    const [selectedCountry, setSelectedCountry] = useState("")
    const [regionError, setRegionError] = useState(false)
    const [hasLinkedinProfile, setHasLinkedinProfile] = useState(false)
    const [linkedinUrl, setLinkedinUrl] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!user || !user.about) {
            return
        }
        setFirstName(user.about.firstName)
        setLastName(user.about.lastName)
        if (!user.about.phone) {
            return
        }
        const code = user.about.phone.code.replace("+", "")
        const fullPhoneNumber = code + user.about.phone.number
        setPhoneCode(code)
        setPhoneNumber(user.about.phone.number)
        setPhoneNumberValid(validateNumber(fullPhoneNumber))
        setSelectedCountry(user.about.country.iso3)
        if (user.about.linkedinUrl) {
            setHasLinkedinProfile(true)
            setLinkedinUrl(user.about.linkedinUrl)
        }
    }, [user])

    // Validate Phone number
    const validateNumber = (phoneNumber) => {
        if (!phoneNumber.trim()) {
            return false
        }
        const phoneNumberPattern = /^\+?\d{1,}$/
        return phoneNumberPattern.test(phoneNumber)
    }
    const handlePhoneNumberChange = (phone, country) => {
        setPhoneCode(country.dialCode)
        setPhoneNumber(phone.replace(country.dialCode, ""))
        setPhoneNumberValid(validateNumber(phone))
    }

    const dispatch = useDispatch()
    const onSubmit = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid || !phoneNumberValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }

            if (!selectedCountry) {
                setRegionError(true)
                return
            }
            const formData = {
                token: csrfToken,
                firstname: firstName,
                lastname: lastName,
                phonecode: `+${phoneCode}`,
                phonenumber: phoneNumber,
                countryiso3: selectedCountry,
                ...(hasLinkedinProfile && {
                    linkedin_url: hasLinkedinProfile ? data.linkedin : null,
                }),
            }
            setLoading(true)
            await dispatch(clientUpdateAboutAsync(formData))
                .unwrap()
                .then(() => {
                    navigate(dashboardPath)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => {
                    setRegionError(false)
                    setLoading(false)
                })
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <FormProvider {...methods}>
            <BrandBar />
            <div className="bg-white mb-12 py-10 md:py-14 px-3 sm:px-8 md:px-16 lg:px-32 xl:px-52">
                <div className="w-full max-w-[1080px] mx-auto px-4 smaller:px-12 xl:px-20 py-8 rounded-md shadow-formLight">
                    <div className="space-y-5 pb-6 border-b border-[#EFEEEE99]">
                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl font-medium flex justify-center">Edit your profile details</h1>
                    </div>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-5">
                        <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                        <div className="grid grid-cols-1 gap-x-5 gap-6 md:grid-cols-2">
                            <InputText name="firstname" inputType="text" label="First Name" rules={{ required: "First name is required" }} onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} />
                            <InputText name="lastname" inputType="text" label="Last Name" rules={{ required: "Last name is required" }} onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} />
                        </div>
                        <PhoneNumberField name="phonenumber" onChange={handlePhoneNumberChange} rules={{ required: "This field is required" }} value={phoneCode + phoneNumber} />
                        <CountrySelectionField name="country" label="Country" selectedCountry={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)} regionError={regionError} />
                        <div className="text-start flex flex-col">
                            <p className="mb-2 text-versich-label text-sm">Do you have a linkedin profile?</p>
                            <div className="flex gap-2.5">
                                <ChoiceButton selected={hasLinkedinProfile} onClick={() => setHasLinkedinProfile(prev => !prev)}>Yes</ChoiceButton>
                                <ChoiceButton selected={!hasLinkedinProfile} onClick={() => setHasLinkedinProfile(prev => !prev)}>No</ChoiceButton>
                            </div>
                        </div>
                        {hasLinkedinProfile &&
                            <InputText name="linkedin" inputType="url" placeholder="Linkedin url" onChange={(e) => setLinkedinUrl(e.target.value)} defaultValue={linkedinUrl} rules={{ required: "Field is required" }} />
                        }
                        {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                        <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                            <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(dashboardPath)}>Cancel</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Update</button>
                        </div>
                    </form>
                </div>
            </div>
            {loading && <FormLoader />}
        </FormProvider>
    )
}
export default EditClientProfile
