import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"
import { BUSINESS, freelancerUnlockOptions } from "../../assets/constants"

// Client
export const directMessageToUserAsync = createAsyncThunk("account/directMessageToUser",
    async ({ requestRefId, expertId, message, token }) => {
        try {
            const { data } = await api.directMessageToUser({ requestRefId, expertId, message, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const allClientDirectMessageOfRequestAsync = createAsyncThunk("service/allClientDirectMessageOfRequest", async (requestRefId) => {
    try {
        const { data } = await api.allClientDirectMessageOfRequest(requestRefId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

// Freelancer
export const allFreelancerDirectMessageAsync = createAsyncThunk("service/allFreelancerDirectMessage", async (_, { dispatch }) => {
    try {
        const { data } = await api.allFreelancerDirectMessage()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getFreelancerDirectMessageAsync = createAsyncThunk("service/getFreelancerDirectMessage", async (id, { dispatch }) => {
    try {
        const { data } = await api.getFreelancerDirectMessage(id)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const unlockFreelancerDirectMessageRestrictionAsync = createAsyncThunk("service/unlockFreelancerDirectMessageRestriction", async ({ id, restriction }, { dispatch }) => {
    try {
        const { data } = restriction === freelancerUnlockOptions.message.key
            ? await api.unlockFreelancerDirectMessage(id)
            : restriction === freelancerUnlockOptions.client.key
                ? await api.unlockFreelancerDirectMessageClient(id)
                : await api.unlockFreelancerDirectMessageRequest(id)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

// Business
export const allBusinessDirectMessageAsync = createAsyncThunk("service/allBusinessDirectMessage", async (_, { dispatch }) => {
    try {
        const { data } = await api.allBusinessDirectMessage()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getBusinessDirectMessageAsync = createAsyncThunk("service/getBusinessDirectMessage", async (id, { dispatch }) => {
    try {
        const { data } = await api.getBusinessDirectMessage(id)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const toggleDirectMessageReadStatusAsync = createAsyncThunk("service/toggleDirectMessageReadStatus",
    async ({ id, accountType, token }, { dispatch }) => {
        try {
            const reqData = { messageId: id, csrfmiddlewaretoken: token }
            const { data } = accountType === BUSINESS ? await api.toggleBusinessDirectMessageReadStatus(reqData) : await api.toggleFreelancerDirectMessageReadStatus(reqData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })