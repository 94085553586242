import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import { allWebsiteRequirementAsync } from "../../../../slices/client/service/webDesignAndDevelopment"

const WebsiteRequirement = ({ navigateToRequestForm }) => {
    const [data, setData] = useState([])

    const dispatch = useDispatch()
    useEffect(() => {
        const params = new URLSearchParams({ "exclude": "other" }).toString()
        dispatch(allWebsiteRequirementAsync(`?${params}`))
            .unwrap()
            .then((data) => {
                if (data.status === "success") {
                    setData(data.payload)
                }
            })
        // eslint-disable-next-line
    }, [])

    const handleClick = (uid) => {
        const params = new URLSearchParams({ "requirement": uid }).toString()
        navigateToRequestForm(`?${params}`)
    }
    return (
        <div className="group/section mt-6 first-of-type:mt-0">
            <div className="text-versich-label font-medium text-sm md:text-base text-left ml-4 mb-2 relative">
                <span>Based on requirement</span>
                <div className="shadow-form shadow-versich-blue group-hover/section:shadow-purple-400 backdrop-blur-2xl absolute -left-4 bottom-2.5 transition-shadow group-hover/section:animate-pulse" />
            </div>
            <div className="flex flex-wrap">
                {data.map(item => (
                    <div className="bg-gradient-to-r from-purple-400 via-versich-blue/30 to-purple-300 m-2 lg:m-3 xl:m-4 rounded-md relative group/service-tag cursor-pointer" onClick={() => handleClick(item.uid)} key={item.uid}>
                        <div className="bg-white/60 h-full backdrop-filter backdrop-blur-sm shadow-lg ring-1 ring-gray-200/50 rounded-md">
                            <div className="bg-white text-versich-blue group-hover/service-tag:text-purple-400 text-sm md:text-base mb-1 p-2 rounded-md">{item.title}</div>
                        </div>
                    </div>
                    // <div className="bg-gradient-to-r from-purple-400 via-versich-blue/30 to-purple-300 m-4 rounded-md relative group cursor-pointer" key={item.uid}>
                    //     <div className="bg-white/60 h-full backdrop-filter backdrop-blur-sm shadow-lg ring-1 ring-gray-200/50 rounded-md">
                    //         <div className="bg-white text-versich-blue group-hover/service-tag:text-purple-400 text-sm md:text-base mb-1 p-2 rounded-md">{item.title}</div>
                    //     </div>
                    //     <div className="shadow-form shadow-versich-blue group-hover/service-tag:shadow-purple-400 backdrop-blur-2xl absolute left-1 bottom-1" />
                    // </div>
                ))}
            </div>
        </div>
    )
}
export default WebsiteRequirement