import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { useForm, FormProvider } from "react-hook-form"

import Meta from "../../components/Meta"
import AuthImgBanner from "../../components/AuthImgBanner"
import Logo from "../../components/Logo"
import CSRFTokenField from "../../components/Inputs/CSRFTokenField"
import InputTextField from "../../components/Inputs/InputTextField"
import ConfirmButton from "../../components/Buttons/ConfirmButton"
import SocialAccounts from "./socialAccounts/SocialAccounts"
import {
    SS_AUTH_ERROR, SS_REMEMBER_ME,
    forgotPasswordPath, signupPath
} from "../../assets/constants"
import { loginUser } from "../../actions/auth"

const metadata = {
    title: "Login",
    description: "Login to your account",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/login/`
}

const Login = () => {
    const methods = useForm()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [csrfToken, setCsrfToken] = useState("")
    const [rememberMe, setRememberMe] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // OAuth2 authentication error
    useEffect(() => {
        const authError = window.sessionStorage.getItem(SS_AUTH_ERROR.key)
        if (authError) {
            setErrorMsg(authError)
            window.sessionStorage.removeItem(SS_AUTH_ERROR.key)
        }
        const qEmail = new URLSearchParams(location.search).get("email")
        if (qEmail) {
            setEmail(qEmail)
        }
        else {
            const rememberMeData = window.sessionStorage.getItem(SS_REMEMBER_ME)
            if (!rememberMeData) {
                return
            }
            const { email: rEmail, password: rPassword } = JSON.parse(rememberMeData)
            setEmail(rEmail)
            try {
                setPassword(window.atob(rPassword))
            }
            catch (error) {
                setPassword(rPassword)
            }
            setRememberMe(true)
        }
    }, [])

    const dispatch = useDispatch()
    const handleLogin = async () => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                return
            }
            setLoading(true)
            const result = await dispatch(loginUser(email, password, csrfToken))
            if (result.status !== "success") {
                return
            }
            if (rememberMe) {
                window.sessionStorage.setItem(SS_REMEMBER_ME, JSON.stringify({ email, password: window.btoa(password) }))
            }
            else window.sessionStorage.removeItem(SS_REMEMBER_ME)
            methods.reset()
        }
        catch (error) {
            if (error.response && error.response.status) {
                setErrorMsg(error.message)
                return error
            }
            setErrorMsg(error.message)
        }
        finally {
            setLoading(false)
        }
    }

    const location = useLocation()
    return (
        <div>
            <Meta data={metadata} />
            <div className="grid grid-cols-1 lg:grid-cols-2 min-h-svh">
                <AuthImgBanner />
                <div className="w-full m-auto space-y-5 lg:space-y-7 p-5 smaller:p-10 max-w-[580px] rounded-lg">
                    <Logo />
                    <div className="text-center space-y-2">
                        <h2 className="text-xl md:text-2xl leading-normal text-versich-darktext-color font-semibold">Log in to your account</h2>
                        <p className="text-versich-label">Complete the details below to log in to your account</p>
                    </div>
                    <FormProvider {...methods}>
                        <form className="space-y-6 lg:space-y-8 pb-6 lg:pb-8" noValidate onSubmit={methods.handleSubmit(handleLogin)}>
                            <div className="space-y-4 md:space-y-5 text-start">
                                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                <InputTextField
                                    name="email"
                                    inputType="email"
                                    label="Email address"
                                    placeholder="Enter your email"
                                    rules={{ required: "Field is required" }}
                                    onChange={(e) => setEmail(e.target.value)}
                                    defaultValue={email}
                                />
                                <InputTextField
                                    name="password"
                                    inputType="password"
                                    label="Password"
                                    placeholder="************"
                                    rules={{ required: "Password is required" }}
                                    onChange={(e) => setPassword(e.target.value)}
                                    defaultValue={password}
                                />
                                {errorMsg && <div className="text-sm text-red-500 text-left">{errorMsg}</div>}
                                <div className="flex flex-wrap justify-between gap-4">
                                    <div className="flex items-center">
                                        <input type="checkbox" className="mr-2" id="remember" onChange={() => setRememberMe(prev => !prev)} checked={rememberMe} />
                                        <label htmlFor="remember" className="text-sm text-versich-label hover:text-versich-blue-hover">Remember me</label>
                                    </div>
                                    <Link className="text-versich-blue hover:text-versich-blue-hover block text-sm hover:underline text-right" to={forgotPasswordPath}>Forget Password?</Link>
                                </div>
                            </div>
                            <div className="space-y-3 md:space-y-4 text-start">
                                <ConfirmButton type="submit" loading={loading}>{loading ? "Logging in..." : "Login"}</ConfirmButton>
                                <SocialAccounts google={{ url: process.env.REACT_APP_API_GOOGLE_OAUTH2_URL, text: "Continue with Google" }} />
                                <p className="text-sm text-center">
                                    Don't have an account?{" "}
                                    <Link className="text-versich-blue hover:text-versich-blue-hover hover:underline font-semibold" to={signupPath + location.search}>Sign up</Link>
                                </p>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
        </div>
    )
}
export default Login
