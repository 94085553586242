import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import Meta from "../../../../components/Meta"
import FreelancerCard from "../../../../components/Requests/BusinessCard"
import ContactFreelancerDialog from "../../../../components/Requests/ContactFreelancerDialog"
import { ClientMenu } from "../../../../components/Dashboard/constants"
import { serviceRelatedFreelancersAsync } from "../../../../slices/freelancer"

const metadata = {
    title: "Service Related Freelancers",
    description: "List of freelancers based on services and popularity",
}

const RelatedFreelancers = () => {
    const { id } = useParams()
    const [relatedFreelancers, setRelatedFreelancers] = useState([])
    const [recommendedFreelancers, setRecommendedFreelancers] = useState([])
    const [expertId, setExpertId] = useState("")
    const [messageSent, setMessageSent] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(serviceRelatedFreelancersAsync(id))
            .unwrap()
            .then(data => {
                setRelatedFreelancers(data.relatedFreelancers)
                setRecommendedFreelancers(data.recommendedFreelancers)
            })
            .catch(() => { })
    }, [])
    return (
        <DashboardLayout navlinks={ClientMenu} activePage="My Requests">
            <div className="text-start mb-5 lg:mb-12">
                <Meta data={metadata} />
                <div className="space-y-3">
                    {messageSent &&
                        <div className="bg-cyan-100 shadow-sticker w-full px-4 py-2 sm:px-6 sm:py-4 font-semibold text-start text-versich-dark-blue rounded-xl">
                            Message sent to freelancer.
                        </div>
                    }
                    <div className="bg-white shadow-card rounded-xl">
                        <div className="px-5 md:px-10 py-6 md:py-12 space-y-5">
                            <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold pb-3">Recommended Freelancers</p>
                            <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-y-10 gap-x-4 xl:gap-x-8 text-start">
                                {recommendedFreelancers.map(freelancer => (
                                    <FreelancerCard user={freelancer} contactExpertClick={() => setExpertId(freelancer.id)} key={freelancer.id} />
                                ))}
                            </div>
                        </div>
                    </div>
                    {relatedFreelancers.length > 0 && (<>
                        <div className="text-versich-dark-blue font-semibold text-lg lg:text-xl text-start w-full px-2 py-2 sm:px-4 sm:py-4">
                            Other Related Freelancers
                        </div>
                        <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-y-10 gap-x-4 xl:gap-x-8 text-start">
                            {relatedFreelancers.map(freelancer => (
                                <FreelancerCard className="bg-white border-none shadow-card" user={freelancer} contactExpertClick={() => setExpertId(freelancer.id)} key={freelancer.id} />
                            ))}
                        </div>
                    </>)}
                </div>
                <ContactFreelancerDialog expertId={expertId} setMessageSent={setMessageSent} />
            </div>
        </DashboardLayout>
    )
}
export default RelatedFreelancers