import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import personIcon from "../../../../assets/svgs/user.svg"
import Meta from "../../../../components/Meta"
import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import InputText from "../../../../components/Inputs/InputTextField"
import PhoneNumberField from "../../../../components/Inputs/PhoneNumberField"
import toastify from "../../../../utils/toastify"
import BusinessSetupTracker from "./BusinessSetupTracker"
import { TOASTTYPE_ERROR, businessPaths } from "../../../../assets/constants"
import { businessPersonalContactSetAsync } from "../../../../slices/business/personalContact"

const metadata = {
    title: "Setup - Personal Contact in the Business",
    description: "Fill information about your business to list your business",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/su/business/personal-contact/`
}

const BusinessPersonalContactForm = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [role, setRole] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberValid, setPhoneNumberValid] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill form
    useEffect(() => {
        if (user?.business?.personalContact?.data) {
            setFirstName(user.business.personalContact.data.firstName || "")
            setLastName(user.business.personalContact.data.lastName || "")
            setRole(user.business.personalContact.data.role || "")
            if (!user.business.personalContact.data.phone) {
                return
            }
            const code = user.business.personalContact.data.phone.code.replace("+", "")
            const fullPhoneNumber = code + user.business.personalContact.data.phone.number
            setPhoneCode(code)
            setPhoneNumber(user.business.personalContact.data.phone.number)
            setPhoneNumberValid(validateNumber(fullPhoneNumber))
        }
    }, [user])

    // Validate Phone number
    const validateNumber = (phoneNumber) => {
        if (!phoneNumber.trim()) {
            return false
        }
        const phoneNumberPattern = /^\+?\d{1,}$/
        return phoneNumberPattern.test(phoneNumber)
    }
    const handlePhoneNumberChange = (phone, country) => {
        setPhoneCode(country.dialCode)
        setPhoneNumber(phone.replace(country.dialCode, ""))
        setPhoneNumberValid(validateNumber(phone))
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onSubmit = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid || !phoneNumberValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                firstname: data.firstName,
                lastname: data.lastName,
                role: data.role,
                phonecode: `+${phoneCode}`,
                phonenumber: phoneNumber,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(businessPersonalContactSetAsync(formData))
                .unwrap()
                .then(() => {
                    navigate(businessPaths.about)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <FormProvider {...methods}>
            <div className="bg-[#FBFBFF] min-h-svh">
                <Meta data={metadata} />
                <BrandBar />
                <div className="max_screen_w mb-12 py-8 md:py-10 px-4 md:px-8 lg:px-16">
                    <div className="space-y-3 pb-8 md:pb-10">
                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl text-left font-medium">Business Listings</h1>
                        <p className="text-versich-dark-blue/80 text-xs sm:text-sm text-left font-medium">Complete details below to list your business</p>
                    </div>
                    <div className="flex max-md:flex-col-reverse gap-5">
                        <div className="bg-white w-full mx-auto px-4 smaller:px-8 xl:px-12 py-8 rounded-[11px] shadow-card2">
                            <div className="flex gap-3 pb-4 border-b border-[#EFEEEE99]">
                                <img src={personIcon} alt="" />
                                <div className="font-medium text-left space-y-[7px]">
                                    <h1 className="text-versich-dark-blue text-lg sm:text-xl">Personal Contact in the Business</h1>
                                    <p className="text-versich-dark-blue/80 text-xs sm:text-sm">Enter required details below</p>
                                </div>
                            </div>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <div className="space-y-5 py-7">
                                    <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                                        <InputText name="firstName" inputType="text" placeholder="e.g. Steve" label="First Name" onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} rules={{ required: "First name is required" }} />
                                        <InputText name="lastName" inputType="text" placeholder="e.g. Rogers" label="Last Name" onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} rules={{ required: "Last name is required" }} />
                                    </div>
                                    <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                                        <InputText name="role" inputType="text" placeholder="e.g. Business Analyst" label="Your Role" onChange={(e) => setRole(e.target.value)} defaultValue={role} rules={{ required: "Role is required" }} />
                                        <PhoneNumberField name="phonenumber" onChange={handlePhoneNumberChange} rules={{ required: "Phone number is required" }} value={phoneCode + phoneNumber} />
                                    </div>
                                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                </div>
                                {errorMsg && <div className="text-sm text-red-500 text-left mt-4">{errorMsg}</div>}
                                <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                                    <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(businessPaths.details)}>Back</button>
                                    <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Continue</button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <BusinessSetupTracker completed={[
                                "Business Details", "Personal Contact"
                            ]} />
                        </div>
                    </div>
                </div>
                {loading && <FormLoader />}
            </div>
        </FormProvider>
    )
}
export default BusinessPersonalContactForm
