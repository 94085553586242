

export const uploadBlobImage = (file) => {
    const url = URL.createObjectURL(file)
    const preurls = window.localStorage.getItem(blobImagesKey)
    let blobUrl = url
    if (preurls) {
        blobUrl = `${preurls};${url}`
    }
    window.localStorage.setItem(blobImagesKey, blobUrl)
    return url
}
export const blobImagesKey = "imgpreviewinput_blobimages"

export const clearBlobImages = () => {
    const preurls = window.localStorage.getItem(blobImagesKey)
    if (preurls) {
        preurls.split(";").forEach(url => {
            URL.revokeObjectURL(url)
        })
    }
    window.localStorage.removeItem(blobImagesKey)
}