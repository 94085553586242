import onlinePresenceIcon from "../../assets/icons/business-listing/online-presence.svg"
import SEOPerformanceIcon from "../../assets/icons/business-listing/SEO-performance.svg"
import leadsIcon from "../../assets/icons/business-listing/leads.svg"
import customersIcon from "../../assets/icons/business-listing/customers.svg"
import analyticsIcon from "../../assets/icons/business-listing/analytics.svg"
import ratingsIcon from "../../assets/icons/business-listing/ratings.svg"
import marketingIcon from "../../assets/icons/business-listing/marketing.svg"
import platformIcon from "../../assets/icons/business-listing/platform.svg"

export const businessListingFeatures = [
    {
        title: "Maximized Online Visibility",
        description: "Secure a prominent online presence by listing on VersiMarket, making your business easily discoverable through organic search, featured placements, and platform recommendations. Stand out from competitors and stay visible to customers who matter most",
        icon: onlinePresenceIcon,
        bgColor: "bg-[#FFF8ED]",
    },
    {
        title: "Enhanced SEO Performance",
        description: "Improve your website’s ranking with high-quality backlinks and optimized listings, helping you rise higher in search results on Google and other search engines. Strengthen your brand’s authority with increased mentions and better online relevance",
        icon: SEOPerformanceIcon,
        bgColor: "bg-[#F2FFFD]",
    },
    {
        title: "Boosted Lead Generation",
        description: "Capture valuable leads by connecting with customers actively searching for your offerings. With VersiMarket’s targeted audience and smart listing features, you can turn exposure into meaningful inquiries and loyal customers",
        icon: leadsIcon,
        bgColor: "bg-[#FFF7F8]",
    },
    {
        title: "Expanded Customer Reach and Market Penetration",
        description: "Broaden your business’s influence beyond your local market. Whether targeting regional, national, or niche audiences, VersiMarket helps you engage with new customer segments across untapped markets",
        icon: customersIcon,
        bgColor: "bg-[#F6F9FF]",
    },
    {
        title: "Comprehensive Performance Insights and Analytics",
        description: "Track page views, leads, and other activity to learn more about people visiting your page",
        icon: analyticsIcon,
        bgColor: "bg-[#FFF8ED]",
    },
    {
        title: "Ratings and Reviews",
        description: "Build business reputation by receiving and responding to ratings and reviews from past, present, and future customers",
        icon: ratingsIcon,
        bgColor: "bg-[#F1FDF5]",
    },
    {
        title: "Advanced Marketing via Third party platforms",
        description: "Amplify your marketing efforts by leveraging existing partnerships with our third-party platforms that sing praises of your works and amplifying the efforts of your marketing teams",
        icon: marketingIcon,
        bgColor: "bg-[#F2FFFD]",
    },
    {
        title: "Full Use of VersiMarket platform",
        description: "Get both B2B and B2C clients and unending access to expert freelancers and technical consultants on the VersiMarket platform for your business operations",
        icon: platformIcon,
        bgColor: "bg-[#F9F8FF]",
    },
]