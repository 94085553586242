import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const businessDetailsSetAsync = createAsyncThunk("account/businessDetailsSet",
    async ({ companyname, website, businessemail, token }, { dispatch }) => {
        try {
            const { data } = await api.businessDetailsSet({ companyname, website, businessemail, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })