import { useState, useEffect } from "react"
import { MdRadioButtonChecked } from "react-icons/md"
import { MdOutlineRadioButtonUnchecked } from "react-icons/md"

const RadioSelectionField = ({ label, selected, options, onChange }) => {
    const [selectedOption, setSelectedOption] = useState(options[0].value)

    useEffect(() => {
        selected && setSelectedOption(selected)
    }, [selected])

    const handleOptionChange = (e) => {
        const value = e.target.value
        setSelectedOption(value)
        onChange(value)
    }
    return (
        <div className="space-y-2 text-start py-5">
            {label && <div>{label}</div>}
            <div className="grid grid-rows-2 gap-y-5">
                {options.map((option) => (
                    <label
                        className={`bg-${option.bgColor} bg-opacity-[0.08] flex max-sm404:flex-col-reverse sm404:items-center px-4 sm:px-6 py-6 border-[3px] ${selectedOption === option.value
                            ? "border-versich-blue/40"
                            : "border-versich-blue/[0.04] hover:border-versich-blue/20"
                            } rounded-lg cursor-pointer`}
                        key={option.value}
                    >
                        <input name={label} className="appearance-none" type="radio" value={option.value} checked={selectedOption === option.value} onChange={handleOptionChange} />
                        <div className="flex-1 inline-flex max-smaller:flex-wrap gap-[18px] sm404:mr-3">
                            <img className="w-8 h-8 sm:w-11 sm:h-11" src={option.icon} alt={option.value} />
                            <div className="text-sm space-y-1">
                                <h3 className="text-versich-dark-blue font-bold">{option.title}</h3>
                                <p className="text-versich-label">{option.desc}</p>
                            </div>
                        </div>
                        {selectedOption === option.value
                            ? <MdRadioButtonChecked className="text-versich-light-blue text-xl max-sm404:ml-auto" />
                            : <MdOutlineRadioButtonUnchecked className="text-versich-light-blue text-xl max-sm404:ml-auto" />
                        }
                    </label>
                ))}
            </div>
        </div>
    )
}
export default RadioSelectionField
