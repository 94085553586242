import { useSelector, useDispatch } from "react-redux"
import { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { FaCrown } from "react-icons/fa"

import { getInitials } from "../../utils"
import { profileCompletionScoreAsync } from "../../slices/profileCompletion"
import {
    CLIENT, BUSINESS,
    emailVerificationPath, setupPath, editProfilePath,
} from "../../assets/constants"
import CircularProgressBar from "../CircularProgressBar"
import ConfirmButton from "../Buttons/ConfirmButton"

const ProfileCard = ({ className }) => {
    const { user } = useSelector((state) => state.auth)
    const profileCompletionScore = useSelector((state) => state.profileCompletion.score)
    const fullName = user.accountType === BUSINESS ? user.business.details.data.companyName : user.about.firstName + " " + user.about.lastName
    const initials = getInitials(fullName)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(profileCompletionScoreAsync(user.accountType))
    }, [dispatch])
    return (
        <div className={className}>
            <div className="bg-white shadow-card px-4 py-4 sm:px-6 sm:py-6 rounded-xl">
                <div className="flex gap-x-3 items-center ">
                    {/* Avatar */}
                    <div className="rounded-full text-white text-lg lg:text-xl font-semibold bg-versich-light-blue w-[50px] h-[50px] lg:h-16 lg:w-16 flex justify-center items-center">
                        {initials}
                    </div>
                    <div className="flex-1 text-start text-versich-dark-blue font-semibold text-lg lg:text-xl">{fullName}</div>
                    {user.plan && user.plan.name === "premium" && user.plan.status === "active" &&
                        <FaCrown className="text-yellow-500 w-5 h-5 mb-auto" title="Premium plan" />
                    }
                </div>
                <div className="flex flex-col items-center gap-y-3 py-4 px-2.5 bg-[#D9EBFC] my-4 rounded-lg">
                    <CircularProgressBar progress={profileCompletionScore} />
                    {profileCompletionScore === 100
                        ? <p>Your profile is completed</p>
                        : user.accountType === CLIENT
                            ? <p>Complete your profile <Link to={user.verified ? editProfilePath : emailVerificationPath} className="text-versich-blue underline">here</Link></p>
                            : <p>Complete your profile <Link to={
                                !user.primaryService || !user.leadsLocation || !user.about || !user.hasServiceDetails
                                    ? setupPath
                                    : user.verified
                                        ? editProfilePath
                                        : emailVerificationPath
                            } className="text-versich-blue underline">here</Link></p>
                    }
                </div>
                {user.accountType !== BUSINESS && (profileCompletionScore === 100 || user.about) &&
                    <div className="max-w-32 lg:max-w-40">
                        <ConfirmButton type="button" handleClick={() => navigate(editProfilePath)}>Edit profile</ConfirmButton>
                    </div>
                }
            </div>
        </div>
    )
}
export default ProfileCard