import { useSelector } from "react-redux"

import EditOtherServices from "../../../dashboard/edit/OtherServices/FreelancerOtherServices"
import {
    BUSINESS, FREELANCER,
    dashboardPath, freelancerPaths
} from "../../../../assets/constants"

const metadata = {
    title: "Setup - Other Services",
    description: "Kindly proceed to add additional services for more leads",
}

const OtherServicesForm = () => {
    const { user } = useSelector((state) => state.auth)
    return (<>
        {user.accountType === FREELANCER &&
            <EditOtherServices
                skipToPath={freelancerPaths.servicedetails}
                metadata={{
                    ...metadata,
                    url: `${process.env.REACT_APP_CLIENT_URL}/auth/su/freelancer/other-services/`
                }}
            />
        }
        {user.accountType === BUSINESS &&
            <EditOtherServices skipToPath={dashboardPath}
                metadata={{
                    ...metadata,
                    url: `${process.env.REACT_APP_CLIENT_URL}/auth/su/business/other-services/`
                }}
            />
        }
    </>)
}
export default OtherServicesForm