import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"

import Meta from "../../../../components/Meta"
import BrandBar from "../../../../components/Navbar/BrandBar"
import ServicesList from "../../../../assets/ServicesList"
import {
    COOKIE_NEW_REQUEST_WDAD, COOKIE_NEW_REQUEST_MA,
    COOKIE_NEW_REQUEST_DAA_BU, COOKIE_NEW_REQUEST_DAA_PE,
    COOKIE_NEW_REQUEST_SD, COOKIE_NEW_REQUEST_MLAI,
    COOKIE_NEW_REQUEST_FT_FPAR, COOKIE_NEW_REQUEST_FT_FRAA, COOKIE_NEW_REQUEST_FT_EN,
    COOKIE_NEW_REQUEST_FT_SA, COOKIE_NEW_REQUEST_FT_DT, COOKIE_NEW_REQUEST_FT,
    dashboardPath, newRequestPath
} from "../../../../assets/constants"

const metadata = {
    title: "New Request - Service Selection",
    description: "Select the service you want to create new request",
    url: `${process.env.REACT_APP_CLIENT_URL}/request/new/`
}

const NewRequest = () => {
    const [selectedService, setSelectedService] = useState("")
    const [error, setError] = useState(false)

    // Clearing old request in cookies
    useEffect(() => {
        Cookies.remove(COOKIE_NEW_REQUEST_WDAD)
        Cookies.remove(COOKIE_NEW_REQUEST_MA)
        Cookies.remove(COOKIE_NEW_REQUEST_DAA_BU)
        Cookies.remove(COOKIE_NEW_REQUEST_DAA_PE)
        Cookies.remove(COOKIE_NEW_REQUEST_SD)
        Cookies.remove(COOKIE_NEW_REQUEST_FT_FPAR)
        Cookies.remove(COOKIE_NEW_REQUEST_FT_FRAA)
        Cookies.remove(COOKIE_NEW_REQUEST_FT_EN)
        Cookies.remove(COOKIE_NEW_REQUEST_FT_SA)
        Cookies.remove(COOKIE_NEW_REQUEST_FT_DT)
        Cookies.remove(COOKIE_NEW_REQUEST_FT)
        Cookies.remove(COOKIE_NEW_REQUEST_MLAI)
    }, [])

    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        if (selectedService) {
            navigate(`${newRequestPath}${selectedService}/`)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (<>
        <Meta data={metadata} />
        <BrandBar />
        <div className="mb-12 py-10 md:py-14 px-3 smaller:px-8 md:px-16 lg:px-28">
            <form className="flex flex-col" onSubmit={handleSubmit}>
                <div className="max_screen_w bg-white flex flex-col gap-3 flex-1 px-4 py-6 shadow-form rounded-md">
                    <div className="relative space-y-2">
                        <label className="text-versich-dark-blue font-semibold py-2 flex justify-center text-lg">What service do you need?</label>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-4 gap-x-8 py-8 px-4">
                            {ServicesList.map((service) => (
                                <div
                                    key={service.id}
                                    className={`flex flex-col justify-center items-center gap-y-2 h-44 py-4 px-3 border-[3px] ${selectedService === service.uid
                                        ? "border-versich-blue/80 bg-versich-blue/15"
                                        : "border-gray-100 hover:border-versich-blue/20"
                                        } cursor-pointer shadow-sticker rounded-xl`
                                    }
                                    onClick={() => setSelectedService(service.uid)}
                                >
                                    <input className="appearance-none" name="selectedService" type="radio" value={service.uid} />
                                    <img src={service.icon} alt={service.name} className="w-16 h-16 drop-shadow contrast-150" />
                                    <span className="text-versich-dark-blue text-center text-sm">{service.name}</span>
                                </div>
                            ))}
                        </div>
                        {error && <p className="text-red-500 text-start text-sm mb-3">Please select a service.</p>}
                        <hr className="!mb-6 border-2 border-gray-100 rounded-xl" />
                        <div className="w-full flex justify-between">
                            <button className="bg-slate-100 hover:bg-slate-200 text-gray-900 border border-versich-border/20 rounded-md py-2 lg:py-2.5 px-6 lg:px-8" type="button" onClick={() => navigate(dashboardPath)}>Back</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover text-white rounded-md py-2 lg:py-2.5 px-6 lg:px-8" type="submit">Next</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </>)
}
export default NewRequest
