import hero from "../../../assets/images/services-hero.jpg"

const Hero = () => {
    return (
        <div style={{ backgroundImage: `url(${hero})` }} className="background-image bg-cover bg-center bg-no-repeat mt-[-100px]">
            <div className="bg-black bg-opacity-80 text-white text-start w-full h-full px-8 md:px-20 lg:px-40 xl:px-60 largeLaptop:px-80 pt-[200px] pb-[124px]">
                <div className="text-4xl lg:text-5xl font-[700] max_screen_w">
                    <h1 className="leading-tight uppercase">
                        <div className="text-versich-label">Providing</div>
                        <div className="text-versich-blue">Experts To Get</div>
                        <div className="text-versich-border">Your Job Done!</div>
                    </h1>
                </div>
            </div>
        </div>
    )
}
export default Hero
