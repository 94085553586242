import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import ErrorPage from "../../../components/ErrorPage/ErrorPage"
import Meta from "../../../components/Meta"
import AuthImgBanner from "../../../components/AuthImgBanner"
import Logo from "../../../components/Logo"
import EmailPendingIcon from "../../../assets/icons/hourglass.png"
import EmailExpiredIcon from "../../../assets/icons/error-message.png"
import EmailVerifiedIcon from "../../../assets/icons/verified.png"
import ResendVerificationLink from "./email/ResendVerificationLink"
import { SS_VERIFICATION_STATUS, verificationStates, dashboardPath } from "../../../assets/constants"
import { verifyEmail } from "../../../actions/verification"
import { getUser } from "../../../actions/auth"

const EmailTokenLinkVerification = () => {
    const { token } = useParams()

    const { verifying, verified, expired } = verificationStates
    const [status, setStatus] = useState(verifying.code)

    const dispatch = useDispatch()
    useEffect(() => {
        let timeout
        dispatch(verifyEmail(token))
            .then(() => {
                setStatus(verified.code)
                window.sessionStorage.setItem(SS_VERIFICATION_STATUS.key, verified.code)
                timeout = setTimeout(() => {
                    dispatch(getUser())
                }, 3500)
            })
            .catch(error => {
                if (error?.response?.status === 410 && error?.response?.data?.shortInfo === "token expired") {
                    setStatus(expired.code)
                }
                else setStatus(null)
            })
        return () => clearTimeout(timeout)
        // eslint-disable-next-line
    }, [])
    return (
        token && status ?
            <div className="grid grid-cols-1 lg:grid-cols-2 min-h-svh">
                {status === verifying.code
                    ? <VerificationStatus
                        title="Verifying Email"
                        icon={
                            <div className="animate-bounce mt-5">
                                <img className="animate-spin" src={EmailPendingIcon} alt="pending" width={64} />
                            </div>
                        }
                        description={
                            <p className="text-center mb-5">Hang tight, we'll be done in just a few seconds.</p>
                        }
                        metaDescription="Verifying user email by unique link"
                    />
                    : status === verified.code
                        ? <VerificationStatus
                            title="Email Verification Successful"
                            icon={<img src={EmailVerifiedIcon} alt="verified" width={92} />}
                            description={
                                <div>
                                    <p className="text-center mb-5">To continue, head back to where you started the verification process on the VersiMarket website.</p>
                                    <p className="text-center">Alternatively, stay on this page to be redirected to your dashboard.</p>
                                </div>
                            }
                            metaDescription="Verifying user email by unique link"
                        />
                        : <VerificationStatus
                            title="Verification Link Expired"
                            icon={<img src={EmailExpiredIcon} alt="expired" width={72} />}
                            description={
                                <div>
                                    <p className="text-center mb-5">This link has expired. Please use the link from the most recent email.</p>
                                    <ResendVerificationLink />
                                </div>
                            }
                            metaDescription="Verifying user email by unique link"
                        />
                }
            </div>
            : <ErrorPage status={404} />
    )
}
const VerificationStatus = ({ title, icon, description, metaTitle = title, metaDescription }) => (<>
    <Meta data={{ title: metaTitle, description: metaDescription }} />
    <AuthImgBanner />
    <div className="w-full m-auto space-y-5 lg:space-y-7 p-5 smaller:p-10 max-w-[580px] rounded-lg">
        <Logo href={dashboardPath} />
        <div className="text-6xl pb-5 flex justify-center text-versich-blue">
            {icon}
        </div>
        <div className="text-center space-y-2">
            <h2 className="text-xl md:text-2xl leading-normal text-versich-darktext-color font-semibold">{title}</h2>
            <div className="text-versich-label">{description}</div>
        </div>
    </div>
</>)
export default EmailTokenLinkVerification