import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import versiGemIcon from "../../../assets/images/versigem.PNG"
import ConfirmButton from "../../../components/Buttons/ConfirmButton"
import { buyVersiGemsPath } from "../../../assets/constants"

const VersiGemsCard = () => {
    const { user } = useSelector(state => state.auth)
    const navigate = useNavigate()
    return (
        <div className="bg-white shadow-card px-4 py-4 sm:px-6 sm:py-6 rounded-xl space-y-4 text-start relative">
            <div className="text-start text-lg lg:text-xl font-semibold text-versich-dark-blue">VersiGems</div>
            <div>
                <img className="absolute w-12 h-12 sm:w-16 sm:h-16 top-4 right-4" src={versiGemIcon} alt="" />
                <p className="text-base text-versich-label mb-1">My Balance</p>
                <div className="text-lg flex items-center gap-x-1.5 mt-1.5 mb-3">
                    <p className="text-versich-dark-blue text-2xl lg:text-3xl font-semibold">{user.versigems ? user.versigems : <span className="text-red-500">0</span>}</p>
                </div>
            </div>
            <div className="max-w-40">
                <ConfirmButton type="button" handleClick={() => navigate(buyVersiGemsPath)}>Buy VersiGems</ConfirmButton>
            </div>
        </div>
    )
}
export default VersiGemsCard