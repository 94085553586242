import { useSelector } from "react-redux"

import { FREELANCER, BUSINESS, dashboardPath } from "../../../../assets/constants"
import EditFreelancerOtherServices from "./FreelancerOtherServices"

const EditOtherServices = () => {
    const { user } = useSelector((state) => state.auth)
    return (<>
        {user.accountType === FREELANCER && <EditFreelancerOtherServices />}
        {user.accountType === BUSINESS && <EditFreelancerOtherServices skipToPath={dashboardPath} />}
    </>)
}
export default EditOtherServices
