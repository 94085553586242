import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { FaCrown, FaCoins } from "react-icons/fa"
import { LuCalendarClock } from "react-icons/lu"
import { MdOutlineAccessTime } from "react-icons/md"
import { GiBackwardTime } from "react-icons/gi"
import { TbCalendarTime } from "react-icons/tb"
import { IoIosTimer } from "react-icons/io"
import { RiInfinityLine } from "react-icons/ri"

import { businessPricingPath } from "../../../assets/constants"

const PlanDetailsCard = () => {
    const { user } = useSelector(state => state.auth)
    const navigate = useNavigate()
    return (
        user.plan && user.plan.name === "premium" && user.plan.status === "active" ?
            <div className="bg-white shadow-card px-4 py-4 sm:px-6 sm:py-6 rounded-xl space-y-4 text-start">
                <div className="text-start text-lg lg:text-xl font-semibold text-versich-dark-blue">Plan Details</div>
                <div>
                    <p className="text-sm text-versich-label mb-1">Type</p>
                    <div className="flex items-center gap-x-1.5 mt-1.5 mb-3">
                        <FaCrown className="text-yellow-500" />
                        <p className="text-sm">Premium</p>
                    </div>
                </div>
                <div>
                    <p className="text-sm text-versich-label mb-1">Recurring Interval</p>
                    <div className="flex items-center gap-x-1.5 mt-1.5 mb-3">
                        <LuCalendarClock className="text-versich-blue-hover" />
                        <p className="text-sm">Month</p>
                    </div>
                </div>
                <div>
                    <p className="text-sm text-versich-label mb-1">Started at</p>
                    <div className="flex items-center gap-x-1.5 mt-1.5 mb-3">
                        <MdOutlineAccessTime className="text-versich-blue-hover" />
                        <p className="text-sm">{new Date(user.plan.startedAt).toLocaleString()}</p>
                    </div>
                </div>
                {user.plan.canceledAt
                    ? <div>
                        <p className="text-sm text-versich-label mb-1">Cancellation Date</p>
                        <div className="flex items-center gap-x-1.5 mt-1.5 mb-3">
                            <GiBackwardTime className="text-versich-blue-hover" />
                            <p className="text-sm">{new Date(user.plan.canceledAt).toLocaleString()}</p>
                        </div>
                        <p className="text-sm text-versich-label mb-1">Subscription End Date</p>
                        <div className="flex items-center gap-x-1.5 mt-1.5 mb-3">
                            <TbCalendarTime className="text-versich-blue-hover" />
                            <p className="text-sm">{new Date(user.plan.endAt).toLocaleString()}</p>
                        </div>
                    </div>
                    : <div>
                        <p className="text-sm text-versich-label mb-1">Next Billing Date</p>
                        <div className="flex items-center gap-x-1.5 mt-1.5 mb-3">
                            <IoIosTimer className="text-versich-blue-hover" />
                            <p className="text-sm">{new Date(user.plan.nextBillingDate).toLocaleString()}</p>
                        </div>
                    </div>
                }
            </div>
            : <div className="bg-white shadow-card px-4 py-4 sm:px-6 sm:py-6 rounded-xl space-y-4 text-start">
                <div className="text-start text-lg lg:text-xl font-semibold text-versich-dark-blue">Plan Details</div>
                <div className="grid sm401:grid-cols-2">
                    <div>
                        <p className="text-sm text-versich-label mb-1">Type</p>
                        <div className="flex items-center gap-x-1.5 mt-1.5 mb-3">
                            <FaCoins className="text-sky-500" />
                            <p className="text-sm">Free</p>
                        </div>
                    </div>
                    <div>
                        <p className="text-sm text-versich-label mb-1">Valid till</p>
                        <div className="flex items-center gap-x-1.5 mt-1.5">
                            <RiInfinityLine className="text-versich-blue-hover" />
                            <p className="text-sm">Forever</p>
                        </div>
                    </div>
                </div>
                <button className="text-yellow-500 hover:bg-yellow-100 text-sm smaller:text-base inline-flex items-center gap-1 py-1 lg:py-1.5 px-3 border-2 border-yellow-300 rounded-md" onClick={() => navigate(businessPricingPath)}><FaCrown /> Upgrade</button>
            </div>
    )
}
export default PlanDetailsCard