import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../api"
import apiErrorParser from "../utils/apiErrorParser"

export const searchServiceAsync = createAsyncThunk("service/searchService", async ({ query, token }) => {
    try {
        const { data } = await api.searchService({ query, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data?.payload
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})