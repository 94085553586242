import { SHOW } from "../../../assets/constants"

export const toggleSideNavbar = (reset = false) => {
    const sideNavbar = document.getElementById("dh-sidenavbar")
    if (reset === true) {
        sideNavbar?.classList.remove(SHOW)
        disableSideNavbar_and_reEnableBody()
    }
    else {
        sideNavbar?.classList.add(SHOW)
        document.body.style.overflow = "hidden"
        window.addEventListener("mousedown", listenOnClick)
    }
}
const listenOnClick = (event) => {
    if (event.target.id === "dh-sidenavbar-float") {
        document.getElementById("dh-sidenavbar").classList.remove(SHOW)
        disableSideNavbar_and_reEnableBody()
    }
}
const disableSideNavbar_and_reEnableBody = () => {
    document.body.removeAttribute("style")
    window.removeEventListener("mousedown", listenOnClick)
}

export const showProfileOptions = (e) => {
    const options = document.getElementById(profileOptionsId)
    if (!options) {
        return
    }
    else if (options.classList.contains(SHOW)) {
        e.target.id !== profileOptionsId
            && e.target.dataset.parentId !== profileOptionsId
            && listenOnProfileClick()
    }
    else {
        options.classList.add(SHOW)
        window.addEventListener("mousedown", listenOnProfileClick)
    }
}
export const profileWrapperId = "miniprofile-wrapper"
export const profileOptionsId = "miniprofile-options"
const trackElementIds = [profileOptionsId, profileWrapperId]
const listenOnProfileClick = (event = null) => {
    const options = document.getElementById(profileOptionsId)
    if ((event
        && (trackElementIds.includes(event.target.id) === false
            && trackElementIds.includes(event.target.dataset.parentId) === false))
        || event === null) {
        window.removeEventListener("mousedown", listenOnProfileClick)
        options?.classList.remove(SHOW)
    }
}