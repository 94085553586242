import { Link } from "react-router-dom"

import Meta from "../../components/Meta"
import Hero from "./components/Hero"
import { businessSignupPath } from "../../assets/constants"
import { businessListingFeatures } from "./constants"
import "./business-listing.css"

const metadata = {
    title: "VersiMarket Services",
    description: "Web-based Services Marketplace for Web Design, Development, Mobile Apps and Data Analytics",
    url: `${process.env.REACT_APP_CLIENT_URL}/business-listing/`
}

const BusinessListing = () => {
    return (
        <div>
            <Meta data={metadata} />
            <Hero />
            <div className="mt-8 sm:mt-16 px-8 md:px-16 lg:px-28">
                <div className="max_screen_w">
                    <div className="text-center px-10">
                        <p className="text-base lg:text-lg text-versich-light-blue mb-3">Our Features</p>
                        <p className="text-3xl lg:text-4xl font-bold text-versich-darktext-color">Why choose VersiMarket?</p>
                    </div>
                    <div className="feature_grid pt-8 sm:pt-12 pb-8">
                        {businessListingFeatures.map(feature => (
                            <div className={`${feature.bgColor} grid_item`} key={feature.title}>
                                <div className="inline-flex items-center justify-center">
                                    <img src={feature.icon} alt="" />
                                </div>
                                <div className="text-versich-darktext-color font-bold mt-5">{feature.title}</div>
                                <p className="text-[#64748B] text-sm mt-2.5">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center mb-12 sm:mb-14">
                        <Link className="bg-versich-blue hover:bg-versich-blue-hover text-white text-sm font-bold py-2.5 lg:py-3 px-3.5 lg:px-[22px] border border-versich-blue transition-colors rounded-lg" to={businessSignupPath}>List Your Business Now</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BusinessListing
