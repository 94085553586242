import DashboardLayout from "../../../components/Dashboard/DashboardLayout"
import ProfileCard from "../../../components/Dashboard/ProfileCard"
import LeadSettingsCard from "../../../components/Dashboard/LeadSettingsCard"
import AboutCard from "../../../components/Dashboard/AboutCard"
import MessagesCard from "../../../components/Dashboard/MessagesCard"
// import LeadsCard from "../../../components/Dashboard/LeadsCard"
import PlanDetailsCard from "./PlanDetailsCard"
import HelpCard from "../../../components/Dashboard/HelpCard"
import { BusinessMenu } from "../../../components/Dashboard/constants"

const BusinessDashboard = () => {
    return (
        <DashboardLayout navlinks={BusinessMenu} activePage="Dashboard" showVerifyEmailBanner={true}>
            <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-3 min-h-[480px]">
                <div className="xl:col-span-2 grid xl:grid-cols-2 flex-col xl:flex-row gap-x-4 gap-y-3">
                    <ProfileCard className="flex-1" />
                    <LeadSettingsCard className="flex-1" />
                    <AboutCard />
                </div>
                <div className="space-y-3 flex-1">
                    <MessagesCard />
                    {/* <LeadsCard /> */}
                    <PlanDetailsCard />
                    <HelpCard />
                </div>
            </div>
        </DashboardLayout>
    )
}
export default BusinessDashboard