import { useState } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import Meta from "../../components/Meta"
import AuthImgBanner from "../../components/AuthImgBanner"
import Logo from "../../components/Logo"
import CSRFTokenField from "../../components/Inputs/CSRFTokenField"
import ConfirmButton from "../../components/Buttons/ConfirmButton"
import { loginPath, TOASTTIMER } from "../../assets/constants"
import { forgotPasswordSendLink } from "../../actions/password"
import toastify from "../../utils/toastify"

const metadata = {
    title: "Recover your account - VersiMarket",
    description: "Forgot password? recover your account",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/forgot-password/`
}

const ForgotPassword = () => {
    const [csrfToken, setCsrfToken] = useState("")
    const [email, setEmail] = useState("")
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (email) {
            setLoading(true)
            dispatch(forgotPasswordSendLink(email, csrfToken))
                .then(data => {
                    toastify(data.message, data.status, null)
                    e.target.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    setErrorMsg(data.message)
                    setTimeout(() => {
                        setErrorMsg("")
                        submitBtn.disabled = false
                    }, TOASTTIMER)
                })
                .finally(() => {
                    setEmail("")
                    setLoading(false)
                })
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
                submitBtn.disabled = false
            }, TOASTTIMER)
        }
    }
    return (
        <div>
            <Meta data={metadata} />
            <div className="grid grid-cols-1 lg:grid-cols-2 min-h-svh">
                <AuthImgBanner />
                <div className="w-full m-auto space-y-5 lg:space-y-7 p-5 smaller:p-10 max-w-[580px] rounded-lg">
                    <Logo />
                    <div className="text-center space-y-2">
                        <h2 className="text-xl md:text-2xl leading-normal text-versich-darktext-color font-semibold">Forgot Password?</h2>
                        <p className="text-versich-label">Enter your email address to recover your account</p>
                    </div>
                    <form className="space-y-6 lg:space-y-8 pb-6 lg:pb-8" noValidate onSubmit={handleSubmit}>
                        <div className="space-y-4 md:space-y-5 text-start">
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            <div className="space-y-2">
                                <input
                                    className={"text-sm w-full h-10 py-2.5 px-3 border-[1px] border-gray-300 focus-visible:border-versich-blue shadow-input focus:shadow-inputFocus outline-none rounded-lg " + (error ? "border-red-500" : "border-versich-border")}
                                    name="email"
                                    type="email"
                                    value={email}
                                    placeholder="Enter your email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {error && <p className="text-red-500 text-start text-sm mb-3">Email is required to recover your account</p>}
                                {errorMsg && <div className="text-sm text-red-500 text-left">{errorMsg}</div>}
                            </div>
                        </div>
                        <div className="space-y-3 md:space-y-4 text-start">
                            <ConfirmButton type="submit" loading={loading}>Submit</ConfirmButton>
                            <p className="text-sm text-center">
                                Remember your password?{" "}
                                <Link className="text-versich-blue hover:text-versich-blue-hover hover:underline font-semibold" to={loginPath}>Login</Link>
                            </p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
export default ForgotPassword