import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

const CheckboxBadgeOptions = ({ actionAsync, values, setValues, error, setError, otherValue, onOtherInputChange, otherInputError }) => {
    const [options, setOptions] = useState([])
    const [selectedOptionIds, setSelectedOptionIds] = useState([])
    const [showOtherInput, setShowOtherInput] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(actionAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setOptions(data.payload)
            })
    }, [])

    const handleCheckboxClick = (option) => {
        if (selectedOptionIds.includes(option.uid)) {
            setValues(
                values.filter(v => v.uid !== option.uid)
            )
        }
        else setValues([...values, option])
        setError(false)
    }

    // Track selected options 'ids'
    useEffect(() => {
        setSelectedOptionIds(values.map(v => v.uid))
    }, [values])

    // Toggle other input visibilty
    useEffect(() => {
        const hasOther = values.filter(v => v.uid === "other").length > 0
        setShowOtherInput(hasOther)
    }, [values])
    return (<>
        <div className="w-full">
            <div className="flex gap-1.5 flex-wrap">
                {options.map((option) => (
                    <label
                        key={option.uid}
                        className={"bg-white hover:border-versich-blue text-oxford-blue hover:text-versich-dark-blue font-semibold text-sm md:text-base inline-flex items-center py-2 px-3 border border-[#D0D5DD] rounded-lg cursor-pointer transition-all group " + (
                            selectedOptionIds.includes(option.uid) ? "border-versich-blue" : "border-[#D0D5DD]"
                        )}
                    >
                        <input className="appearance-none" name="need" type="checkbox" value={option.uid} onChange={() => handleCheckboxClick(option)} />
                        <span className={"group-hover:text-versich-blue text-sm font-medium transition-all " + (
                            selectedOptionIds.includes(option.uid) ? "text-versich-blue" : "text-[#9E9E9E]"
                        )}>{option.title}</span>
                    </label>
                ))}
            </div>
            {showOtherInput &&
                <div className="flex flex-col gap-2 mt-2.5 mb-2">
                    <input
                        className={"text-sm w-full h-10 py-2.5 px-3 border-[1px] focus-visible:border-versich-blue shadow-input focus:shadow-inputFocus outline-none rounded-lg " + (otherInputError ? "border-red-500" : "border-gray-300")}
                        name="otherOption"
                        type="text"
                        value={otherValue}
                        placeholder="Service you offer"
                        onChange={onOtherInputChange}
                        autoFocus
                    />
                    {otherInputError &&
                        <p className="text-sm text-red-500 text-left">Field is required</p>
                    }
                </div>
            }
        </div>
        {error && <p className="text-red-500 text-start text-sm my-3">Please select at least one service</p>}
    </>)
}
export default CheckboxBadgeOptions