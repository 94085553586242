import { useEffect, useState } from "react"

import checkListIcon from "../../../../../assets/icons/request/checklist.png"
import NewRequestLayout from "../NewRequestLayout"

const ClientDetails = ({ totalForms, formPosition, updateFormPosition, clientDetails, setClientDetails, goBack }) => {
    const [email, setEmail] = useState(clientDetails ? clientDetails.email : "")
    const [emailError, setEmailError] = useState(false)
    const [name, setName] = useState(clientDetails ? clientDetails.name : "")
    const [nameError, setNameError] = useState(false)

    useEffect(() => {
        updateFormPosition()
    }, [])

    const handleContinue = () => {
        if (email && name) {
            setClientDetails({ email, name })
        }
        else {
            !email && setEmailError(true)
            !name && setNameError(true)
            setTimeout(() => {
                setEmailError(false)
                setNameError(false)
            }, 2000)
        }
    }
    return (
        <NewRequestLayout
            icon={checkListIcon}
            description="Details about how you like to receive quotes"
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            handleContinueClick={handleContinue}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-5">Which email address would you like to have quotes sent to?</p>
                <div className="flex flex-col gap-y-2 mt-1 mb-10">
                    <input
                        className={"max-md:text-sm flex-1 h-10 py-2 px-3 border rounded-lg outline-none " + (emailError ? "border-red-500" : "border-versich-border")}
                        name="email" type="email" placeholder="Enter address" onChange={(e) => setEmail(e.target.value)} value={email}
                    />
                    {emailError && <p className="text-red-500 text-start text-sm">Email can't be empty</p>}
                </div>
                <span className="text-versich-dark-blue max-md:text-sm w-full">Your name</span>
                <div className="flex flex-col gap-y-2 mt-1 mb-10">
                    <input className={"max-md:text-sm flex-1 h-10 py-2 px-3 border rounded-lg outline-none " + (nameError ? "border-red-500" : "border-versich-border")}
                        name="name" type="text" placeholder="Firstname or Lastname" onChange={(e) => setName(e.target.value)} value={name}
                    />
                    {nameError && <p className="text-red-500 text-start text-sm">Name can't be empty</p>}
                </div>
            </div>
        </NewRequestLayout>
    )
}
export default ClientDetails
