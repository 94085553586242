import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { IoIosArrowDown } from "react-icons/io"

import { countryStatesAsync } from "../../slices/country"

const StateSelectionField = ({ name, label, country, selectedState, onChange, regionError }) => {
    const [states, setStates] = useState([])

    const dispatch = useDispatch()
    useEffect(() => {
        country && dispatch(countryStatesAsync(country))
            .then(data => {
                setStates(data.payload ? data.payload : [])
            })
            .catch(() => { })
    }, [country])
    return (
        states.length > 0 &&
        <div className="space-y-2">
            {label &&
                <div className="flex items-center justify-between">
                    <label htmlFor={name} className="text-charcoal text-sm">{label}</label>
                </div>
            }
            <div className="relative">
                <select className="bg-white text-sm font-medium w-full h-10 rounded-lg border-[1px] border-[#D0D5DD] px-3 pr-8 appearance-none focus-visible:border-versich-blue shadow-input focus:shadow-inputFocus outline-none cursor-pointer"
                    name={name}
                    value={selectedState}
                    onChange={onChange}
                >
                    <option value="">Select State</option>
                    {states.map(state => (
                        <option value={state.stateCode} key={state.stateCode}>{state.name}</option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <IoIosArrowDown className="w-5 h-5 text-gray-400" />
                </div>
            </div>
            {regionError &&
                <p className="text-red-600 text-sm text-start">Please select a state.</p>
            }
        </div>
    )
}
export default StateSelectionField