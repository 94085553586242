import * as api from "../../../api"
import apiErrorParser from "../../../utils/apiErrorParser"

export const getServices = (params) => async () => {
    try {
        const { data } = await api.FPandARevolutionServicesFT(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
}