import { PropagateLoader } from "react-spinners"

const FormLoader = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black/15 z-20 ">
            <div className="w-full h-screen absolute flex justify-center items-center sm:pb-[15%]">
                <PropagateLoader loading={true} color="#1F71BE" height={3} width="40%" />
            </div>
        </div>
    )
}
export default FormLoader