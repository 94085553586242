import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { FaSquareCheck } from "react-icons/fa6"
import { RiCheckboxBlankLine } from "react-icons/ri"
import { IoIosClose } from "react-icons/io"

const CheckboxSearchOptions = ({ actionAsync, updateFormPosition, noSearchBar, defaultValue, values, setValues, error }) => {
    const [options, setOptions] = useState([])
    const [query, setQuery] = useState("")
    const [searchResults, setSearchResults] = useState([])

    const dispatch = useDispatch()
    useEffect(() => {
        updateFormPosition()
        dispatch(actionAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setOptions(data.payload)
            })
    }, [])

    // Filter search results
    useEffect(() => {
        const results = options.filter((option) => option.title.toLowerCase().includes(query.toLowerCase()))
        setSearchResults(results)
    }, [query, options])

    const handleCheckboxClick = (option) => {
        if (values.includes(option)) {
            setValues(
                values.filter(v => v !== option)
            )
        }
        else setValues([...values, option])
    }

    // Set default value selected
    useEffect(() => {
        defaultValue && options.length > 0 && options.map(option => {
            if (option.uid === defaultValue) {
                handleCheckboxClick(option)
            }
        })
    }, [defaultValue, options])
    return (<>
        {!noSearchBar &&
            <div className="flex mt-1.5 mb-5">
                <input
                    className="max-md:text-sm flex-1 h-10 py-2 px-3 border border-gray-300 rounded-lg outline-none"
                    type="text"
                    placeholder="Search..."
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
            </div>
        }
        <div className="w-full max-h-72 min-h-48 mb-5 overflow-y-auto">
            {searchResults.map((option) => (
                <label
                    key={option.uid}
                    className="flex items-center px-1 py-2 md:p-2 cursor-pointer group"
                >
                    <input className="appearance-none" name="need" type="checkbox" value={option.uid} onChange={() => handleCheckboxClick(option)} />
                    {values.includes(option)
                        ? <FaSquareCheck className="fill-versich-blue-hover" />
                        : <RiCheckboxBlankLine className="text-versich-dark-blue group-hover:fill-versich-blue-hover" />
                    }
                    <span className="text-versich-dark-blue max-md:text-sm w-full ps-2 group-hover:text-versich-blue-hover transition-all">{option.title}</span>
                </label>
            ))}
        </div>
        {values && values.length > 0 &&
            <div className="flex gap-x-2 flex-wrap gap-y-2">
                {values.map((option) => (
                    <div
                        key={option.uid}
                        className="bg-oxford-blue/5 hover:bg-versich-blue/10 text-oxford-blue hover:text-versich-dark-blue font-semibold text-sm md:text-base inline-flex items-center py-1 px-2 border border-versich-border/40 rounded-3xl cursor-pointer"
                        onClick={() => setValues(values.filter(v => v !== option))}
                    >
                        <p className="text-xs">{option.title}</p>
                        <IoIosClose className="text-2xl" />
                    </div>
                ))}
            </div>
        }
        {error && <p className="text-red-500 text-start text-sm mb-3">Please select at least one option</p>}
    </>)
}
export default CheckboxSearchOptions