import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { formatDistanceToNow } from "date-fns"
import { MdOutlineUpdate } from "react-icons/md"
import { FaCheckCircle } from "react-icons/fa"
import { FaRegCircleDot } from "react-icons/fa6"
import Cookies from "js-cookie"

import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import DashboardConfirmBtn from "../../../../components/Buttons/DashboardConfirmBtn"
import { fetchService } from "../../request/confirmation/utils"
import { fetchRequestTitleAction } from "../../request/details/utils/title"
import { toggleDirectMessageReadStatusAsync } from "../../../../slices/message/directMessage"
import {
    BUSINESS, FREELANCER,
    COOKIE_REQUEST_REFID,
    messageDetailsPath
} from "../../../../assets/constants"
import toastify from "../../../../utils/toastify"

const MessageCard = ({ data }) => {
    const navigate = useNavigate()
    const viewMessage = () => {
        Cookies.set(COOKIE_REQUEST_REFID, data.requestRefId, { expires: 1 })
        navigate(messageDetailsPath.replace(":id", data.uid))
    }

    // Fetch request title
    const service = fetchService(data.requestRefId)
    const [requestTitle, setRequestTitle] = useState("")
    const dispatch = useDispatch()
    useEffect(() => {
        const actionAsync = fetchRequestTitleAction(data.requestRefId)
        dispatch(actionAsync(data.requestRefId))
            .unwrap()
            .then(data => setRequestTitle(data.payload))
            .catch(() => { })
    }, [])

    // Premium Plan check
    const { user } = useSelector(state => state.auth)
    const businessUserHasPremiumPlan = user.accountType === BUSINESS && user.plan && user.plan.name === "premium" && user.plan.status === "active"

    // Message Viewed check
    const freelancerUserHasAlreadyViewed = user.accountType === FREELANCER && !(data.restriction.message && data.restriction.message > 0)

    // Mark/UnMark read status
    const [csrfToken, setCsrfToken] = useState("")
    const [readStatus, setReadStaus] = useState(data.read)
    const handleSubmit = (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        dispatch(toggleDirectMessageReadStatusAsync({ id: data.uid, accountType: user.accountType, token: csrfToken }))
            .unwrap()
            .then((data) => setReadStaus(data.payload.read))
            .catch((error) => {
                const data = JSON.parse(error.message)
                if (data.shortInfo && data.shortInfo === "invalid-plan") {
                    toastify(data.message, data.status, submitBtn)
                }
            })
            .finally(() => {
                submitBtn.disabled = false
            })
    }
    return (
        <div className="border p-3 py-8 rounded-xl flex flex-col md:flex-row gap-y-3 gap-x-4 justify-between relative">
            <div className="space-y-2">
                <p className="text-base lg:text-lg font-semibold hover:text-versich-blue-hover cursor-pointer" onClick={viewMessage}>Room: {data.id} @{requestTitle ? requestTitle : service.name}</p>
                <div className="text-versich-light-gray text-sm inline-flex items-center gap-1 md:gap-1.5"><MdOutlineUpdate /> Contacted {formatDistanceToNow(data.createdAt, { addSuffix: true })}</div>
                <p className="text-sm text-versich-label">ref: {data.uid}</p>
            </div>
            {readStatus !== undefined && (
                readStatus
                    ? <FaCheckCircle className="absolute w-4 h-4 right-2 bottom-2 text-versich-blue" title="read" />
                    : <FaRegCircleDot className=" absolute w-4 h-4 right-2 bottom-2 text-versich-label" title="unread" />
            )}
            <form className="inline-flex flex-wrap md:flex-col gap-2" onSubmit={businessUserHasPremiumPlan || freelancerUserHasAlreadyViewed ? handleSubmit : null}>
                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                <DashboardConfirmBtn onClick={viewMessage}>View Message</DashboardConfirmBtn>
                {(businessUserHasPremiumPlan || freelancerUserHasAlreadyViewed)
                    &&
                    <button className="text-versich-blue hover:bg-versich-blue-hover/10 px-4 lg:px-5 py-2.5 border border-versich-blue font-medium text-sm rounded-xl" type="submit">
                        Mark as {readStatus ? "un" : ""}read
                    </button>
                }
            </form>
        </div>
    )
}
export default MessageCard