import { useSelector } from "react-redux"

import Meta from "../../../../components/Meta"
import { FREELANCER_TYPES } from "../../../../assets/constants"
import EditFreelancerLeadsLocation from "./FreelancerLeadsLocation"

const metadata = {
    title: "Edit Leads Location",
    description: "Edit user leads location",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/edit/leads/`
}

const EditLeadsLocation = () => {
    const { user } = useSelector((state) => state.auth)
    return (<>
        <Meta data={metadata} />
        {FREELANCER_TYPES.includes(user.accountType) && <EditFreelancerLeadsLocation />}
    </>)
}
export default EditLeadsLocation
