import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import Meta from "../../../../components/Meta"
import BackButton from "../../../../components/Buttons/BackButton"
import noMessages from "../../../../assets/images/no-messages.svg"
import MessageCard from "./MessageCard"
import { BUSINESS, dashboardPath } from "../../../../assets/constants"
import { allBusinessDirectMessageAsync, allFreelancerDirectMessageAsync } from "../../../../slices/message/directMessage"
import { FreelancerMenu, BusinessMenu } from "../../../../components/Dashboard/constants"

const metadata = {
    title: "All Direct Messages | VersiMarket",
    description: "Direct Messages page to view client request as message",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/message/all/`
}

const AllMessage = () => {
    const { user } = useSelector(state => state.auth)
    const [messages, setMessages] = useState([])

    const dispatch = useDispatch()
    useEffect(() => {
        const actionAsync = user.accountType === BUSINESS ? allBusinessDirectMessageAsync : allFreelancerDirectMessageAsync
        dispatch(actionAsync())
            .unwrap()
            .then(data => {
                setMessages(data.payload)
            })
            .catch(() => { })
    }, [])

    const navMenu = user.accountType === BUSINESS ? BusinessMenu : FreelancerMenu
    const navigate = useNavigate()
    return (
        <DashboardLayout navlinks={navMenu} activePage="Messages">
            <div className="text-start mb-5 lg:mb-12 space-y-8">
                <Meta data={metadata} />
                <div className="bg-white shadow-formLight rounded-xl">
                    <div className="border-b-2 px-3 py-3 md:py-6">
                        <BackButton onClick={() => navigate(dashboardPath)} />
                    </div>
                    <div className="px-3 py-12 space-y-4">
                        {messages.length > 0 ? (
                            <div className="text-start px-3 space-y-5">
                                <div className="flex pb-3">
                                    <p className="text-lg lg:text-xl text-versich-dark-blue font-semibold">Your messages</p>
                                </div>
                                {messages.map((data, index) => (
                                    <MessageCard data={data} key={index} />
                                ))}
                            </div>
                        ) : (
                            <div>
                                <div className="flex justify-center">
                                    <img className="w-[400px]" src={noMessages} alt="no messages" />
                                </div>
                                <p className="text-center text-base lg:text-lg font-semibold text-versich-dark-blue -mt-5">There are no direct messages</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}
export default AllMessage
