import requestCreated from "../../../../../assets/images/request-created.png"
import DialogBox, { getDialog } from "../../../../../components/DialogBox"

const RequestConfirmationDialogForGuest = ({ pid, redirectToLogin }) => {
    const handleClick = (event) => {
        const dialog = getDialog(event.target)
        if (!dialog) {
            return
        }
        redirectToLogin(dialog)
    }
    return (
        <DialogBox
            id={requestConfirmationDialog}
            className="backdrop:bg-oxford-blue/20 max-sm:max-w-sm"
            toastStacked={false}
            hideClose={true}
        >
            <div className="bg-lighter bg-opacity-20 mx-auto p-4 lg:p-4">
                <div className="text-center mb-1.5 sm:mb-3">
                    <h2 className="text-versich-dark-blue font-semibold text-lg sm:text-xl lg:text-2xl">Your request has been created</h2>
                    <span className="text-versich-label text-xs sm:text-sm lg:text-base mt-1.5">pid: {pid}</span>
                </div>
                <div className="flex justify-center p-4 py-6">
                    <img className="max-w-32 sm:max-w-48 lg:max-w-64 w-full" src={requestCreated} alt="request created" />
                </div>
                <div className="max-w-fit mx-auto">
                    <button className="bg-versich-blue hover:bg-versich-blue-hover text-sm lg:text-base w-full text-white font-medium px-4 lg:px-5 py-3 rounded-lg" type="button" onClick={handleClick} data-parent-id={requestConfirmationDialog}>View Request</button>
                </div>
            </div>
        </DialogBox >
    )
}
export const requestConfirmationDialog = "requestconfirmation-dialog"
export default RequestConfirmationDialogForGuest