import { useNavigate } from "react-router-dom"
import { MdOutlineChat, MdOutlineMarkUnreadChatAlt } from "react-icons/md"

import ConfirmButton from "../Buttons/ConfirmButton"
import { allMessagePath } from "../../assets/constants"
import { useSelector } from "react-redux"

const MessagesCard = () => {
    const { user } = useSelector(state => state.auth)
    const navigate = useNavigate()
    return (
        <div className="bg-white shadow-card px-4 py-4 sm:px-6 sm:py-6 rounded-xl space-y-4 text-start">
            <div className="text-start text-lg lg:text-xl font-semibold text-versich-dark-blue">Messages</div>
            <div className="flex max-smaller:flex-col smaller:items-center gap-3">
                <div className="bg-[#D9EBFC] max-smaller:w-fit rounded-full p-2.5 text-versich-dark-blue">
                    <MdOutlineChat className="w-[18px] h-[18px]" />
                </div>
                <div className="text-sm">{user.messages && user.messages.total ? user.messages.total : 0} Messages</div>
            </div>
            <div className="flex max-smaller:flex-col smaller:items-center gap-3">
                <div className="bg-[#D9EBFC] max-smaller:w-fit rounded-full p-2.5 text-versich-dark-blue">
                    <MdOutlineMarkUnreadChatAlt className="w-[18px] h-[18px]" />
                </div>
                <div className="text-sm">{user.messages && user.messages.unread ? user.messages.unread : 0} Unread messages and requests</div>
            </div>
            <div className="max-w-40">
                <ConfirmButton type="button" handleClick={() => navigate(allMessagePath)}>View messages</ConfirmButton>
            </div>
        </div>
    )
}
export default MessagesCard