import { useSelector } from "react-redux"
import { useLocation, Navigate, Outlet } from "react-router-dom"

import ErrorPage from "../ErrorPage/ErrorPage"
import {
    SS_VERIFICATION_STATUS, BUSINESS,
    dashboardPath, businessPaths
} from "../../assets/constants"

const EmailVerificationRoutes = () => {
    const { user } = useSelector(state => state.auth)
    const location = useLocation()
    if (user.verified) {
        const status = window.sessionStorage.getItem(SS_VERIFICATION_STATUS.key)
        if (status === SS_VERIFICATION_STATUS.value) {
            return <Navigate to={
                new URLSearchParams(location.search).get("next") || user.accountType === BUSINESS ? businessPaths.root : dashboardPath
            } />
        }
        return <ErrorPage status={user.social ? 403 : 404} />
    }
    return <Outlet />
}
export default EmailVerificationRoutes