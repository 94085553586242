import { createAsyncThunk } from "@reduxjs/toolkit"
import * as api from "../api"

export const allCountriesAsync = createAsyncThunk("account/all-countries", async () => {
    try {
        const { data } = await api.getAllCounties()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data?.payload
    }
    catch (error) {
        throw error
    }
})

export const countryStatesAsync = createAsyncThunk("account/country-states", async (iso3) => {
    try {
        const { data } = await api.getCountryStates(iso3)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data?.payload
    }
    catch (error) {
        throw error
    }
})