

export const getInitials = (fullname) => {
    const names = fullname.trim().split(" ")
    if (names.length >= 2) {
        return `${names[0].charAt(0).toUpperCase()}${names[1].charAt(0).toUpperCase()}`
    }
    else if (names.length === 1) {
        return names[0].charAt(0).toUpperCase()
    }
}

export const capitialize = (text) => {
    const initial = text[0].toUpperCase()
    return initial + text.slice(1)
}

export const titleCase = (text) => {
    const capitializedWords = text.trim().split(" ").map(word => {
        return capitialize(word)
    })
    return capitializedWords.join(" ")
}