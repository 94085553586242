import starIcon from "../../assets/svgs/star.svg"

const RatingSelectionField = ({ name, label, ratings, setRatings, error, setError }) => {
    const handleOptionChange = (e) => {
        let val = parseInt(e.target.value)
        if (isNaN(val)) {
            val = ratingOptions[0]
        }
        setRatings(val)
        setError(false)
    }
    return (
        <div className="space-y-2 text-start">
            {label && <label htmlFor={name} className="text-charcoal block text-sm text-start">{label}<span className="text-red-500 ml-1">*</span></label>}
            <div className="flex max-xl:flex-col flex-wrap gap-5">
                {ratingOptions.map((rating) => (
                    <label
                        className={"text-oxford-blue min-w-fit flex-1 py-2 px-4 border rounded-lg cursor-pointer transition-all "
                            + (ratings === rating
                                ? "bg-yellow-50 border-yellow-400"
                                : "bg-white border-[#D0D5DD] hover:border-versich-blue"
                            )}
                        key={rating}
                    >
                        <input name={name} className="appearance-none" type="radio" value={rating} checked={ratings === rating} onChange={handleOptionChange} />
                        <div className="inline-flex items-center gap-2">
                            <p className="text-charcoal text-sm font-medium ">{rating.toFixed(1)}</p>
                            <div className="inline-flex gap-2">
                                {[...Array(rating)].map((item, index) => (
                                    <img className="w-4 h-4" src={starIcon} alt={rating} key={index} />
                                ))}
                            </div>
                        </div>
                    </label>
                ))}
            </div>
            {error && <p className="text-red-500 text-start text-sm my-3">Please select at least one rating</p>}
        </div>
    )
}
export const ratingOptions = [
    5, 4, 3, 2, 1
]

export default RatingSelectionField
