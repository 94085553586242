import { useLocation, Navigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import Meta from "../../../components/Meta"
import AuthImgBanner from "../../../components/AuthImgBanner"
import Logo from "../../../components/Logo"
import { authenticateGoogleUser } from "../../../actions/socialAuth"
import { SS_ACCOUNT_TYPE, SS_AUTH_ERROR, loginPath } from "../../../assets/constants"

const metadata = {
    title: "Google Authentication",
    description: "Sign in with Google",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/social-accounts/verify/google-oauth2/`
}

const GoogleOAuthVerification = () => {
    const [status, setStatus] = useState("verifying")
    const location = useLocation()

    const dispatch = useDispatch()
    useEffect(() => {
        let params = location.search
        const accountType = sessionStorage.getItem(SS_ACCOUNT_TYPE.key)
        if (accountType) {
            params += `&accounttype=${accountType}`
        }
        dispatch(authenticateGoogleUser(params))
            .then((data) => setStatus(data?.status))
            .catch(error => {
                const data = JSON.parse(error?.message)
                window.sessionStorage.setItem(SS_AUTH_ERROR.key, data?.message)
                setStatus(null)
            })
        // eslint-disable-next-line
    }, [])
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 min-h-svh">
            <Meta data={metadata} />
            <AuthImgBanner />
            <div className="w-full m-auto space-y-5 lg:space-y-7 p-5 smaller:p-10 max-w-[580px] rounded-lg">
                <Logo />
                <h2 className="text-2xl leading-normal text-center mb-10 text-versich-darktext-color font-medium ">Signing you in with</h2>
                <section>
                    {status === null
                        ? <Navigate to={loginPath} />
                        : status === "verifying" && (
                            <div className="flex-grow inline-flex items-center">
                                <div className="max-w-24 sm:max-w-32 md:max-w-[180px] animate-pulse">
                                    <img className="" src="https://cdn-icons-png.flaticon.com/512/300/300221.png" alt={status} />
                                </div>
                            </div>
                        )
                    }
                </section>
                <p className="mt-5 animate-pulse">verifying your request..</p>
            </div>
        </div>
    )
}
export default GoogleOAuthVerification
