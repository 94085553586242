import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { formatDistanceToNow } from "date-fns"
import { MdOutlineUpdate, MdEmail, MdLocationCity } from "react-icons/md"
import { PiLinkBold, PiPhoneCallFill } from "react-icons/pi"
import { FaCrown, FaUser } from "react-icons/fa"
import { BiSolidLock, BiErrorCircle } from "react-icons/bi"
import { BsCheck2Circle } from "react-icons/bs"
import Cookies from "js-cookie"

import { fetchRequestTitleAction } from "../../../request/details/utils/title"
import { fetchService } from "../../../request/confirmation/utils"
import { COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION, businessPricingPath } from "../../../../../assets/constants"

const BusinessMessageCard = ({ data, pid, hasPremiumPlan }) => {
    const [requestTitle, setRequestTitle] = useState("")
    const dispatch = useDispatch()
    useEffect(() => {
        // Fetch request title
        const actionAsync = fetchRequestTitleAction(data.requestRefId)
        dispatch(actionAsync(data.requestRefId))
            .unwrap()
            .then(data => setRequestTitle(data.payload))
            .catch(() => { })
    }, [])
    const service = fetchService(data.requestRefId)

    const location = useLocation()
    const navigate = useNavigate()
    const upgradeToPremium = () => {
        const path = window.encodeURIComponent(location.pathname + location.search)
        Cookies.set(COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION, path, { expires: 1 })
        navigate(businessPricingPath)
    }
    return (
        <div className="p-5 md:p-10 space-y-4">
            <div className="text-start space-y-5">
                <div className="flex max-md:flex-col mb-1.5 md:mb-4">
                    <div className="flex-1 p-3">
                        <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold pb-3">Room: {data.id} @{pid ? pid + ": " : ""}{requestTitle ? requestTitle : service.name}</p>
                        <div className="text-versich-light-gray text-xs md:text-sm inline-flex items-center gap-1 md:gap-1.5"><MdOutlineUpdate /> Contacted {formatDistanceToNow(data.createdAt, { addSuffix: true })}</div>
                    </div>
                    {!hasPremiumPlan &&
                        <div className="pt-3 pl-3">
                            <button className="text-yellow-500 hover:bg-yellow-100 inline-flex items-center gap-1 py-1.5 px-3 border-2 border-yellow-300 rounded-md" onClick={upgradeToPremium}><FaCrown /> Upgrade to Reveal</button>
                        </div>
                    }
                </div>
                <div className="text-sm p-4 py-6 md:py-8 border-t">
                    {hasPremiumPlan
                        ? data.message
                        : <div className="w-3/4 md:w-1/3 h-10 rounded-md relative">
                            <div className="bg-[#F0F2FF] w-full h-full blur-[10px] rounded-xl" />
                            <div className="flex items-center justify-center w-full h-full absolute top-0">
                                <BiSolidLock className="text-oxford-blue/80 w-6 h-6" />
                            </div>
                        </div>
                    }
                </div>
                <div className="flex flex-col p-4 sm:p-6 py-6 border rounded-xl overflow-auto">
                    <div className="flex-1">
                        <p className="text-versich-dark-blue text-lg lg:text-xl font-semibold mb-4">Client Details</p>
                    </div>
                    <div className="sm:grid grid-cols-2 gap-4 xl:gap-x-8 min-w-[240px] py-2">
                        <div>
                            <p className="text-sm text-versich-label mb-1">Name</p>
                            <div className="flex max-smaller:flex-col smaller:items-center gap-x-1 mb-3 group">
                                <FaUser className="fill-versich-label" />
                                <span className="text-versich-darktext-color text-sm">{data.client.name}</span>
                            </div>
                        </div>
                        <div>
                            <p className="text-sm text-versich-label mb-1">Email</p>
                            {hasPremiumPlan
                                ? <a className="flex max-smaller:flex-col smaller:items-center gap-x-1 mb-3 group" href={`mailto:${data.client.email}`} style={{ overflowWrap: "anywhere" }}>
                                    <MdEmail className="fill-versich-light-blue group-hover:fill-versich-blue-hover" />
                                    <span className="text-versich-light-blue group-hover:text-versich-blue-hover text-sm inline-flex items-center gap-1.5">
                                        {data.client.email} {data.client.verified ? <BsCheck2Circle className="text-green-700" title="Email verified" /> : <BiErrorCircle className="text-yellow-500 w-4 h-4" title="Email not verified" />}
                                    </span>
                                </a>
                                : <div className="w-3/4 md:w-[200px] h-10 rounded-md relative">
                                    <div className="bg-[#F0F2FF] w-full h-full blur-[10px] rounded-xl" />
                                    <div className="flex items-center justify-center w-full h-full absolute top-0">
                                        <BiSolidLock className="text-oxford-blue/80 w-6 h-6" />
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                            <p className="text-sm text-versich-label mb-1">Phone number</p>
                            {hasPremiumPlan
                                ? <a className="flex max-smaller:flex-col smaller:items-center gap-x-1 mb-3 group" href={`tel:${data.client.about.phone.code + data.client.about.phone.number}`}>
                                    <PiPhoneCallFill className="fill-versich-light-blue group-hover:fill-versich-blue-hover" />
                                    <span className="text-versich-light-blue group-hover:text-versich-blue-hover text-sm">{data.client.about.phone.code} {data.client.about.phone.number}</span>
                                </a>
                                : <div className="w-3/4 md:w-[200px] h-10 rounded-md relative">
                                    <div className="bg-[#F0F2FF] w-full h-full blur-[10px] rounded-xl" />
                                    <div className="flex items-center justify-center w-full h-full absolute top-0">
                                        <BiSolidLock className="text-oxford-blue/80 w-6 h-6" />
                                    </div>
                                </div>
                            }
                        </div>
                        {data.client.about.country &&
                            <div>
                                <p className="text-sm text-versich-label mb-1">Country</p>
                                <div className="flex max-smaller:flex-col smaller:items-center gap-x-1 mb-3 group">
                                    <MdLocationCity className="fill-versich-label" />
                                    <span className="text-versich-label text-sm">{data.client.about.country.name}</span>
                                </div>
                            </div>
                        }
                        {data.client.about.website &&
                            <div>
                                <p className="text-sm text-versich-label mb-1">Website</p>
                                <a className="flex max-smaller:flex-col smaller:items-center gap-x-1 mb-3 group" href={data.client.about.website} target="_blank" rel="opener">
                                    <PiLinkBold className="fill-versich-light-blue group-hover:fill-versich-blue-hover" />
                                    <span className="text-versich-light-blue group-hover:text-versich-blue-hover text-sm">{data.client.about.website}</span>
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BusinessMessageCard