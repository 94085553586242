import { useRef, useState, useEffect } from "react"
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io"

import "./our-services-carousel.css"
import ServiceCard from "./ServiceCard"
import ServicesList from "../../assets/ServicesList"

const OurServicesCarousel = () => {
    const scrollContainerRef = useRef(null)
    const intervalIdRef = useRef(null)
    const resumeTimer = useRef(null)
    const [isHovered, setIsHovered] = useState(false)

    const startScrolling = () => {
        if (intervalIdRef.current) {
            return
        }
        intervalIdRef.current = setInterval(() => {
            const scrollContainer = scrollContainerRef.current
            if (!isHovered) {
                const container = scrollContainer
                const lastChild = container.lastElementChild
                if (lastChild) {
                    if (container.scrollLeft >= lastChild.offsetLeft - container.clientWidth) {
                        const firstImages = container.querySelectorAll(".scroll-item")
                        const images = [...firstImages]
                        images.slice(0, 6).forEach(image => {
                            const clone = image.cloneNode(true)
                            container.appendChild(clone)
                        })
                    }
                    container.scrollLeft += 1
                }
            }
        }, 20)
    }
    const stopScrolling = () => {
        clearInterval(intervalIdRef.current)
        intervalIdRef.current = null
    }

    // Check carousel in viewport
    const checkInView = () => {
        if (!scrollContainerRef.current) {
            return
        }
        const rect = scrollContainerRef.current.getBoundingClientRect()
        setInView(
            rect.top < window.innerHeight && rect.bottom >= 0
        )
    }
    const [inView, setInView] = useState(true)
    useEffect(() => {
        inView ? startScrolling() : stopScrolling()
        // eslint-disable-next-line
    }, [inView])

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current

        // Clone the first set of images initially
        const firstImages = scrollContainer.querySelectorAll(".scroll-item")
        firstImages.forEach((image) => {
            const clone = image.cloneNode(true)
            scrollContainer.appendChild(clone)
        })
        checkInView()
        document.addEventListener("scroll", checkInView)

        // Pause/Resume on Mouse hover
        const handleMouseEnter = () => {
            setIsHovered(true)
            stopScrolling()
        }
        const handleMouseLeave = () => {
            setIsHovered(false)
            startScrolling()
        }
        scrollContainer.addEventListener("mouseenter", handleMouseEnter)
        scrollContainer.addEventListener("mouseleave", handleMouseLeave)
        return () => {
            stopScrolling()
            document.removeEventListener("scroll", checkInView)
            scrollContainer.removeEventListener("mouseenter", handleMouseEnter)
            scrollContainer.removeEventListener("mouseleave", handleMouseLeave)
        }
        // eslint-disable-next-line
    }, [])

    const scrollWithLeftArrow = () => {
        const scrollContainer = scrollContainerRef.current
        const container = scrollContainer
        const lastChild = container.lastElementChild
        if (lastChild) {
            stopScrolling()
            if (container.scrollLeft >= lastChild.offsetLeft - container.clientWidth) {
                const firstImages = container.querySelectorAll(".scroll-item")
                firstImages.forEach((image) => {
                    const clone = image.cloneNode(true)
                    container.appendChild(clone)
                })
            }
            container.scrollLeft -= lastChild.offsetWidth * -1
            resumeTimer.current && clearTimeout(resumeTimer.current)
            resumeTimer.current = setTimeout(() => {
                startScrolling()
            }, 2000)
        }
    }
    const scrollWithRightArrow = () => {
        const scrollContainer = scrollContainerRef.current
        const container = scrollContainer
        const lastChild = container.lastElementChild
        if (lastChild) {
            stopScrolling()
            if (container.scrollLeft >= lastChild.offsetLeft - container.clientWidth) {
                const firstImages = container.querySelectorAll(".scroll-item")
                firstImages.forEach((image) => {
                    const clone = image.cloneNode(true)
                    container.appendChild(clone)
                })
            }
            container.scrollLeft += lastChild.offsetWidth
            resumeTimer.current && clearTimeout(resumeTimer.current)
            resumeTimer.current = setTimeout(() => {
                startScrolling()
            }, 2000)
        }
    }
    return (
        <div className="mt-16 max_screen_w">
            <div className="text-start md:text-center px-10">
                <p className="text-base lg:text-lg text-versich-light-blue mb-2">Our Services</p>
                <p className="text-3xl lg:text-4xl font-bold text-versich-darktext-color">We Provide The Best Service</p>
            </div>
            <div className="flex item-center relative overflow-hidden">
                <button className="bg-gradient-to-r from-white to-90% to-versich-primary-bg/80 h-full px-1.5 smaller:px-2 sm:px-3 md:px-4 absolute -left-1 z-10" onClick={() => scrollWithLeftArrow()}>
                    <IoIosArrowDropleftCircle className="fill-versich-label w-6 sm:w-7 h-6 sm:h-7" />
                </button>
                <div className="flex scroll-container overflow-x-auto snap-x-mandatory py-6" ref={scrollContainerRef}>
                    {ServicesList.map((service) => (
                        <ServiceCard service={service} key={service.id} />
                    ))}
                </div>
                <button className="bg-gradient-to-l from-white to-90% to-versich-primary-bg/80 h-full px-1.5 smaller:px-2 sm:px-3 md:px-4 absolute -right-1 " onClick={() => scrollWithRightArrow()}>
                    <IoIosArrowDroprightCircle className="fill-versich-label w-6 sm:w-7 h-6 sm:h-7" />
                </button>
            </div>
        </div>
    )
}
export default OurServicesCarousel
