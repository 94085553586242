import { useSelector, useDispatch } from "react-redux"
import { useEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import { ToastContainer, Flip } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

import ScrollToTop from "./components/ScrollToTop"
import MainLoader from "./components/loader/MainLoader"
import AllRoutes from "./components/appRoutes/AllRoutes"
import { TOASTTIMER } from "./assets/constants"
import { checkAuth } from "./actions/auth"

function App() {
    const { isLoading } = useSelector(state => state.authVerify)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(checkAuth())
        // eslint-disable-next-line
    }, [])
    return (
        <div className="App">
            <BrowserRouter >
                <ScrollToTop>
                    {isLoading
                        ? <MainLoader />
                        : <AllRoutes />
                    }
                </ScrollToTop>
                <ToastContainer stacked containerId={"app"} position="bottom-right" autoClose={TOASTTIMER} hideProgressBar={false}
                    newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable transition={Flip} toastClassName="text-start" />
            </BrowserRouter>
        </div>
    )
}

export default App
