import Google from "./Google"

const SocialAccounts = ({ google = { url: "", text: "" } }) => {
    return (<>
        <div className="flex items-center gap-5 justify-between px-2.5">
            <div className="bg-[#E0E0E0] h-[1px] w-full rounded-md" />
            <p className="text-[#616161]">or</p>
            <div className="bg-[#E0E0E0] h-[1px] w-full" />
        </div>
        {google && <Google url={google.url} text={google.text} />}
    </>)
}
export default SocialAccounts