import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../api"
import apiErrorParser from "../utils/apiErrorParser"

export const allBusinessTypeAsync = createAsyncThunk("service/allBusinessType", async () => {
    try {
        const { data } = await api.getAllBusinessType()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allIndustryTypeAsync = createAsyncThunk("service/allIndustryType", async () => {
    try {
        const { data } = await api.getAllIndustryType()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allProjectDurationAsync = createAsyncThunk("service/allProjectDuration", async () => {
    try {
        const { data } = await api.getAllProjectDuration()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allCurrencyAsync = createAsyncThunk("service/allCurrency", async () => {
    try {
        const { data } = await api.getAllCurrency()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allEstimatedBudgetAsync = createAsyncThunk("service/allEstimatedBudget", async () => {
    try {
        const { data } = await api.getAllEstimatedBudget()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allHiringDecisionAsync = createAsyncThunk("service/allHiringDecision", async () => {
    try {
        const { data } = await api.getAllHiringDecision()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allBusinessSizeAsync = createAsyncThunk("service/allBusinessSize", async () => {
    try {
        const { data } = await api.getAllBusinessSize()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})