import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { FaRegEye } from "react-icons/fa"
import Cookies from "js-cookie"

import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import Meta from "../../../../components/Meta"
import FreelancerCard from "../../../../components/Requests/BusinessCard"
import ContactFreelancerDialog from "../../../../components/Requests/ContactFreelancerDialog"
import { ClientMenu } from "../../../../components/Dashboard/constants"
import { serviceRelatedFreelancersAsync } from "../../../../slices/freelancer"
import { COOKIE_REQUEST_REFID, requestDetailsPath } from "../../../../assets/constants"
import { fetchService } from "./utils"

const metadata = {
    title: "Request Confirmation",
    description: "VersiMarket user dashboard",
}

const RequestConfirmation = () => {
    const { id, pid } = useParams()
    const [freelancers, setFreelancers] = useState([])
    const [expertId, setExpertId] = useState("")
    const [messageSent, setMessageSent] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        const service = fetchService(id)
        dispatch(serviceRelatedFreelancersAsync(service.uid))
            .unwrap()
            .then(data => {
                Cookies.set(COOKIE_REQUEST_REFID, id, { expires: 1 })
                setFreelancers(data.recommendedFreelancers)
            })
            .catch(() => { })
    }, [])

    const navigate = useNavigate()
    const viewRequest = () => {
        navigate(requestDetailsPath.replace(":id", id))
    }
    return (
        <DashboardLayout navlinks={ClientMenu} activePage="My Requests">
            <div className="text-start mb-5 lg:mb-12">
                <div className="space-y-4">
                    <Meta data={metadata} />
                    <div className="bg-white shadow-card text-versich-dark-blue font-semibold text-start flex justify-between flex-col sm:flex-row gap-2 w-full px-4 py-4 sm:px-6 sm:py-6 rounded-xl">
                        <p>Request created with pid: {pid}</p>
                        <button className="text-versich-blue hover:text-versich-blue-hover text-sm inline-flex items-center gap-1.5" onClick={viewRequest} title="View Request">
                            <FaRegEye className="w-3.5 md:w-4 h-3.5 md:h-4" /> View request
                        </button>
                    </div>
                    {messageSent &&
                        <div className="bg-cyan-100 shadow-sticker w-full !mt-5 px-4 py-2 sm:px-6 sm:py-4 font-semibold text-start text-versich-dark-blue rounded-xl">
                            Message sent to freelancer.
                        </div>
                    }
                    <div className="bg-white shadow-card rounded-xl">
                        <div className="px-5 md:px-10 py-6 md:py-12 space-y-5">
                            <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold pb-3">Recommended Freelancers</p>
                            <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-y-10 gap-x-4 xl:gap-x-8 text-start">
                                {freelancers.map(freelancer => (
                                    <FreelancerCard user={freelancer} contactExpertClick={() => setExpertId(freelancer.id)} key={freelancer.id} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <ContactFreelancerDialog requestRefId={id} expertId={expertId} setMessageSent={setMessageSent} />
            </div>
        </DashboardLayout>
    )
}
export default RequestConfirmation