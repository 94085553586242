import Meta from "../../components/Meta"
import Hero from "./components/Hero"
import ServiceBadges from "./components/ServiceBadges"
import ServicesList from "../../assets/ServicesList"
import SubServices from "./components/SubServices"

const metadata = {
    title: "VersiMarket Services",
    description: "Web-based Services Marketplace for Web Design, Development, Mobile Apps and Data Analytics",
    url: `${process.env.REACT_APP_CLIENT_URL}/services/`
}

const Services = () => {
    return (
        <div>
            <Meta data={metadata} />
            <Hero />
            <ServiceBadges />
            <div className="text-start py-16 px-8 md:px-20 lg:px-40 xl:px-60 largeLaptop:px-80">
                <div className="max_screen_w">
                    <h2 className="text-3xl lg:text-4xl leading-[1.2] font-semibold text-versich-darktext-color">All Services</h2>
                    {ServicesList.map(service => (
                        <div className="flex max-sm:flex-col items-start sm:gap-10 py-5 sm:py-8 border-b border-slate-100 last-of-type:border-none" key={service.id}>
                            <div className="text-center my-3 max-sm:max-w-[259px] w-full sm:w-fit max-sm:overflow-hidden border border-gray-100 shadow-form hover:shadow-card transition-shadow rounded-lg cursor-pointer">
                                <div className="h-[150px] md:h-[170px] w-[257px] md:w-[325px] relative overflow-hidden rounded-tr-lg rounded-tl-lg">
                                    <img src={service.image} alt={service.name} className="object-cover w-full h-full" />
                                </div>
                                <div className="h-auto text-start px-4 py-2 md:py-4">
                                    <p className="font-bold text-base md:text-lg text-versich-darktext-color">{service.name}</p>
                                </div>
                            </div>
                            <SubServices id={service.uid} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Services
