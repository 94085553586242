import { Link } from "react-router-dom"

import logo from "../assets/logo.png"
import { homePath } from "../assets/constants"

const Logo = ({ href = homePath }) => {
    return (
        <div>
            <Link to={href} className="flex items-center justify-center">
                <img src={logo} alt="logo" className="w-12 md:w-14 xl:w-16" />
                &nbsp;
                <span className="font-semibold text-2xl md:text-3xl text-versich-dark-blue">VersiMarket</span>
            </Link>
        </div>
    )
}
export default Logo