
const MoreService = ({ data, navigateToRequestForm }) => {
    const handleClick = (uid) => {
        navigateToRequestForm(`${uid}/`)
    }
    return (
        <div className="flex flex-wrap">
            {data.map(item => (
                <div className="bg-gradient-to-r from-purple-400 via-versich-blue/30 to-purple-300 m-2 lg:m-3 xl:m-4 rounded-md relative group/service-tag cursor-pointer" onClick={() => handleClick(item.uid)} key={item.uid}>
                    <div className="bg-white/60 h-full backdrop-filter backdrop-blur-sm shadow-lg ring-1 ring-gray-200/50 rounded-md">
                        <div className="bg-white text-versich-blue group-hover/service-tag:text-purple-400 text-sm md:text-base mb-1 p-2 rounded-md">{item.title}</div>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default MoreService