
const EditWebsite = ({ className }) => {
    return (
        <svg className={className} width="82" height="70" viewBox="0 0 82 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.9998 64.6666H8.4165C5.24087 64.6666 2.6665 62.0923 2.6665 58.9166V9.08325C2.6665 5.90762 5.24087 3.33325 8.4165 3.33325H73.5832C76.7589 3.33325 79.3332 5.90762 79.3332 9.08325V32.196" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2.6665 9.08325C2.6665 5.90762 5.24087 3.33325 8.4165 3.33325H73.5832C76.7589 3.33325 79.3332 5.90762 79.3332 9.08325V26.3333H2.6665V9.08325Z" stroke="black" strokeWidth="5" />
            <path d="M10.3333 14.8332C10.3333 12.7161 12.0496 10.9998 14.1667 10.9998C16.2838 10.9998 18 12.7161 18 14.8332C18 16.9503 16.2838 18.6665 14.1667 18.6665C12.0496 18.6665 10.3333 16.9503 10.3333 14.8332Z" fill="#114B8A" />
            <path d="M21.8333 14.8332C21.8333 12.7161 23.5496 10.9998 25.6667 10.9998C27.7838 10.9998 29.5 12.7161 29.5 14.8332C29.5 16.9503 27.7838 18.6665 25.6667 18.6665C23.5496 18.6665 21.8333 16.9503 21.8333 14.8332Z" fill="#114B8A" />
            <path d="M65.9165 58.9165C69.0921 58.9165 71.6665 56.3421 71.6665 53.1665C71.6665 49.9909 69.0921 47.4165 65.9165 47.4165C62.7409 47.4165 60.1665 49.9909 60.1665 53.1665C60.1665 56.3421 62.7409 58.9165 65.9165 58.9165Z" stroke="black" strokeWidth="5" />
            <path d="M65.9165 66.5832V58.9165" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M65.9165 47.4167V39.75" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M54.2974 59.8748L60.9369 56.0415" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M70.896 50.2916L77.5355 46.4583" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M54.2974 46.4583L60.9369 50.2916" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M70.896 56.0415L77.5355 59.8748" stroke="black" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}
export default EditWebsite