import { getRequestTitleAsync as getWDADRequestTitleAsync } from "../../../../../slices/client/service/webDesignAndDevelopment"
import { getRequestTitleAsync as getMARequestTitleAsync } from "../../../../../slices/client/service/mobileApp"
import { getRequestTitleAsync as getDAABusinessRequestTitleAsync } from "../../../../../slices/client/service/dataAndAnalytics/business"
import { getRequestTitleAsync as getDAAPersonalRequestTitleAsync } from "../../../../../slices/client/service/dataAndAnalytics/personal"
import { getRequestTitleAsync as getSDRequestTitleAsync } from "../../../../../slices/client/service/softwareDevelopment"
import { getRequestTitleAsync as getFT_FPAR_RequestTitleAsync } from "../../../../../slices/client/service/financeTransformation/FPandARevolution"
import { getRequestTitleAsync as getFT_FRAA_RequestTitleAsync } from "../../../../../slices/client/service/financeTransformation/FRAA"
import { getRequestTitleAsync as getFT_EN_RequestTitleAsync } from "../../../../../slices/client/service/financeTransformation/enterprise"
import { getRequestTitleAsync as getFT_SA_RequestTitleAsync } from "../../../../../slices/client/service/financeTransformation/systemAdministration"
import { getRequestTitleAsync as getFT_DT_RequestTitleAsync } from "../../../../../slices/client/service/financeTransformation/digitalTransformation"
import { getRequestTitleAsync as getFTRequestTitleAsync } from "../../../../../slices/client/service/financeTransformation"
import { getRequestTitleAsync as getMLAIRequestTitleAsync } from "../../../../../slices/client/service/machineLearningAndAI"

export const fetchRequestTitleAction = (refId) => {
    const words = refId.split("_")
    if (words.length === 3) {
        const serviceCode = words[0]
        const subCode = words[1]
        if (serviceCode === "DAA") {
            if (subCode === "BU") {
                return getDAABusinessRequestTitleAsync
            }
            else if (subCode === "PE") return getDAAPersonalRequestTitleAsync
        }
        else if (serviceCode === "FT") {
            if (subCode === "FPAR") {
                return getFT_FPAR_RequestTitleAsync
            }
            else if (subCode === "FRAA") {
                return getFT_FRAA_RequestTitleAsync
            }
            else if (subCode === "EN") {
                return getFT_EN_RequestTitleAsync
            }
            else if (subCode === "SA") {
                return getFT_SA_RequestTitleAsync
            }
            else if (subCode === "DT") {
                return getFT_DT_RequestTitleAsync
            }
        }
    }
    else if (words.length === 2) {
        const serviceCode = words[0]
        switch (serviceCode) {
            case "WDAD":
                return getWDADRequestTitleAsync
            case "MA":
                return getMARequestTitleAsync
            case "SD":
                return getSDRequestTitleAsync
            case "FT":
                return getFTRequestTitleAsync
            case "MLAI":
                return getMLAIRequestTitleAsync
            default:
                break
        }
    }
    return null
}