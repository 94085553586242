import { useSelector } from "react-redux"
import { useNavigate, Link } from "react-router-dom"
import { FaCrown } from "react-icons/fa"

import Meta from "../Meta"
import SideNavbar from "../Navbar/DashSideNavbar"
import Header from "./Header/Header"
import {
    SS_VERIFICATION_STATUS,
    emailVerificationPath, businessPricingPath,
    CLIENT, FREELANCER, BUSINESS
} from "../../assets/constants"

const metadata = {
    title: "Dashboard",
    description: "VersiMarket user dashboard",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/`
}

const DashboardLayout = ({ children, activePage, navlinks, showVerifyEmailBanner = false }) => {
    const { user } = useSelector((state) => state.auth)

    // Clean session cookie
    window.sessionStorage.removeItem(SS_VERIFICATION_STATUS.key)
    const navigate = useNavigate()
    return (
        <div className="bg-[#FBFBFF] flex">
            <Meta data={metadata} />
            <SideNavbar navlinks={navlinks} activePage={activePage} />
            <div className="w-full h-svh flex flex-col">
                <Header />
                <div className="flex-grow w-full h-full p-2.5 pl-[14px] pb-5 space-y-3 overflow-y-auto">
                    {showVerifyEmailBanner && <>
                        {user.accountType === CLIENT && !user.verified && <>
                            <div className="bg-white shadow-card w-full px-4 py-4 sm:px-6 sm:py-6 font-semibold text-start text-versich-dark-blue rounded-xl flex justify-between sm:items-center flex-col sm:flex-row gap-4">
                                <p>To have full access, and reach more freelancers.</p>
                                <Link to={emailVerificationPath} className="text-versich-blue underline hover:text-versich-blue-hover">Verify Email</Link>
                            </div>
                        </>}
                        {user.accountType === FREELANCER && !user.verified && <>
                            <div className="bg-white shadow-card w-full px-4 py-4 sm:px-6 sm:py-6 font-semibold text-start text-versich-dark-blue rounded-xl flex justify-between sm:items-center flex-col sm:flex-row gap-4">
                                <p>To have full access, and reach more clients</p>
                                <Link to={emailVerificationPath} className="text-versich-blue underline hover:text-versich-blue-hover">Verify Email</Link>
                            </div>
                        </>}
                        {user.accountType === BUSINESS &&
                            (!user.plan || user.plan.status !== "active") &&
                            <div className="bg-white shadow-card w-full px-4 py-4 sm:px-6 sm:py-6 font-semibold text-start text-versich-dark-blue rounded-xl flex justify-between sm:items-center flex-col sm:flex-row gap-4">
                                <p>To have full access, and reach more clients.</p>
                                <div>
                                    <button className="text-yellow-500 hover:bg-yellow-100 inline-flex items-center gap-1 py-1.5 px-3 border-2 border-yellow-300 rounded-md" onClick={() => navigate(businessPricingPath)}><FaCrown /> Upgrade to Premium</button>
                                </div>
                            </div>
                        }
                    </>}
                    {children}
                </div>
            </div>
        </div>
    )
}
export default DashboardLayout