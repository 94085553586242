import infoIcon from "../assets/svgs/info.svg"

const QuickNote = ({ className, title, description }) => {
    return (
        <div className={"bg-brandeis-blue/[0.08] flex gap-3.5 items-start text-start px-4 sm:px-6 py-4 rounded-lg " + (
            className ? className : ""
        )}>
            <div className="min-w-6">
                <img className="text-[#65A3FC] text-2xl" src={infoIcon} alt="" />
            </div>
            <div className="space-y-1">
                <h3 className="text-versich-dark-blue text-sm font-bold">{title || "Quick Note"}</h3>
                <p className="text-versich-label text-xs">{description}</p>
            </div>
        </div>
    )
}
export default QuickNote