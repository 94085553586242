import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const freelancerOtherRemainingServicesAsync = createAsyncThunk("account/freelancerOtherRemainingServices", async () => {
    try {
        const { data } = await api.freelancerOtherRemainingServices()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const freelancerOtherServiceUpdateAsync = createAsyncThunk("account/freelancerOtherServiceUpdate",
    async ({ serviceIds, token }, { dispatch }) => {
        try {
            const { data } = await api.freelancerOtherServiceUpdate({ serviceids: serviceIds, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })