import { useSelector, useDispatch } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import ServicesList from "../../../../assets/ServicesList"
import { dashboardPath, editLeadsLocationPath, TOASTTIMER } from "../../../../assets/constants"
import { freelancerPrimaryServiceUpdateAsync } from "../../../../slices/freelancer/primaryService"

const EditPrimaryService = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()
    const navigate = useNavigate()

    const [csrfToken, setCsrfToken] = useState("")
    const [selectedService, setSelectedService] = useState("")
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!user || !user.primaryService) {
            return
        }
        setSelectedService(user.primaryService.uid)
    }, [user])

    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (selectedService) {
            const formData = {
                token: csrfToken,
                serviceid: selectedService
            }
            setLoading(true)
            await dispatch(freelancerPrimaryServiceUpdateAsync(formData))
                .unwrap()
                .then(() => {
                    navigate(user.leadsLocation ? editLeadsLocationPath : dashboardPath)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, TOASTTIMER)
        }
    }
    return (
        <FormProvider {...methods}>
            <BrandBar />
            <div className="bg-white mb-12 py-10 md:py-14 px-3 smaller:px-8 md:px-16 lg:px-28 overflow-hidden">
                <form className="max_screen_w flex flex-col" onSubmit={handleSubmit}>
                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                    <div className="bg-white flex flex-col flex-1 px-4 sm404:px-12 xl:px-20 py-8 shadow-formLight rounded-md">
                        <div className="space-y-3 pb-6 border-b border-[#EFEEEE99]">
                            <h1 className="text-versich-dark-blue text-xl sm:text-2xl font-medium flex justify-center">Choose your primary service?</h1>
                            <p className="text-versich-label text-xs sm:text-sm font-medium">You can select only one now and add more services later.</p>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-5 sm:gap-y-8 gap-x-5 py-6">
                            {ServicesList.map((service) => (
                                <div
                                    key={service.id}
                                    className={`flex flex-col justify-center items-center gap-y-2 h-44 smaller:h-52 xl:h-60 py-4 px-3 border-[3px] ${selectedService === service.uid
                                        ? `border-versich-blue/80 bg-versich-blue/15`
                                        : `${service.bgColor} bg-opacity-[0.04] ${service.borderColor} border-opacity-[0.04] hover:${service.borderColor} hover:border-opacity-20`
                                        } cursor-pointer rounded-xl`
                                    }
                                    onClick={() => setSelectedService(service.uid)}
                                >
                                    <input className="appearance-none" name="selectedService" type="radio" value={service.uid} />
                                    <img src={service.icon} alt={service.name} className="w-16 h-16 drop-shadow contrast-150" />
                                    <span className="text-versich-dark-blue text-center text-sm font-medium">{service.name}</span>
                                </div>
                            ))}
                        </div>
                        {error && <p className="text-red-500 text-start text-sm mb-3">Please select a service.</p>}
                        {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                        <hr className="pb-3" />
                        <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                            <button className="bg-columbia-blue hover:opacity-80 text-versich-light-blue border border-columbia-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(dashboardPath)}>Back</button>
                            <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(editLeadsLocationPath)}>Skip</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Next</button>
                        </div>
                    </div>
                </form>
            </div>
            {loading && <FormLoader />}
        </FormProvider>
    )
}
export default EditPrimaryService