import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import apiErrorParser from "../../utils/apiErrorParser"

export const getBusinessPaidPlansAsync = createAsyncThunk("account/businessPaidPlans", async () => {
    try {
        const { data } = await api.getBusinessPaidPlans()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createBusinessCheckoutSessionAsync = createAsyncThunk("account/createBusinessBusinessCheckoutSession", async ({ period, token }) => {
    try {
        const { data } = await api.createBusinessCheckoutSession({ period, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})