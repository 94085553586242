import { useSelector } from "react-redux"

import ClientDashboard from "./client/ClientDashboard"
import FreelancerDashboard from "./freelancer/FreelancerDashboard"
import BusinessDashboard from "./business/BusinessDashboard"
import { CLIENT, FREELANCER, BUSINESS } from "../../assets/constants"

const Dashboard = () => {
    const { user } = useSelector((state) => state.auth)
    return (<>
        {user.accountType === CLIENT && <ClientDashboard />}
        {user.accountType === FREELANCER && <FreelancerDashboard />}
        {user.accountType === BUSINESS && <BusinessDashboard />}
    </>)
}
export default Dashboard
