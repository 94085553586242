import bannerImage from "../assets/images/auth-bg.svg"

const AuthImgBanner = () => {
    return (
        <div className="bg-[#F0F2FF] hidden lg:flex items-center justify-center min-h-svh">
            <div className="bg-no-repeat bg-center w-full h-full max-xl:hidden" style={{ backgroundImage: `url("${bannerImage}")` }}>            </div>
            <img className="xl:hidden max-h-svh" src={bannerImage} alt="VersiMarket" />
        </div >
    )
}
export default AuthImgBanner