import { Link } from "react-router-dom"

import serviceIcon from "../../assets/icons/service.png"
import Hero from "./components/Hero"
import OurServicesCarousel from "../../components/OurServicesCarousel/OurServicesCarousel"
import Meta from "../../components/Meta"
import ServicesList from "../../assets/ServicesList"
import SubServices from "../services/components/SubServices"
import { servicesPath } from "../../assets/constants"

const metadata = {
    title: "VersiMarket",
    description: "Web-based Services Marketplace for Web Design, Development, Mobile Apps and Data Analytics",
    url: `${process.env.REACT_APP_CLIENT_URL}/`
}

const Home = () => {
    return (
        <div>
            <Meta data={metadata} />
            <Hero />
            <OurServicesCarousel />
            <div className="text-start py-16 px-8 md:px-20 lg:px-48">
                <div className="max_screen_w">
                    <h2 className="text-versich-darktext-color font-semibold flex items-center gap-4">
                        <span className="text-3xl lg:text-4xl leading-[1.2]">Services</span>
                        <Link className="text-versich-blue-hover hover:opacity-80 inline-flex items-center justify-center gap-0.5 lg:gap-1" to={servicesPath}>
                            (
                            <img className="w-4 lg:w-5" src={serviceIcon} alt="" />
                            <span className="max-lg:text-sm">view all</span>
                            )
                        </Link>
                    </h2>
                    {ServicesList.map(service => (
                        <div className="flex max-sm:flex-col sm:flex-row-reverse items-start sm:gap-10 py-5 sm:py-8 border-b border-slate-100 last-of-type:border-none" key={service.id}>
                            <div className="flex flex-col items-center justify-center max-sm401:mx-auto min-w-[150px] md:min-w-[250px] mb-3 p-3.5 border border-gray-100 shadow-form rounded-2xl">
                                <img src={service.icon} alt={service.name} className="w-12 h-12 drop-shadow contrast-150" />
                                <p className="text-versich-darktext-color font-bold text-[0.75rem] leading-[1rem] text-center md:text-sm max-w-[120px] md:max-w-[220px] pt-2 md:pt-4">{service.name}</p>
                            </div>
                            <SubServices id={service.uid} importantOnly={true} />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Home
