import { getRequestAsync as getWDADRequestAsync } from "../../../../../slices/client/service/webDesignAndDevelopment"
import { getRequestAsync as getMARequestAsync } from "../../../../../slices/client/service/mobileApp"
import { getRequestAsync as getDAABusinessRequestAsync } from "../../../../../slices/client/service/dataAndAnalytics/business"
import { getRequestAsync as getDAAPersonalRequestAsync } from "../../../../../slices/client/service/dataAndAnalytics/personal"
import { getRequestAsync as getSDRequestAsync } from "../../../../../slices/client/service/softwareDevelopment"
import { getRequestAsync as getFT_FPAR_RequestAsync } from "../../../../../slices/client/service/financeTransformation/FPandARevolution"
import { getRequestAsync as getFT_FRAA_RequestAsync } from "../../../../../slices/client/service/financeTransformation/FRAA"
import { getRequestAsync as getFT_EN_RequestAsync } from "../../../../../slices/client/service/financeTransformation/enterprise"
import { getRequestAsync as getFT_SA_RequestAsync } from "../../../../../slices/client/service/financeTransformation/systemAdministration"
import { getRequestAsync as getFT_DT_RequestAsync } from "../../../../../slices/client/service/financeTransformation/digitalTransformation"
import { getRequestAsync as getFTRequestAsync } from "../../../../../slices/client/service/financeTransformation"
import { getRequestAsync as getMLAIRequestAsync } from "../../../../../slices/client/service/machineLearningAndAI"

export const fetchRequestAction = (refId) => {
    const words = refId.split("_")
    if (words.length === 3) {
        const serviceCode = words[0]
        const subCode = words[1]
        if (serviceCode === "DAA") {
            if (subCode === "BU") {
                return getDAABusinessRequestAsync
            }
            else if (subCode === "PE") return getDAAPersonalRequestAsync
        }
        else if (serviceCode === "FT") {
            if (subCode === "FPAR") {
                return getFT_FPAR_RequestAsync
            }
            else if (subCode === "FRAA") {
                return getFT_FRAA_RequestAsync
            }
            else if (subCode === "EN") {
                return getFT_EN_RequestAsync
            }
            else if (subCode === "SA") {
                return getFT_SA_RequestAsync
            }
            else if (subCode === "DT") {
                return getFT_DT_RequestAsync
            }
        }
    }
    else if (words.length === 2) {
        const serviceCode = words[0]
        switch (serviceCode) {
            case "WDAD":
                return getWDADRequestAsync
            case "MA":
                return getMARequestAsync
            case "SD":
                return getSDRequestAsync
            case "FT":
                return getFTRequestAsync
            case "MLAI":
                return getMLAIRequestAsync
            default:
                break
        }
    }
    return null
}