import { useNavigate } from "react-router-dom"
import { formatDistanceToNow } from "date-fns"
import { MdOutlineUpdate } from "react-icons/md"

import DashboardConfirmBtn from "../Buttons/DashboardConfirmBtn"
import { requestDetailsPath } from "../../assets/constants"

const RequestCard = ({ data }) => {
    const navigate = useNavigate()
    const viewRequest = (refId) => {
        navigate(requestDetailsPath.replace(":id", refId))
    }
    return (
        <div className="border p-3 py-6 rounded-xl flex flex-col md:flex-row gap-y-3 gap-x-4 justify-between">
            <div className="space-y-2">
                <p className="text-base lg:text-lg font-semibold text-versich-dark-blue">{data.title}</p>
                <div className="text-versich-light-gray text-sm inline-flex items-center gap-1 md:gap-1.5"><MdOutlineUpdate /> Updated {formatDistanceToNow(data.updatedAt, { addSuffix: true })}</div>
                <p className="text-sm text-versich-label">{data.description}</p>
            </div>
            <div className="min-w-[140px]">
                <DashboardConfirmBtn onClick={() => viewRequest(data.refId)}>View Request</DashboardConfirmBtn>
            </div>
        </div>
    )
}
export default RequestCard