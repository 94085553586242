import ServicesList from "../../../assets/ServicesList"

const ServiceBadges = () => {
    return (
        <div className="mt-8 md:mt-12 lg:mt-16 px-8 md:px-20 lg:px-40 xl:px-60 largeLaptop:px-80">
            <div className="max_screen_w grid w-fit max-sm401:mx-auto sm401:w-full sm401:grid-cols-2 smaller:grid-cols-3 min-[1800px]:grid-cols-6 sm:gap-x-0.5 max-sm401:gap-y-1.5 sm:gap-y-1 md:gap-x-4 md:gap-y-3 lg:gap-x-6 lg:gap-y-5 my-6">
                {ServicesList.map((service) => (
                    <div className="flex flex-col items-center justify-center max-w-60 xl:max-w-80 p-3.5 border border-gray-100 shadow-form rounded-2xl" key={service.id}>
                        <img src={service.icon} alt={service.name} className="w-12 h-12 drop-shadow contrast-150" />
                        <p className="text-versich-darktext-color font-bold text-[0.75rem] leading-[1rem] md:text-sm pt-2 md:pt-4">{service.name}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default ServiceBadges
