import { FcGoogle } from "react-icons/fc"

const Google = ({ url, text }) => {
    return (
        <button
            type="button"
            className="text-[#616161] text-sm font-medium flex items-center gap-2.5 w-full transition-all duration-6000 ease-in-out m-auto justify-center p-2 lg:py-3 rounded-lg border border-[#E0E0E0] shadow-input hover:shadow-inputFocus"
            onClick={() => { window.location.href = url }}
            title={text === "" ? "Google" : ""}
        >
            <FcGoogle className="w-6 h-6" />
            {text}
        </button>
    )
}
export default Google