import DashboardLayout from "../../../components/Dashboard/DashboardLayout"
import ProfileCard from "../../../components/Dashboard/ProfileCard"
import HelpCard from "../../../components/Dashboard/HelpCard"
import Requests from "../../../components/Requests/Requests"
import AboutCard from "../../../components/Dashboard/AboutCard"
import { ClientMenu } from "../../../components/Dashboard/constants"

const ClientDashboard = () => {
    return (
        <DashboardLayout navlinks={ClientMenu} activePage="Dashboard" showVerifyEmailBanner={true}>
            <div className="grid md:grid-cols-5 xl:grid-cols-3 gap-x-4 gap-y-3">
                <div className="md:col-span-3 xl:col-span-2 flex flex-col xl:flex-row gap-x-4 gap-y-3">
                    <ProfileCard className="flex-1" />
                    <AboutCard className="flex-1" />
                </div>
                <div className="space-y-3 flex-1 md:col-span-2 xl:col-span-1">
                    <HelpCard />
                </div>
            </div>
            <Requests />
        </DashboardLayout>
    )
}
export default ClientDashboard