import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { BiSolidLock, BiSolidLockOpen } from "react-icons/bi"
import { IoMdCloseCircle } from "react-icons/io"

import ErrorPage from "../../../../components/ErrorPage/ErrorPage"
import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import Meta from "../../../../components/Meta"
import BackButton from "../../../../components/Buttons/BackButton"
import FreelancerMessageCard from "./components/FreelancerMessageCard"
import FreelancerRequestCard from "./components/FreelancerRequestCard"
import UnlockWithVersiGemsDialog, { unlockWithVersiGemsDialog } from "./components/UnlockWithVersiGemsDialog"
import { FreelancerMenu } from "../../../../components/Dashboard/constants"
import { openDialog } from "../../../../components/DialogBox"
import { allMessagePath, freelancerUnlockOptions } from "../../../../assets/constants"
import { getFreelancerDirectMessageAsync } from "../../../../slices/message/directMessage"
import { fetchRequestAction } from "../../request/details/utils"

const metadata = {
    title: "Message Details | VersiMarket",
    description: "Message details page for messages",
}

const MessageDetails = () => {
    const { id } = useParams()
    const { user } = useSelector(state => state.auth)
    const [message, setMessage] = useState(undefined)
    const [request, setRequest] = useState(undefined)
    const [requiredVerisGems, setRequiredVersiGems] = useState(0)
    const [versiGemsSpent, setVersiGemsSpent] = useState(null)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getFreelancerDirectMessageAsync(id))
            .unwrap()
            .then(data => {
                setMessage(data.payload)
                if (data.payload.restriction.request > 0) {
                    return
                }
                const actionAsync = fetchRequestAction(data.payload.requestRefId)
                dispatch(actionAsync(data.payload.requestRefId))
                    .unwrap()
                    .then(data => {
                        setRequest(data.payload)
                    })
                    .catch(() => setRequest(null))
            })
            .catch(() => setMessage(null))
    }, [versiGemsSpent])
    return (
        message === null || request === null
            ? <ErrorPage status={404} />
            : <DashboardLayout navlinks={FreelancerMenu} activePage="Messages">
                <div className="text-start mb-5 lg:mb-12 space-y-3">
                    <Meta data={metadata} />
                    <div className="bg-white shadow-formLight rounded-xl">
                        <div className="border-b-[3px] border-versich-blue/5 p-3 md:p-6">
                            <BackButton onClick={() => navigate(allMessagePath)} />
                        </div>
                        {versiGemsSpent && [freelancerUnlockOptions.message.key, freelancerUnlockOptions.client.key].includes(requiredVerisGems.key) &&
                            <div className="mt-5 px-4">
                                <div className="bg-green-50 text-green-500 font-semibold text-start flex gap-4 justify-between w-full px-4 py-2 sm:px-6 sm:py-4 border border-green-500 shadow-sticker rounded-lg">
                                    <p>
                                        <span className="text-green-700 font-medium">You spent</span> {versiGemsSpent} VersiGems <span className="text-green-700 font-medium">to unlock</span> <span className="lowercase">{requiredVerisGems.label}</span>. <span className="text-green-700 font-medium">Your remaining VersiGems balance is</span> {user.versigems}.
                                    </p>
                                    <button className="hover:text-red-500 transition-colors" onClick={() => setVersiGemsSpent(null)}>
                                        <IoMdCloseCircle className="w-5 h-5" />
                                    </button>
                                </div>
                            </div>
                        }
                        {message &&
                            <FreelancerMessageCard data={message} setRequiredVersiGems={setRequiredVersiGems} />
                        }
                    </div>
                    <div className="bg-white shadow-formLight rounded-xl">
                        {request &&
                            (!(message.restriction.request) || message.restriction.request > 0)
                            ? <FreelancerRequestCard data={request} />
                            : <div className="p-5 md:p-10 space-y-4">
                                <div className="text-start space-y-5">
                                    <div className="smaller:flex items-center mb-1.5 md:mb-4 pb-3">
                                        <div className="flex-1 p-3">
                                            <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold">Request Details:</p>
                                        </div>
                                        <div className="pl-3">
                                            <button className="text-sky-500 hover:bg-sky-100 text-sm inline-flex items-center gap-1 py-1.5 px-3 border-2 border-sky-300 rounded-md"
                                                onClick={(e) => { openDialog(e); setRequiredVersiGems({ ...freelancerUnlockOptions.request, value: message.restriction.request }) }}
                                                data-parent-id={unlockWithVersiGemsDialog}
                                            >
                                                <BiSolidLockOpen /> Unlock Request
                                            </button>
                                        </div>
                                    </div>
                                    <div className="w-full h-64 rounded-md relative">
                                        <div className="bg-[#F0F2FF] w-full h-full blur-[10px] rounded-xl" />
                                        <div className="flex items-center justify-center w-full h-full absolute top-0">
                                            <BiSolidLock className="text-oxford-blue/80 w-6 h-6" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <UnlockWithVersiGemsDialog requiredVerisGems={requiredVerisGems} messageId={id} setVersiGemsSpent={setVersiGemsSpent} />
                    </div>
                </div>
            </DashboardLayout>
    )
}
export default MessageDetails