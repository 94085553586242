import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io"
import { BeatLoader } from "react-spinners"

const RadioOptions = ({ actionAsync, updateFormPosition, value, setValue, error, setOtherValue, otherInputError }) => {
    const [options, setOptions] = useState([])
    const [showOtherInput, setShowOtherInput] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        updateFormPosition()
        dispatch(actionAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setOptions(data.payload)
            })
    }, [])
    return (<>
        {options ?
            <div className="min-h-48 pb-2.5">
                {options.map((option) => (
                    <label
                        key={option.uid}
                        className="flex items-center px-1 py-2 md:p-2 cursor-pointer group"
                        onClick={() => setValue(option.uid)}
                    >
                        <input className="appearance-none" name="choice" type="radio" onChange={(e) => setShowOtherInput(e.target.value === "other")} value={option.uid} />
                        {value === option.uid
                            ? <IoMdRadioButtonOn className="fill-versich-blue-hover" />
                            : <IoMdRadioButtonOff className="text-versich-dark-blue group-hover:fill-versich-blue-hover" />
                        }
                        <span className="text-versich-dark-blue max-md:text-sm w-full ps-2 group-hover:text-versich-blue-hover transition-all">{option.title}</span>
                    </label>
                ))}
                {showOtherInput &&
                    <div className="flex gap-x-2 items-center mt-2.5 ml-1 md:ml-2 mb-2">
                        <input
                            className={"max-md:text-sm flex-1 h-10 py-2 px-3 border rounded-lg outline-none " + (otherInputError ? "border-red-500" : "border-versich-border")}
                            name="otherOption"
                            type="text"
                            placeholder="Enter your choice"
                            onChange={(e) => setOtherValue(e.target.value)}
                        />
                    </div>
                }
            </div>
            : <div className="w-full h-64 flex justify-center items-center">
                <BeatLoader loading={true} color="#1D88ED" size={10} />
            </div>
        }
        {otherInputError && <p className="text-red-500 text-start text-sm mb-3">Please enter a value</p>}
        {error && <p className="text-red-500 text-start text-sm mb-3">Please select an option</p>}
    </>)
}
export default RadioOptions