
const ChoiceButton = ({ children, selected, onClick }) => {
    return (
        <div className="text-start">
            <button className={`text-sm font-medium px-10 lg:px-12 py-1.5 lg:py-2 border-2 ${selected ? "text-versich-blue border-versich-blue" : "text-[#66666699] hover:text-versich-blue/70 border-[#D0D5DD] hover:border-versich-blue/20"} rounded-lg`} type="button" onClick={onClick}>
                {children}
            </button>
        </div>
    )
}
export default ChoiceButton
