import { useState, useEffect } from "react"

import ServicesList from "../../assets/ServicesList"
import AddPlus from "../svgs/AddPlus"
import ImagePreviewInput from "./ImagePreviewInput"
import { clearBlobImages } from "./utils"

const ServiceDetailsTile = ({ data, submitted, setDetail }) => {
    const [desc, setDesc] = useState(data.description || null)
    const [descError, setDescError] = useState(false)
    const [descLimitReached, setDescLimitReached] = useState(false)
    const [images, setImages] = useState([])
    const [previwUrls, setPreviewUrls] = useState(data.images || [])

    // Set Icon & Color
    const [service, setService] = useState(null)
    useEffect(() => {
        const services = ServicesList.filter(s => s.uid === data.uid)
        if (services.length > 0) {
            setService(services[0])
        }
    }, [data])

    // Track changes in description & images
    const updateDescAndImages = (add) => {
        setDetail(preDetail => {
            let details
            if (add) {
                details = {
                    ...preDetail, [data.uid]: {
                        description: desc,
                        images,
                        ...(previwUrls && { urls: previwUrls }),
                    }
                }
            }
            else {
                let d = Object.entries({ ...preDetail }).filter(([key, value]) => key !== data.uid)
                if (d.length > 0) {
                    details = Object.fromEntries(d)
                }
            }
            return details
        })
    }
    useEffect(() => {
        updateDescAndImages(desc !== null && [...images])
    }, [desc, images, previwUrls])

    // Toggle description & images details
    const handleDescDetails = () => {
        if (desc === null) {
            updateDescAndImages(true)
            setDesc("")
        }
        else {
            setDesc(null)
            updateDescAndImages(false)
        }
    }

    const totalChars = 1000
    const handleTextboxInput = (e) => {
        const newChars = e.target.value.length
        if (newChars > totalChars) {
            e.target.value = e.target.value.slice(0, totalChars)
            return setDescLimitReached(true)
        }
        setDescLimitReached(false)
        setDescError(false)
        setDesc(e.target.value)
    }

    useEffect(() => {
        setDescError(submitted && desc === "")
    }, [submitted])

    // Clear image urls
    useEffect(() => {
        return (
            clearBlobImages()
        )
    }, [])
    return (
        service &&
        <div className="pt-5 pb-7 border-b border-[#EFEEEE99]">
            <div className={`${service.bgColor} bg-opacity-[0.08] text-start flex max-md:flex-col gap-5 py-4 sm:py-6 px-6 sm:px-12 rounded-lg`}>
                <div>
                    <img className="min-w-10 h-10 smaller:min-w-14 smaller:h-14 drop-shadow contrast-150" src={service.icon} alt={data.title} />
                </div>
                <div className="flex-grow">
                    <h3 className="text-versich-dark-blue font-medium">{data.title}</h3>
                    <p className="text-versich-label text-sm mt-[5px]">You’ve signed up for</p>
                </div>
                <div className="flex items-center">
                    <button className="hover:opacity-80 inline-flex flex-wrap items-center gap-x-1.5 group" type="button" onClick={handleDescDetails}>
                        <AddPlus className={"group-hover:[&>*]:opacity-80 smaller:max-lg:w-5 smaller:max-lg:h-5 w-6 h-6 transition-transform " + (
                            desc !== null ? "[&>*]:stroke-red-500 rotate-45" : "[&>*]:stroke-versich-light-blue"
                        )} />
                        {desc !== null
                            ? <span className="text-red-500 text-sm font-medium">Remove Desc</span>
                            : <span className="text-versich-light-blue text-sm font-bold">Add Description</span>
                        }
                    </button>
                </div>
            </div>
            {desc !== null && <>
                <div className="space-y-1">
                    <label className="text-versich-dark-blue block text-sm font-medium text-start">
                        <span className="text-base inline-block mt-3 mb-2">Description</span>
                        <textarea className={"w-full min-h-60 max-h-[600px] px-3 py-2 border border-[#BDBDBD] outline-none rounded-[10px] " + (descError || descLimitReached ? "border-red-500" : "border-versich-border")}
                            name="message" placeholder="Convince your prospective buyer here..."
                            onChange={handleTextboxInput}
                            value={desc}
                        />
                    </label>
                    <div className="flex justify-end">
                        <span className={"text-sm font-medium " + (descLimitReached ? "text-red-500" : "text-versich-label")}>{desc.length}/{totalChars} Characters</span>
                    </div>
                    {descError && <p className="text-red-500 text-start text-sm !mb-3">Please enter a description</p>}
                </div>
                <div className="space-y-1">
                    <div className="text-versich-dark-blue block font-medium text-start">
                        <span className="text-base inline-block mt-3 mb-2">Project Photos</span>
                        <div className="grid grid-cols-4 gap-3">
                            {[...Array(4)].map((_, index) => (
                                <ImagePreviewInput name="projectphoto" maxSizeInMb={10} defaultUrl={previwUrls[index]} setDefaultUrls={setPreviewUrls} setFiles={setImages} key={index} />
                            ))}
                        </div>
                    </div>
                </div>
            </>}
        </div>
    )
}
export default ServiceDetailsTile