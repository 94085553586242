import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../api"
import apiErrorParser from "../../../utils/apiErrorParser"

export const allUserRequestAsync = createAsyncThunk("service/allUserRequest", async () => {
    try {
        const { data } = await api.allUserRequest()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const latestUserRequestAsync = createAsyncThunk("service/latestUserRequest", async () => {
    try {
        const { data } = await api.latestUserRequest()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})