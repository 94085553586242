import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { IoIosAlert } from "react-icons/io"

import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import RadioSelectionField from "../../../../components/Inputs/RadioSelectionField"
import LocationSelectionField from "../../../../components/Inputs/LocationSelectionField"
import toastify from "../../../../utils/toastify"
import {
    dashboardPath, editOtherServicesPath, editPrimaryServicePath,
    regionCoverage, regionCoverageOptions,
    TOASTTYPE_ERROR
} from "../../../../assets/constants"
import { freelancerLeadsLocationUpdateAsync } from "../../../../slices/freelancer/leadsLocation"

const EditLeadsLocation = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()
    const navigate = useNavigate()

    const [csrfToken, setCsrfToken] = useState("")
    const [isWorldwide, setIsWorldwide] = useState(true)
    const [selectedCountry, setSelectedCountry] = useState("")
    const [selectedState, setSelectedState] = useState(undefined)
    const [regionError, setRegionError] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!user || !user.leadsLocation) {
            return
        }
        setIsWorldwide(user.leadsLocation.coverage === regionCoverage.worldwide.value)
        user.leadsLocation.country && setSelectedCountry(user.leadsLocation.country.iso3)
        user.leadsLocation.state && setSelectedState(user.leadsLocation.state.stateCode)
    }, [user])

    const handleRadioChange = (value) => {
        setIsWorldwide(value === regionCoverage.worldwide.value)
    }
    const handleLocationChange = (country, state) => {
        setSelectedCountry(country)
        setSelectedState(state)
    }

    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            let formData
            if (isWorldwide) {
                formData = {
                    token: csrfToken,
                    coverage: regionCoverage.worldwide.value,
                }
            }
            else {
                if (!selectedCountry || selectedState === "") {
                    setRegionError(true)
                    return
                }
                formData = {
                    token: csrfToken,
                    coverage: regionCoverage.custom.value,
                    countryiso3: selectedCountry,
                    statecode: selectedState,
                }
            }
            setLoading(true)
            await dispatch(freelancerLeadsLocationUpdateAsync(formData))
                .unwrap()
                .then(() => {
                    navigate(editOtherServicesPath)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => {
                    setLoading(false)
                    setRegionError(false)
                })
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <FormProvider {...methods}>
            <BrandBar />
            <div className="bg-white mb-12 py-10 md:py-14 px-3 sm:px-8 md:px-16 lg:px-32 xl:px-52">
                <form className="w-full max-w-[1080px] mx-auto px-4 smaller:px-12 xl:px-20 py-8 rounded-md shadow-formLight" onSubmit={handleSubmit}>
                    <div className="space-y-3 pb-6 border-b border-[#EFEEEE99]">
                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl font-medium flex justify-center">Where would you love to get leads from?</h1>
                        <p className="text-versich-label text-xs sm:text-sm font-medium">Tell us the area you cover so we can show leads for your location.</p>
                    </div>
                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                    <RadioSelectionField selected={isWorldwide ? regionCoverage.worldwide.value : regionCoverage.custom.value} options={regionCoverageOptions} onChange={handleRadioChange} />
                    <LocationSelectionField notWorldwide={!isWorldwide} country={selectedCountry} state={selectedState} onLocationChange={handleLocationChange} regionError={regionError} />
                    {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                    <div className="bg-brandeis-blue/[0.04] flex max-smaller:flex-wrap gap-[18px] items-start text-start px-4 sm:px-6 py-3 rounded-lg">
                        <div className="flex-1 inline-flex max-smaller:flex-wrap gap-3.5">
                            <IoIosAlert className="text-[#65A3FC] text-2xl" />
                            <div className="space-y-1">
                                <h3 className="text-versich-dark-blue text-sm font-bold">Quick Note</h3>
                                <p className="text-versich-label text-xs">You can change your location at any time</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                        <button className="bg-columbia-blue hover:opacity-80 text-versich-light-blue border border-columbia-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(dashboardPath)}>Back</button>
                        <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(editOtherServicesPath)}>Skip</button>
                        <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Next</button>
                    </div>
                </form>
            </div>
            {loading && <FormLoader />}
        </FormProvider>
    )
}
export default EditLeadsLocation
