import { toast } from "react-toastify"

import { TOASTTIMER } from "../assets/constants"

const toastify = (message, status, submitButton, containerId = "app") => {
    switch (status) {
        case "error":
            toast.error(message, { containerId })
            break
        case "info":
            toast.info(message, { containerId })
            break
        case "success":
            toast.success(message, { containerId })
            break
        case "warn":
            toast.warn(message, { containerId })
            break
        default:
            toast(message, { containerId })
            break
    }
    submitButton && setTimeout(() => {
        submitButton.disabled = false
    }, TOASTTIMER)
}
export default toastify