import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { BeatLoader } from "react-spinners"

import checkListIcon from "../../../../../../assets/icons/request/checklist.png"
import NewRequestLayout from "../../NewRequestLayout"
import CreateWebsite from "../../../../../../components/svgs/CreateWebsite"
import EditWebsite from "../../../../../../components/svgs/EditWebsite"
import { allWebsiteRequirementAsync } from "../../../../../../slices/client/service/webDesignAndDevelopment"

const WebsiteRequirement = ({ totalForms, formPosition, updateFormPosition, defaultValue, setRequirement, goBack }) => {
    const [options, setOptions] = useState([])
    const [value, setValue] = useState(defaultValue || "")
    const [error, setError] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        updateFormPosition()
        dispatch(allWebsiteRequirementAsync(`?exclude=None`))
            .unwrap()
            .then((data) => {
                setOptions(data.payload)
            })
    }, [])

    const handleContinue = () => {
        if (value) {
            setRequirement(value)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <NewRequestLayout
            icon={checkListIcon}
            description="Choose one option from where you would like to start. You can provide more details in upcoming steps."
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            handleContinueClick={handleContinue}
        >
            <div className="relative">
                <p className=" text-versich-dark-blue text-lg font-semibold pb-3">What is your website requirement?</p>
                <p className="text-sm pb-6">Once selected, please click ‘continue’</p>
                {options ?
                    <div className="grid grid-cols-1 sm404:grid-cols-2 gap-5 pb-5">
                        {options.map((option, index) => (
                            <div
                                key={option.uid}
                                className={`flex-1 flex flex-col justify-center items-center gap-y-2 h-44 py-4 px-3 border-[3px] ${value === option.uid
                                    ? "border-versich-blue/80 bg-versich-blue/15"
                                    : "border-gray-100 hover:border-versich-blue/20"
                                    } cursor-pointer shadow-sticker rounded-xl`
                                }
                                onClick={() => setValue(option.uid)}
                            >
                                <input className="appearance-none" name="requirement" type="radio" value={option.uid} />
                                {index === 0
                                    ? <CreateWebsite className="w-16 h-16 [&>*]:fill-versich-dark-blue" />
                                    : <EditWebsite className="w-16 h-16 [&>*]:stroke-versich-dark-blue" />
                                }
                                <span className="text-versich-dark-blue text-center text-xs md:text-sm max-w-[200px]">{option.title}</span>
                            </div>
                        ))}
                    </div>
                    : <div className="w-full h-64 flex justify-center items-center">
                        <BeatLoader loading={true} color="#1D88ED" size={10} />
                    </div>
                }
                {error && <p className="text-red-500 text-start text-sm mb-3">Please select an option</p>}
            </div>
        </NewRequestLayout>
    )
}
export default WebsiteRequirement
