import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import logo from "../../assets/logo.png"
import { dashboardPath, homePath } from "../../assets/constants"

const BrandBar = () => {
    const { isAuthenticated } = useSelector(state => state.auth)
    return (
        <nav className="py-3.5 z-20 px-8 md:px-16 lg:px-28 relative bg-white shadow-bottom">
            <div className="flex justify-center items-center max-w-[1700px] mx-auto">
                <Link to={isAuthenticated ? dashboardPath : homePath} className="flex items-center justify-center">
                    <img src={logo} alt="logo" className="w-[48px] md:w-[52px]" />
                    &nbsp;
                    <span className="font-semibold text-xl text-versich-dark-blue">VersiMarket</span>
                </Link>
            </div>
        </nav>
    )
}
export default BrandBar
