import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { CiLocationOn } from "react-icons/ci"

import ConfirmButton from "../Buttons/ConfirmButton"
import { editPrimaryServicePath, regionCoverage, CLIENT } from "../../assets/constants"

const LeadSettingsCard = ({ className }) => {
    const { user } = useSelector((state) => state.auth)
    const profileCompletionScore = useSelector((state) => state.profileCompletion.score)

    const navigate = useNavigate()
    return (
        user.primaryService &&
        <div className={className}>
            <div className="bg-white shadow-card px-4 py-4 sm:px-6 sm:py-6 rounded-xl space-y-4 text-start">
                <div className="text-start text-lg lg:text-xl font-semibold text-versich-dark-blue">Lead Setting</div>
                <div>
                    <p className="text-versich-dark-blue font-medium mb-1">Services</p>
                    <p className="text-sm text-versich-label mb-2.5">Your primary service where you got most of leads from</p>
                    <div className="flex mb-2.5">
                        <div className="bg-anti-flash-white text-versich-darktext-color text-xs md:text-sm py-1.5 px-2.5 border border-versich-border/40 rounded-2xl">{user.primaryService.title}</div>
                    </div>
                    {user.otherServices && (<>
                        <p className="text-sm text-versich-label mb-2.5">You’ll also receive leads in these categories</p>
                        <div className="flex flex-wrap flex-col smallest:flex-row gap-3">
                            {user.otherServices.map(service => (
                                <div className="bg-anti-flash-white text-versich-darktext-color text-xs md:text-sm py-1.5 px-2.5 border border-versich-border/40 rounded-2xl" key={service.uid}>{service.title}</div>
                            ))}
                        </div>
                    </>)}
                </div>
                <div>
                    {user.leadsLocation &&
                        <div>
                            <p className="text-versich-dark-blue font-medium mb-1">Location</p>
                            {user.leadsLocation.coverage === regionCoverage.worldwide.value
                                ? (<>
                                    <p className="text-sm text-versich-label mb-1">You’re receiving customers from</p>
                                    <div className="flex items-center gap-x-1 mb-3">
                                        <CiLocationOn />
                                        <p className="text-sm">Worldwide</p>
                                    </div>
                                </>)
                                : (<>
                                    <p className="text-sm text-versich-label mb-1">You’re receiving customers within</p>
                                    <div className="flex items-center gap-x-1 mb-3">
                                        <CiLocationOn />
                                        <p className="text-sm">{user.leadsLocation.state ? `${user.leadsLocation.state.name}, ` : ""}{user.leadsLocation.country.name}</p>
                                    </div>
                                </>)
                            }
                        </div>
                    }
                    {(profileCompletionScore === 100 || (user.accountType !== CLIENT && user.primaryService)) &&
                        <div className="max-w-32 lg:max-w-40">
                            <ConfirmButton type="button" handleClick={() => navigate(editPrimaryServicePath)}>Edit setting</ConfirmButton>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default LeadSettingsCard